import * as types from "./applications.types";

export const managedAppsRequestAC: types.ManagedAppsRequestAC = () => ({
  type: types.MANAGED_APPS_REQUEST,
});

export const managedAppsRequestSuccessAC: types.ManagedAppsRequestSuccessAC = (payload) => ({
  type: types.MANAGED_APPS_REQUEST_SUCCESS,
  payload,
});

export const managedAppsRequestFailureAC: types.ManagedAppsRequestFailureAC = () => ({
  type: types.MANAGED_APPS_REQUEST_FAILURE,
});

export const detectedAppsRequestAC: types.DetectedAppsRequestAC = () => ({
  type: types.DETECTED_APPS_REQUEST,
});

export const detectedAppsRequestSuccessAC: types.DetectedAppsRequestSuccessAC = (payload) => ({
  type: types.DETECTED_APPS_REQUEST_SUCCESS,
  payload,
});

export const detectedAppsRequestFailureAC: types.DetectedAppsRequestFailureAC = () => ({
  type: types.DETECTED_APPS_REQUEST_FAILURE,
});

export const setAppsSortFieldAC: types.SetAppsSortFieldAC = (payload) => ({
  type: types.SET_APPS_SORT_FIELD,
  payload,
});

export const setManagedAppsFiltersAC: types.SetManagedAppsFiltersAC = (payload) => ({
  type: types.SET_MANAGED_APPS_FILTERS,
  payload,
});

export const setDetectedAppsFiltersAC: types.SetDetectedAppsFiltersAC = (payload) => ({
  type: types.SET_DETECTED_APPS_FILTERS,
  payload,
});

export const setAppsPerPageAC: types.SetAppsPerPageAC = (payload) => ({
  type: types.SET_APPS_PER_PAGE,
  payload,
});

export const setAppsCurrentPageAC: types.SetAppsCurrentPageAC = (payload) => ({
  type: types.SET_APPS_CURRENT_PAGE,
  payload,
});
