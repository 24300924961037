import { RequestStatus } from "types/common.types";
import {
  ManagedDeviceModel,
  MicrosoftDefenderDevicesResponse,
  MicrosoftDefenderReportModel,
  UnmanagedDeviceModel,
  WindowsUpdateConfigModel,
  WindowsUpdateDeviceModel,
} from "types/devicesApi.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface ManagedDevicesFiltersModel {
  filters: {
    deviceName: string;
    manufacturer: string[];
    complianceState: string[];
  };
}

export interface UnmanagedDevicesFiltersModel {
  filters: {
    deviceName: string;
    manufacturer: string[];
    isCompliant: boolean;
    isUncompliant: boolean;
  };
}

export interface WindowsUpdateFiltersModel {
  filters: {
    deviceOrUserName: string;
    status: string[];
  };
}

export interface MicrosoftDefenderDevicesFiltersModel {
  filters: {
    deviceOrUserName: string;
    deviceState: string[];
  };
}

export interface ManagedDevices extends WithRequestStatus, ManagedDevicesFiltersModel {
  list: ManagedDeviceModel[];
}

export interface UnmanagedDevices extends WithRequestStatus, UnmanagedDevicesFiltersModel {
  list: UnmanagedDeviceModel[];
}

export interface SelectedWindowsUpdateDevices extends WithRequestStatus, WindowsUpdateFiltersModel {
  windowsUpdateId: string;
  list: WindowsUpdateDeviceModel[];
}

export interface MicrosoftDefenderReportStoreModel extends MicrosoftDefenderReportModel {
  callsCounter: number;
}

export interface MicrosoftDefender extends MicrosoftDefenderDevicesResponse, MicrosoftDefenderDevicesFiltersModel {
  report: MicrosoftDefenderReportStoreModel;
}

export interface WindowsUpdates extends WithRequestStatus {
  list: WindowsUpdateConfigModel[];
  selectedWindowsUpdateDevices: SelectedWindowsUpdateDevices;
}
export type SortFieldType = keyof ManagedDeviceModel | keyof UnmanagedDeviceModel | "";
export type SortOrderType = "asc" | "desc";

export interface DevicesStateSlice {
  readonly managedDevices: ManagedDevices;
  readonly unmanagedDevices: UnmanagedDevices;
  readonly windowsUpdates: WindowsUpdates;
  readonly microsoftDefender: MicrosoftDefender;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly perPage: number;
  readonly currentPage: number;
}

export const MANAGED_DEVICES_REQUEST = "MANAGED_DEVICES_REQUEST";
export type ManagedDevicesRequestAction = { type: typeof MANAGED_DEVICES_REQUEST };
export type ManagedDevicesRequestAC = () => ManagedDevicesRequestAction;

export const MANAGED_DEVICES_REQUEST_SUCCESS = "MANAGED_DEVICES_REQUEST_SUCCESS";
export type ManagedDevicesRequestSuccessAction = {
  type: typeof MANAGED_DEVICES_REQUEST_SUCCESS;
  payload: ManagedDeviceModel[];
};
export type ManagedDevicesRequestSuccessAC = (payload: ManagedDeviceModel[]) => ManagedDevicesRequestSuccessAction;

export const MANAGED_DEVICES_REQUEST_FAILURE = "MANAGED_DEVICES_REQUEST_FAILURE";
export type ManagedDevicesRequestFailureAction = { type: typeof MANAGED_DEVICES_REQUEST_FAILURE };
export type ManagedDevicesRequestFailureAC = () => ManagedDevicesRequestFailureAction;

export const UNMANAGED_DEVICES_REQUEST = "UNMANAGED_DEVICES_REQUEST";
export type UnmanagedDevicesRequestAction = {
  type: typeof UNMANAGED_DEVICES_REQUEST;
};
export type UnmanagedDevicesRequestAC = () => UnmanagedDevicesRequestAction;

export const UNMANAGED_DEVICES_REQUEST_SUCCESS = "UNMANAGED_DEVICES_REQUEST_SUCCESS";
export type UnmanagedDevicesRequestSuccessAction = {
  type: typeof UNMANAGED_DEVICES_REQUEST_SUCCESS;
  payload: UnmanagedDeviceModel[];
};
export type UnmanagedDevicesRequestSuccessAC = (
  payload: UnmanagedDeviceModel[]
) => UnmanagedDevicesRequestSuccessAction;

export const UNMANAGED_DEVICES_REQUEST_FAILURE = "UNMANAGED_DEVICES_REQUEST_FAILURE";
export type UnmanagedDevicesRequestFailureAction = {
  type: typeof UNMANAGED_DEVICES_REQUEST_FAILURE;
};
export type UnmanagedDevicesRequestFailureAC = () => UnmanagedDevicesRequestFailureAction;

export const WINDOWS_UPDATE_CONFIGS_REQUEST = "WINDOWS_UPDATE_CONFIGS_REQUEST";
export type WindowsUpdateConfigsRequestAction = {
  type: typeof WINDOWS_UPDATE_CONFIGS_REQUEST;
};
export type WindowsUpdateConfigsRequestAC = () => WindowsUpdateConfigsRequestAction;

export const WINDOWS_UPDATE_CONFIGS_REQUEST_SUCCESS = "WINDOWS_UPDATE_CONFIGS_REQUEST_SUCCESS";
export type WindowsUpdateConfigsRequestSuccessAction = {
  type: typeof WINDOWS_UPDATE_CONFIGS_REQUEST_SUCCESS;
  payload: WindowsUpdateConfigModel[];
};
export type WindowsUpdateConfigsRequestSuccessAC = (
  payload: WindowsUpdateConfigModel[]
) => WindowsUpdateConfigsRequestSuccessAction;

export const WINDOWS_UPDATE_CONFIGS_REQUEST_FAILURE = "WINDOWS_UPDATE_CONFIGS_REQUEST_FAILURE";
export type WindowsUpdateConfigsRequestFailureAction = {
  type: typeof WINDOWS_UPDATE_CONFIGS_REQUEST_FAILURE;
};
export type WindowsUpdateConfigsRequestFailureAC = () => WindowsUpdateConfigsRequestFailureAction;

export const SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST = "SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST";
export type SelectedWindowsUpdateDevicesRequestAction = {
  type: typeof SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST;
  payload: WindowsUpdateDeviceModel["id"];
};
export type SelectedWindowsUpdateDevicesRequestAC = (
  payload: WindowsUpdateDeviceModel["id"]
) => SelectedWindowsUpdateDevicesRequestAction;

export const SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_SUCCESS = "SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_SUCCESS";
export type SelectedWindowsUpdateDevicesRequestSuccessAction = {
  type: typeof SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_SUCCESS;
  payload: WindowsUpdateDeviceModel[];
};
export type SelectedWindowsUpdateDevicesRequestSuccessAC = (
  payload: WindowsUpdateDeviceModel[]
) => SelectedWindowsUpdateDevicesRequestSuccessAction;

export const SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_FAILURE = "SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_FAILURE";
export type SelectedWindowsUpdateDevicesRequestFailureAction = {
  type: typeof SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_FAILURE;
};
export type SelectedWindowsUpdateDevicesRequestFailureAC = () => SelectedWindowsUpdateDevicesRequestFailureAction;

export const MICROSOFT_DEFENDER_DEVICES_REQUEST = "MICROSOFT_DEFENDER_DEVICES_REQUEST";
export type MicrosoftDefenderDevicesRequestAction = {
  type: typeof MICROSOFT_DEFENDER_DEVICES_REQUEST;
};
export type MicrosoftDefenderDevicesRequestAC = () => MicrosoftDefenderDevicesRequestAction;

export const MICROSOFT_DEFENDER_DEVICES_REQUEST_SUCCESS = "MICROSOFT_DEFENDER_DEVICES_REQUEST_SUCCESS";
export type MicrosoftDefenderDevicesRequestSuccessAction = {
  type: typeof MICROSOFT_DEFENDER_DEVICES_REQUEST_SUCCESS;
  payload: MicrosoftDefenderDevicesResponse;
};
export type MicrosoftDefenderDevicesRequestSuccessAC = (
  payload: MicrosoftDefenderDevicesResponse
) => MicrosoftDefenderDevicesRequestSuccessAction;

export const MICROSOFT_DEFENDER_DEVICES_REQUEST_FAILURE = "MICROSOFT_DEFENDER_DEVICES_REQUEST_FAILURE";
export type MicrosoftDefenderDevicesRequestFailureAction = {
  type: typeof MICROSOFT_DEFENDER_DEVICES_REQUEST_FAILURE;
};
export type MicrosoftDefenderDevicesRequestFailureAC = () => MicrosoftDefenderDevicesRequestFailureAction;

export const SET_DEVICES_SORT_FIELD = "SET_DEVICES_SORT_FIELD";
export type SetDevicesSortFieldAction = {
  type: typeof SET_DEVICES_SORT_FIELD;
  payload: SortFieldType;
};
export type SetDevicesSortFieldAC = (payload: SortFieldType) => SetDevicesSortFieldAction;

export const SET_MANAGED_DEVICES_FILTERS = "SET_MANAGED_DEVICES_FILTERS";
export type SetManagedDevicesFiltersAction = {
  type: typeof SET_MANAGED_DEVICES_FILTERS;
  payload: ManagedDevicesFiltersModel["filters"];
};
export type SetManagedDevicesFiltersAC = (
  payload: ManagedDevicesFiltersModel["filters"]
) => SetManagedDevicesFiltersAction;

export const SET_UNMANAGED_DEVICES_FILTERS = "SET_UNMANAGED_DEVICES_FILTERS";
export type SetUnmanagedDevicesFiltersAction = {
  type: typeof SET_UNMANAGED_DEVICES_FILTERS;
  payload: UnmanagedDevicesFiltersModel["filters"];
};
export type SetUnmanagedDevicesFiltersAC = (
  payload: UnmanagedDevicesFiltersModel["filters"]
) => SetUnmanagedDevicesFiltersAction;

export const SET_WINDOWS_UPDATE_DEVICES_FILTERS = "SET_WINDOWS_UPDATE_DEVICES_FILTERS";
export type SetWindowsUpdateDevicesFiltersAction = {
  type: typeof SET_WINDOWS_UPDATE_DEVICES_FILTERS;
  payload: WindowsUpdateFiltersModel["filters"];
};
export type SetWindowsUpdateDevicesFiltersAC = (
  payload: WindowsUpdateFiltersModel["filters"]
) => SetWindowsUpdateDevicesFiltersAction;

export const SET_MICROSOFT_DEFENDER_DEVICES_FILTERS = "SET_MICROSOFT_DEFENDER_DEVICES_FILTERS";
export type SetMicrosoftDefenderDevicesFiltersAction = {
  type: typeof SET_MICROSOFT_DEFENDER_DEVICES_FILTERS;
  payload: MicrosoftDefenderDevicesFiltersModel["filters"];
};
export type SetMicrosoftDefenderDevicesFiltersAC = (
  payload: MicrosoftDefenderDevicesFiltersModel["filters"]
) => SetMicrosoftDefenderDevicesFiltersAction;

export const SET_DEVICES_PER_PAGE = "SET_DEVICES_PER_PAGE";
export type SetDevicesPerPageAction = {
  type: typeof SET_DEVICES_PER_PAGE;
  payload: number;
};
export type SetDevicesPerPageAC = (payload: number) => SetDevicesPerPageAction;

export const SET_DEVICES_CURRENT_PAGE = "SET_DEVICES_CURRENT_PAGE";
export type SetDevicesCurrentPageAction = {
  type: typeof SET_DEVICES_CURRENT_PAGE;
  payload: number;
};
export type SetDevicesCurrentPageAC = (payload: number) => SetDevicesCurrentPageAction;

export type DevicesActions =
  | ManagedDevicesRequestAction
  | ManagedDevicesRequestSuccessAction
  | ManagedDevicesRequestFailureAction
  | UnmanagedDevicesRequestAction
  | UnmanagedDevicesRequestSuccessAction
  | UnmanagedDevicesRequestFailureAction
  | WindowsUpdateConfigsRequestAction
  | WindowsUpdateConfigsRequestSuccessAction
  | WindowsUpdateConfigsRequestFailureAction
  | SelectedWindowsUpdateDevicesRequestAction
  | SelectedWindowsUpdateDevicesRequestSuccessAction
  | SelectedWindowsUpdateDevicesRequestFailureAction
  | MicrosoftDefenderDevicesRequestAction
  | MicrosoftDefenderDevicesRequestSuccessAction
  | MicrosoftDefenderDevicesRequestFailureAction
  | SetDevicesSortFieldAction
  | SetManagedDevicesFiltersAction
  | SetUnmanagedDevicesFiltersAction
  | SetWindowsUpdateDevicesFiltersAction
  | SetMicrosoftDefenderDevicesFiltersAction
  | SetDevicesPerPageAction
  | SetDevicesCurrentPageAction;
