import { AppThunk } from "store";
import * as mwApi from "api/usageStatistic.api";
import * as devicesApi from "api/devices.api";
import * as usageStatisticActions from "./usageStatistic.actions";
import { DevicesMalwareSummaryModel, WindowsUpdateSummaryModel } from "./usageStatistic.types";
import { notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";
import { parseApiErrorMessage } from "utils/helpers";

export const getUsersSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.usersSummaryRequestAC());
      const { data: usersSummary } = await mwApi.getUsersSummary(selectedContractId);
      dispatch(usageStatisticActions.usersSummaryRequestSuccessAC(usersSummary.users));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "USERS SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:usersSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.usersSummaryRequestFailureAC());
    }
  };

export const getDevicesSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.devicesSummaryRequestAC());
      const { data: devicesSummary } = await mwApi.getDevicesSummary(selectedContractId);
      dispatch(usageStatisticActions.devicesSummaryRequestSuccessAC(devicesSummary.devices));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DEVICES SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:devicesSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.devicesSummaryRequestFailureAC());
    }
  };

export const getLicensesSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.licensesSummaryRequestAC());
      const { data: licensesSummary } = await mwApi.getLicensesSummary(selectedContractId);
      dispatch(usageStatisticActions.licensesSummaryRequestSuccessAC(licensesSummary.licenses));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "LICENSES SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:licensesSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.licensesSummaryRequestFailureAC());
    }
  };

export const getAppsSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.appsSummaryRequestAC());
      const { data: appsSummary } = await mwApi.getAppsSummary(selectedContractId);
      dispatch(usageStatisticActions.appsSummaryRequestSuccessAC(appsSummary.applications));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "APPS SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:appsSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.appsSummaryRequestFailureAC());
    }
  };

export const getWindowsUpdateSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.windowsUpdateSummaryRequestAC());
      const { data } = await devicesApi.getWindowsUpdateSummary(selectedContractId);
      const windowsUpdateSummary: WindowsUpdateSummaryModel = data.windowsUpdateConfigurations.reduce(
        (acc, value) => {
          acc.success += value.success;
          acc.pending += value.pending;
          acc.error += value.error;
          acc.failed += value.failed;
          acc.conflict += value.conflict;
          return acc;
        },
        { success: 0, pending: 0, error: 0, failed: 0, conflict: 0 }
      );

      dispatch(usageStatisticActions.windowsUpdateSummaryRequestSuccessAC(windowsUpdateSummary));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "WINDOWS UPDATE SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:windowsUpdateSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.windowsUpdateSummaryRequestFailureAC());
    }
  };

export const getDevicesProtectionSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.devicesProtectionSummaryRequestAC());
      const { data } = await mwApi.getDevicesProtectionSummary(selectedContractId);
      dispatch(usageStatisticActions.devicesProtectionSummaryRequestSuccessAC(data.deviceProtection));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DEVICES PROTECTION SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:devicesProtectionSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.devicesProtectionSummaryRequestFailureAC());
    }
  };

export const getDevicesMalwareSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.devicesMalwareSummaryRequestAC());
      const { data } = await mwApi.getDevicesMalwareSummary(selectedContractId);

      const devicesMalwareSummary: DevicesMalwareSummaryModel = data.malware.totalMalwareDevices
        ? data.malware.malwareSeveritySummary.reduce(
            (acc, value) => {
              acc[value.severity] = value.malwareDetectionCount;
              return acc;
            },
            {
              totalMalwareDevices: data.malware.totalMalwareDevices,
              severe: 0,
              high: 0,
              moderate: 0,
              low: 0,
              unknown: 0,
            }
          )
        : { totalMalwareDevices: 0, severe: 0, high: 0, moderate: 0, low: 0, unknown: 0 };

      dispatch(usageStatisticActions.devicesMalwareSummaryRequestSuccessAC(devicesMalwareSummary));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DEVICES MALWARE SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:devicesMalwareSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.devicesMalwareSummaryRequestFailureAC());
    }
  };

export const getDevicesFirewallSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.devicesFirewallSummaryRequestAC());
      const { data } = await mwApi.getDevicesFirewallSummary(selectedContractId);
      dispatch(usageStatisticActions.devicesFirewallSummaryRequestSuccessAC(data.firewallStatus));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DEVICES FIREWALL SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:devicesFirewallSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.devicesFirewallSummaryRequestFailureAC());
    }
  };

export const getDevicesEncryptionSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.devicesEncryptionSummaryRequestAC());
      const { data } = await mwApi.getDevicesEncryptionSummary(selectedContractId);
      dispatch(usageStatisticActions.devicesEncryptionSummaryRequestSuccessAC(data.encryptionStatus));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DEVICES ENCRYPTION SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:devicesEncryptionSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.devicesEncryptionSummaryRequestFailureAC());
    }
  };

export const getDevicesOperatingSystemsInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.devicesOperatingSystemsRequestAC());
      const { data: devicesOperatingSystemsSummary } = await mwApi.getDevicesOperatingSystemsSummary(
        selectedContractId
      );
      dispatch(usageStatisticActions.devicesOperatingSystemsRequestSuccessAC(devicesOperatingSystemsSummary));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DEVICES OPERATING SYSTEMS SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:devicesOperatingSystemsSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.devicesOperatingSystemsRequestFailureAC());
    }
  };

export const getDevicesComplianceHistoryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.devicesCompianceHistoryRequestAC());
      const { data: devicesComplianceHistory } = await mwApi.getDevicesComplianceHistory(selectedContractId);
      dispatch(usageStatisticActions.devicesCompianceHistoryRequestSuccessAC(devicesComplianceHistory));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DEVICES COMPLIANCE HISTORY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:devicesComplianceHistoryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.devicesCompianceHistoryRequestFailureAC());
    }
  };

export const getUsersTypesSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.usersTypesSummaryRequestAC());
      const { data: usersTypesSummary } = await mwApi.getUsersTypesSummary(selectedContractId);
      dispatch(usageStatisticActions.usersTypesSummaryRequestSuccessAC(usersTypesSummary.userTypes));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "USER TYPES SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:userTypesSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.usersTypesSummaryRequestFailureAC());
    }
  };

export const getUsersServicesSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.usersServicesSummaryRequestAC());
      const { data: usersServicesSummary } = await mwApi.getUsersServicesSummary(selectedContractId);
      dispatch(usageStatisticActions.usersServicesSummaryRequestSuccessAC(usersServicesSummary.activity));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "USER SERVICES SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:userServicesSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.usersServicesSummaryRequestFailureAC());
    }
  };

export const getUsersAppsSummaryInfo =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usageStatisticActions.usersAppsSummaryRequestAC());
      const { data: usersAppsSummary } = await mwApi.getUsersAppsSummary(selectedContractId);
      dispatch(usageStatisticActions.usersAppsSummaryRequestSuccessAC(usersAppsSummary.activity));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "USER APPS SUMMARY REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:userAppsSummaryRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usageStatisticActions.usersAppsSummaryRequestFailureAC());
    }
  };
