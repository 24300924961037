import {
  capitalizeFirstLetter,
  pieChartLegendLabelFormatter,
} from "components/DevicesBlockCharts/charts/DevicesOperatingSystemChart/utils";
import { WindowsUpdateSummaryModel } from "store/usageStatistic/usageStatistic.types";

export const composeChartOptions = (windowsUpdateStatus: WindowsUpdateSummaryModel) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 350,
    },
    colors: ["#4ef09d", "#44d9fc", "#e59a7f", "#ff5a5a", "#c48df7"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: Object.entries(windowsUpdateStatus).map((status) => {
          return {
            name: capitalizeFirstLetter(status[0]),
            y: status[1],
          };
        }),
      },
    ],
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: pieChartLegendLabelFormatter,
    },
  };
};
