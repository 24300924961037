import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { devicesSelectors, devicesThunks } from "store/devices";
import { MicrosoftDefenderReportStatus } from "types/devicesApi.types";

const useFetchDevices = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const {
    report: { status, callsCounter },
  } = useSelector(devicesSelectors.getMicrosoftDefenderData);

  useEffect(() => {
    if (selectedContractId) {
      thunkDispatch(devicesThunks.getManagedDevicesList(selectedContractId));
      thunkDispatch(devicesThunks.getUnmanagedDevicesList(selectedContractId));
      thunkDispatch(devicesThunks.getWindowsUpdateConfigsList(selectedContractId));
      thunkDispatch(devicesThunks.getMicrosoftDefenderDevicesList(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId]);

  useEffect(() => {
    if (selectedContractId && status !== MicrosoftDefenderReportStatus.completed) {
      setTimeout(() => {
        thunkDispatch(devicesThunks.getMicrosoftDefenderDevicesList(selectedContractId));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thunkDispatch, callsCounter]);

  return useSelector(devicesSelectors.getSlice);
};

export default useFetchDevices;
