import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Spinner, Table } from "@skyportal/ui-kit";
import useFetchLicenses from "hooks/useFetchLicenses";
import { RequestStatus } from "types/common.types";
import LicensesUsageChart from "./LicensesUsageChart";
import useOtherLicensesColumnConfig from "hooks/useOtherLicensesColumnConfig";
import { licensesSelectors, licensesThunks } from "store/licenses";
import PaginationBox from "containers/PaginationBox";
import {
  setOtherLicensesSortFieldAC,
  setOtherLicensesCurrentPageAC,
  setOtherLicensesPerPageAC,
} from "store/licenses/licenses.actions";
import { generateCsvExportUrl } from "utils/helpers";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";
import CardFallback from "containers/CardFallback";

import styles from "./styles.module.css";

const LicensesPageContent = () => {
  const { t } = useTranslation("licensesPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  const licenses = useFetchLicenses();
  const { sortField, sortOrder, currentPage, perPage } = useSelector(licensesSelectors.getSlice);
  const { list: otherLicensesList, pageCount } = useSelector(licensesSelectors.getOtherLicensesTable);

  const microsoft365LicensesList = useMemo(
    () => licenses.list.filter((license) => license.isMicrosoft365),
    [licenses.list]
  );

  const columnsConfig = useOtherLicensesColumnConfig();

  const handleSortFieldChange = useCallback((field) => dispatch(setOtherLicensesSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setOtherLicensesPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setOtherLicensesCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(licensesThunks.getLicensesList(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <>
      <h2 className={styles.title}>{t("microsoft365Subtitle")}</h2>
      <div className={styles.chartsContainer}>
        {licenses.requestStatus === RequestStatus.PENDING && (
          <div className={cn(styles.chartsItem, styles.smallCard)}>
            <Card className={cn(styles.card, styles.placeholderCard)}>
              <Spinner className={styles.spinner} show />
            </Card>
          </div>
        )}
        {licenses.requestStatus === RequestStatus.SUCCESS && !microsoft365LicensesList.length && (
          <span className={styles.noMicrosoft365LicensesText}>{t("noMicrosoft365Licenses")}</span>
        )}
        {licenses.requestStatus === RequestStatus.SUCCESS &&
          !!microsoft365LicensesList.length &&
          microsoft365LicensesList.map((license) => (
            <div className={cn(styles.chartsItem, styles.smallCard)}>
              <Card className={styles.card} title={license.displayName}>
                <LicensesUsageChart license={license} />
                <div className={styles.totalPrepaid}>
                  {t("totalPrepaid", { quantity: license.prepaidUnits.enabled })}
                </div>
              </Card>
            </div>
          ))}
        {licenses.requestStatus === RequestStatus.FAILURE && (
          <div className={cn(styles.chartsItem, styles.smallCard)}>
            <Card className={cn(styles.card, styles.placeholderCard)}>
              <CardFallback onReload={reloadData} />
            </Card>
          </div>
        )}
      </div>

      <h2 className={styles.title}>{t("otherSubtitle")}</h2>
      <Card className={styles.card} data-testId="otherLicensesBlock">
        <div className={styles.cardContent}>
          {licenses.requestStatus === RequestStatus.PENDING && <Spinner show />}
          {licenses.requestStatus === RequestStatus.SUCCESS && (
            <>
              <div className={styles.otherLicensesTable}>
                <div className={styles.filtersBlock}>
                  <a href={generateCsvExportUrl(selectedContractId, "licenses")} download>
                    <Button data-testId="exportAsCsvBtn">{t("common:exportAsCsvBtn")}</Button>
                  </a>
                </div>
                <Table
                  dataSource={otherLicensesList}
                  columns={columnsConfig}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortFieldChange={handleSortFieldChange}
                />
              </div>
              <PaginationBox
                perPage={perPage}
                currentPage={currentPage}
                pageCount={pageCount}
                onPerPageChange={handlePerPageChange}
                onChangePage={handlePageChange}
                className={styles.paginationBox}
              />
            </>
          )}
          {licenses.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
        </div>
      </Card>
    </>
  );
};

export default LicensesPageContent;
