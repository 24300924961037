import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Card } from "@skyportal/ui-kit";
import { composeChartOptions, getWindowsUpdateStatusData } from "./utils";
import { WindowsUpdateConfigModel } from "types/devicesApi.types";
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import { useThunkDispatch } from "store";
// import { contractSelectors } from "store/contracts";
// import { devicesSelectors, devicesThunks } from "store/devices";
// import { setDevicesCurrentPageAC, setDevicesSortFieldAC } from "store/devices/devices.actions";
// import { RequestStatus } from "types/common.types";

import styles from "../../../styles.module.css";

export interface WindowsUpdateStatusChartModel {
  windowsUpdateItem: WindowsUpdateConfigModel;
}

const WindowsUpdateStatusChart = ({ windowsUpdateItem }: WindowsUpdateStatusChartModel) => {
  // const { t } = useTranslation("devicesPage");
  // const dispatch = useDispatch();
  // const thunkDispatch = useThunkDispatch();
  // const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  // const { windowsUpdateId, requestStatus } = useSelector(devicesSelectors.getWindowsUpdateDevicesData);

  const chartOptions = useMemo(
    () => composeChartOptions(getWindowsUpdateStatusData(windowsUpdateItem)),
    [windowsUpdateItem]
  );

  // const handleShowDevicesBtnClick = () => {
  //   if (selectedContractId) {
  //     thunkDispatch(devicesThunks.getSelectedWindowsUpdateDevicesList(selectedContractId, windowsUpdateItem.id));
  //   }
  //   dispatch(setDevicesCurrentPageAC(1));
  //   dispatch(setDevicesSortFieldAC(""));
  // };

  return (
    <Card
      className={styles.windowsUpdateCard}
      title={windowsUpdateItem.name}
      data-testid="windowsUpdateStatusChartContent"
    >
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <div className={styles.showBtnBlock}>
        {/* The button has been hidden because the corresponding API endpoint has been removed from the AZUR API */}

        {/* <Button
          data-testId={`showDevicesBtn-${windowsUpdateItem.id}`}
          onClick={handleShowDevicesBtnClick}
          loading={windowsUpdateId === windowsUpdateItem.id && requestStatus === RequestStatus.PENDING}
          disabled={windowsUpdateId === windowsUpdateItem.id && requestStatus === RequestStatus.SUCCESS}
        >
          {t("showDevicesBtn")}
        </Button> */}
      </div>
    </Card>
  );
};

export default WindowsUpdateStatusChart;
