import { DevicesEncryptionModel, DevicesFirewallModel } from "types/usageStatisticApi.types";

export function halfPieChartLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.2; font-family: Dustin Sans; margin-bottom: 4px; ">
      <div>${(this as any).name}</div>
    </div>`;
}

export const composeFirewallChartOptions = (firewallChartData: DevicesFirewallModel) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 150,
      spacingTop: 0,
    },
    colors: ["#4ef09d", "#ff5a5a"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    accessibility: {
      point: {
        valueSuffix: "pcs",
      },
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        innerSize: "60%",
        startAngle: -90,
        endAngle: 90,
        data: [
          ["On", firewallChartData.total - firewallChartData.firewallOff],
          ["Off", firewallChartData.firewallOff],
        ],
        center: ["50%", "155%"],
        size: "300%",
      },
    ],
    legend: {
      enabled: true,
      alignColumns: true,
      useHTML: true,
      labelFormatter: halfPieChartLegendLabelFormatter,
    },
  };
};

export const composeEncryptionChartOptions = (encryptionChartData: DevicesEncryptionModel) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 150,
      spacingTop: 0,
    },
    colors: ["#4ef09d", "#ff5a5a"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    accessibility: {
      point: {
        valueSuffix: "pcs",
      },
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        innerSize: "60%",
        startAngle: -90,
        endAngle: 90,
        data: [
          ["On", encryptionChartData.total - encryptionChartData.notEncrypted],
          ["Off", encryptionChartData.notEncrypted],
        ],
        center: ["50%", "155%"],
        size: "300%",
      },
    ],
    legend: {
      enabled: true,
      alignColumns: true,
      useHTML: true,
      labelFormatter: halfPieChartLegendLabelFormatter,
    },
  };
};
