import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, Checkbox, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import PaginationBox from "containers/PaginationBox";
import useUnmanagedDevicesColumnConfig from "hooks/useUnmanagedDevicesColumnConfig";
import { initState } from "store/devices/devices.reducer";
import { devicesSelectors, devicesThunks } from "store/devices";
import {
  setDevicesSortFieldAC,
  setUnmanagedDevicesFiltersAC,
  setDevicesCurrentPageAC,
  setDevicesPerPageAC,
} from "store/devices/devices.actions";
import { UNMANAGED_DEVICES_FILTER_KEY_LIST } from "utils/devicesTables";
import AutocompleteTableFilter from "containers/AutocompleteTableFilter";
import { generateCsvExportUrl } from "utils/helpers";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";

import styles from "../styles.module.css";

const UnmanagedDevicesTable = () => {
  const { t } = useTranslation("devicesPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  const columnsConfig = useUnmanagedDevicesColumnConfig();
  const { unmanagedDevices, sortField, sortOrder, currentPage, perPage } = useSelector(devicesSelectors.getSlice);
  const { list, pageCount } = useSelector(devicesSelectors.getUnmanagedDevicesTable);
  const [filterDraft, setFilterDraft] = useState(initState.unmanagedDevices.filters);

  useEffect(() => {
    dispatch(setUnmanagedDevicesFiltersAC(filterDraft));
  }, [dispatch, filterDraft]);

  const handleDeviceNameFilterChange = (value: string) => {
    setFilterDraft({ ...filterDraft, deviceName: value });
    dispatch(setDevicesCurrentPageAC(1));
  };
  const handleFilterChange = useCallback(
    (keyIndex, filterValues) => {
      setFilterDraft({
        ...filterDraft,
        [keyIndex]: filterValues.map((filterValue: { value: any }) => filterValue.value),
      });
      dispatch(setDevicesCurrentPageAC(1));
    },
    [dispatch, filterDraft]
  );
  const handleIsCompliantCheckboxClick = useCallback(() => {
    setFilterDraft({ ...filterDraft, isCompliant: !filterDraft.isCompliant });
    dispatch(setDevicesCurrentPageAC(1));
  }, [dispatch, filterDraft]);
  const handleIsUncompliantCheckboxClick = useCallback(() => {
    setFilterDraft({ ...filterDraft, isUncompliant: !filterDraft.isUncompliant });
    dispatch(setDevicesCurrentPageAC(1));
  }, [dispatch, filterDraft]);

  const handleResetFiltersBtnClick = useCallback(() => setFilterDraft(initState.unmanagedDevices.filters), []);

  const handleSortFieldChange = useCallback((field) => dispatch(setDevicesSortFieldAC(field)), [dispatch]);

  const handlePerPageChange = useCallback((value) => dispatch(setDevicesPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setDevicesCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(devicesThunks.getUnmanagedDevicesList(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <div className={styles.cardContent}>
      {unmanagedDevices.requestStatus === RequestStatus.PENDING && <Spinner show />}
      {unmanagedDevices.requestStatus === RequestStatus.SUCCESS && (
        <div className={styles.tableBlock}>
          <div className={styles.filtersBlock} data-testId="devicesFilterBlock">
            <div className={styles.filterBar}>
              <div className={styles.filterLabel}>{t("filterLabels.deviceName")}</div>
              <TextInput
                data-testId="devicesFilter-deviceName"
                className={styles.filterInput}
                placeholder={t("deviceNamePlaceholder")}
                value={unmanagedDevices.filters.deviceName}
                onChange={(e) => handleDeviceNameFilterChange(e.target.value)}
              />
            </div>

            {UNMANAGED_DEVICES_FILTER_KEY_LIST.map((key) => (
              <AutocompleteTableFilter
                key={key}
                list={unmanagedDevices.list}
                currentFilterValues={unmanagedDevices.filters[key]}
                keyIndex={key}
                onChange={handleFilterChange}
                testIdPrefix="devicesFilter"
                localeObjectName="devicesPage"
              />
            ))}

            <div className={styles.checkboxFilterBlock}>
              <div className={styles.filterLabel}>{t("filterLabels.complianceState")}</div>
              <div className={styles.checkboxesBlock}>
                <Checkbox
                  data-testId="devicesFilter-isCompliant"
                  checked={unmanagedDevices.filters.isCompliant}
                  onClick={handleIsCompliantCheckboxClick}
                  size="small"
                />
                <span className={styles.checkboxLabel}>{t("common:Yes")}</span>
                <Checkbox
                  data-testId="devicesFilter-isUncompliant"
                  checked={unmanagedDevices.filters.isUncompliant}
                  onClick={handleIsUncompliantCheckboxClick}
                  size="small"
                />
                <span className={styles.checkboxLabel}>{t("common:No")}</span>
              </div>
            </div>

            <Button
              type="secondary"
              className={styles.resetBtn}
              onClick={handleResetFiltersBtnClick}
              data-testId="devicesResetFilterBtn"
            >
              {t("common:resetFiltersBtn")}
            </Button>
            <a
              className={styles.exportBtn}
              href={generateCsvExportUrl(selectedContractId, "devices/unmanaged")}
              download
            >
              <Button data-testId="exportAsCsvBtn">{t("common:exportAsCsvBtn")}</Button>
            </a>
          </div>
          <div className={styles.devicesTable}>
            <Table
              dataSource={list}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
          />
        </div>
      )}
      {unmanagedDevices.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </div>
  );
};

export default UnmanagedDevicesTable;
