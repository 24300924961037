import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import LicensesPageContent from "components/LicensesPageContent";

const LicensesPage: React.FC = () => {
  const { t } = useTranslation("licensesPage");

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <LicensesPageContent />
    </PageLayout>
  );
};

export default LicensesPage;
