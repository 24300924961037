import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { appsSelectors, appsThunks } from "store/applications";

const useFetchApplications = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  useEffect(() => {
    if (selectedContractId) {
      thunkDispatch(appsThunks.getManagedAppsList(selectedContractId));
      thunkDispatch(appsThunks.getDetectedAppsList(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId]);

  return useSelector(appsSelectors.getSlice);
};

export default useFetchApplications;
