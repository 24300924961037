import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import PaginationBox from "containers/PaginationBox";
import useManagedDevicesColumnConfig from "hooks/useManagedDevicesColumnConfig";
import { initState } from "store/devices/devices.reducer";
import { devicesSelectors, devicesThunks } from "store/devices";
import {
  setDevicesSortFieldAC,
  setManagedDevicesFiltersAC,
  setDevicesCurrentPageAC,
  setDevicesPerPageAC,
} from "store/devices/devices.actions";
import { MANAGED_DEVICES_FILTER_KEY_LIST } from "utils/devicesTables";
import AutocompleteTableFilter from "containers/AutocompleteTableFilter";
import { generateCsvExportUrl } from "utils/helpers";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";

import styles from "../styles.module.css";

const ManagedDevicesTable = () => {
  const { t } = useTranslation("devicesPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  const columnsConfig = useManagedDevicesColumnConfig();
  const { managedDevices, sortField, sortOrder, currentPage, perPage } = useSelector(devicesSelectors.getSlice);
  const { list, pageCount } = useSelector(devicesSelectors.getManagedDevicesTable);
  const [filterDraft, setFilterDraft] = useState(initState.managedDevices.filters);

  useEffect(() => {
    dispatch(setManagedDevicesFiltersAC(filterDraft));
  }, [dispatch, filterDraft]);

  const handleDeviceNameFilterChange = (value: string) => {
    setFilterDraft({ ...filterDraft, deviceName: value });
    dispatch(setDevicesCurrentPageAC(1));
  };

  const handleFilterChange = useCallback(
    (keyIndex, filterValues) => {
      setFilterDraft({
        ...filterDraft,
        [keyIndex]: filterValues.map((filterValue: { value: any }) => filterValue.value),
      });
      dispatch(setDevicesCurrentPageAC(1));
    },
    [dispatch, filterDraft]
  );
  const handleResetFiltersBtnClick = useCallback(() => setFilterDraft(initState.managedDevices.filters), []);

  const handleSortFieldChange = useCallback((field) => dispatch(setDevicesSortFieldAC(field)), [dispatch]);

  const handlePerPageChange = useCallback((value) => dispatch(setDevicesPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setDevicesCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(devicesThunks.getManagedDevicesList(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <div className={styles.cardContent}>
      {managedDevices.requestStatus === RequestStatus.PENDING && <Spinner show />}
      {managedDevices.requestStatus === RequestStatus.SUCCESS && (
        <div className={styles.tableBlock}>
          <div className={styles.filtersBlock}>
            <div className={styles.filterBar}>
              <div className={styles.filterLabel}>{t("filterLabels.deviceName")}</div>
              <TextInput
                data-testId="devicesFilter-deviceName"
                className={styles.filterInput}
                placeholder={t("deviceNamePlaceholder")}
                value={managedDevices.filters.deviceName}
                onChange={(e) => handleDeviceNameFilterChange(e.target.value)}
              />
            </div>
            {MANAGED_DEVICES_FILTER_KEY_LIST.map((key) => (
              <AutocompleteTableFilter
                key={key}
                list={managedDevices.list}
                currentFilterValues={managedDevices.filters[key]}
                keyIndex={key}
                onChange={handleFilterChange}
                testIdPrefix="devicesFilter"
                localeObjectName="devicesPage"
              />
            ))}
            <Button
              type="secondary"
              className={styles.resetBtn}
              onClick={handleResetFiltersBtnClick}
              data-testId="devicesResetFilterBtn"
            >
              {t("common:resetFiltersBtn")}
            </Button>
            <a className={styles.exportBtn} href={generateCsvExportUrl(selectedContractId, "devices/managed")} download>
              <Button data-testId="exportAsCsvBtn">{t("common:exportAsCsvBtn")}</Button>
            </a>
          </div>
          <div className={styles.devicesTable}>
            <Table
              dataSource={list}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
          />
        </div>
      )}
      {managedDevices.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </div>
  );
};

export default ManagedDevicesTable;
