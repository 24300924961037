import * as types from "./users.types";

export const allUsersRequestAC: types.AllUsersRequestAC = () => ({
  type: types.ALL_USERS_REQUEST,
});

export const allUsersRequestSuccessAC: types.AllUsersRequestSuccessAC = (payload) => ({
  type: types.ALL_USERS_REQUEST_SUCCESS,
  payload,
});

export const allUsersRequestFailureAC: types.AllUsersRequestFailureAC = () => ({
  type: types.ALL_USERS_REQUEST_FAILURE,
});

export const authenticatedUsersRequestAC: types.AuthenticatedUsersRequestAC = () => ({
  type: types.AUTHENTICATED_USERS_REQUEST,
});

export const authenticatedUsersRequestSuccessAC: types.AuthenticatedUsersRequestSuccessAC = (payload) => ({
  type: types.AUTHENTICATED_USERS_REQUEST_SUCCESS,
  payload,
});

export const authenticatedUsersRequestFailureAC: types.AuthenticatedUsersRequestFailureAC = () => ({
  type: types.AUTHENTICATED_USERS_REQUEST_FAILURE,
});

export const setUsersSortFieldAC: types.SetUsersSortFieldAC = (payload) => ({
  type: types.SET_USERS_SORT_FIELD,
  payload,
});

export const setAllUsersFiltersAC: types.SetAllUsersFiltersAC = (payload) => ({
  type: types.SET_ALL_USERS_FILTERS,
  payload,
});

export const setAuthenticatedUsersFiltersAC: types.SetAuthenticatedUsersFiltersAC = (payload) => ({
  type: types.SET_AUTHENTICATED_USERS_FILTERS,
  payload,
});

export const setUsersPerPageAC: types.SetUsersPerPageAC = (payload) => ({
  type: types.SET_USERS_PER_PAGE,
  payload,
});

export const setUsersCurrentPageAC: types.SetUsersCurrentPageAC = (payload) => ({
  type: types.SET_USERS_CURRENT_PAGE,
  payload,
});
