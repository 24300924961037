import { UsageStatisticCardConfigType, UsageStatisticCardDataType, UsageStatisticCardType } from "./types";
import { usageStatisticSelectors, usageStatisticThunks } from "store/usageStatistic";

export const USERS_VARIANT = "USERS_VARIANT";

export const DEVICES_VARIANT = "DEVICES_VARIANT";

export const LICENSES_VARIANT = "LICENSES_VARIANT";

export const APPS_VARIANT = "APPS_VARIANT";

export const USAGE_STATISTICS_VARIANTS: Array<UsageStatisticCardType> = [
  DEVICES_VARIANT,
  USERS_VARIANT,
  LICENSES_VARIANT,
  APPS_VARIANT,
];

export const UsageStatisticCardData: UsageStatisticCardDataType = {
  [DEVICES_VARIANT]: ["total", "enrolled", "compliant"],
  [USERS_VARIANT]: ["total", "guestUsers"],
  [LICENSES_VARIANT]: ["assigned", "available", "total"],
  [APPS_VARIANT]: ["managed", "detected"],
};

export const UsageStatisticCardsConfig: Record<UsageStatisticCardType, UsageStatisticCardConfigType> = {
  [DEVICES_VARIANT]: {
    selector: usageStatisticSelectors.getDevicesSummary,
    thunk: usageStatisticThunks.getDevicesSummaryInfo,
    labelPhrase: "usageStatisticBlock.devicesLabelEnding",
    subLabelPhrase: "usageStatisticBlock.devicesSublabelEnding",
    linkTo: "/mw/devices",
    style: "devicesVariant",
    icon: "tablet-alt",
    buttonColor: "lightPurple",
  },
  [USERS_VARIANT]: {
    selector: usageStatisticSelectors.getUsersSummary,
    thunk: usageStatisticThunks.getUsersSummaryInfo,
    labelPhrase: "usageStatisticBlock.usersLabelEnding",
    subLabelPhrase: "usageStatisticBlock.usersSublabelEnding",
    linkTo: "/mw/users",
    style: "usersVariant",
    icon: "users",
    buttonColor: "lightBlue",
  },
  [LICENSES_VARIANT]: {
    selector: usageStatisticSelectors.getLicensesSummary,
    thunk: usageStatisticThunks.getLicensesSummaryInfo,
    labelPhrase: "usageStatisticBlock.licensesLabelEnding",
    subLabelPhrase: "usageStatisticBlock.licensesSublabelEnding",
    linkTo: "/mw/licenses",
    style: "licensesVariant",
    icon: "award",
    buttonColor: "lightBrown",
  },
  [APPS_VARIANT]: {
    selector: usageStatisticSelectors.getAppsSummary,
    thunk: usageStatisticThunks.getAppsSummaryInfo,
    labelPhrase: "usageStatisticBlock.appsLabelEnding",
    subLabelPhrase: "usageStatisticBlock.appsSublabelEnding",
    linkTo: "/mw/apps",
    style: "appsVariant",
    icon: "tasks",
    buttonColor: "lightGreen",
  },
};
