import { AllUserModel, AuthenticatedUserModel } from "types/usersApi.types";
import { makeUniversalSort } from "./sort";

export const displayNameNormalizer = (item: AllUserModel) => item.displayName.toLowerCase();
export const mailNormalizer = (item: AllUserModel) => item.mail || "";
export const departmentNormalizer = (item: AllUserModel) => item.department || "";
export const jobTitleNormalizer = (item: AllUserModel) => item.jobTitle || "";
export const userTypeNormalizer = (item: AllUserModel) => item.userType;
export const accountEnabledNormalizer = (item: AllUserModel) => item.accountEnabled;

export const allUsersSortHandler: any = {
  displayName: makeUniversalSort(displayNameNormalizer),
  mail: makeUniversalSort(mailNormalizer),
  department: makeUniversalSort(departmentNormalizer),
  jobTitle: makeUniversalSort(jobTitleNormalizer),
  userType: makeUniversalSort(userTypeNormalizer),
  accountEnabled: makeUniversalSort(accountEnabledNormalizer),
};

export type AllUsersFilterKeyListType = "department" | "userType";
export const ALL_USERS_FILTER_KEY_LIST: AllUsersFilterKeyListType[] = ["department", "userType"];

export type AuthenticatedUsersFilterKeyType = "userType" | "methodsRegistered";
export const AUTHENTICATED_USERS_USER_TYPE_FILTER_KEY: AuthenticatedUsersFilterKeyType = "userType";
export const AUTHENTICATED_USERS_METHODS_REGISTERED_FILTER_KEY: AuthenticatedUsersFilterKeyType = "methodsRegistered";

export const userDisplayNameNormalizer = (item: AuthenticatedUserModel) => item.userDisplayName.toLowerCase();
export const userPrincipalNameNormalizer = (item: AuthenticatedUserModel) => item.userPrincipalName || "";
export const isAdminNormalizer = (item: AuthenticatedUserModel) => item.isAdmin;
export const isMfaCapableNormalizer = (item: AuthenticatedUserModel) => item.isMfaCapable;
export const isSsprCapableNormalizer = (item: AuthenticatedUserModel) => item.isSsprCapable;
export const isPasswordlessCapableNormalizer = (item: AuthenticatedUserModel) => item.isPasswordlessCapable;

export const authenticatedUsersSortHandler: any = {
  userDisplayName: makeUniversalSort(userDisplayNameNormalizer),
  userPrincipalName: makeUniversalSort(userPrincipalNameNormalizer),
  userType: makeUniversalSort(userTypeNormalizer),
  isAdmin: makeUniversalSort(isAdminNormalizer),
  isMfaCapable: makeUniversalSort(isMfaCapableNormalizer),
  isSsprCapable: makeUniversalSort(isSsprCapableNormalizer),
  isPasswordlessCapable: makeUniversalSort(isPasswordlessCapableNormalizer),
};
