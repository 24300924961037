import {
  getPaginationSlice,
  managedDevicesSortHandler,
  microsoftDefenderDevicesSortHandler,
  unmanagedDevicesSortHandler,
  windowsUpdateDevicesSortHandler,
} from "utils/devicesTables";
import { RootState } from "../index";
import {
  DevicesStateSlice,
  ManagedDevices,
  MicrosoftDefender,
  UnmanagedDevices,
  WindowsUpdates,
} from "./devices.types";

export const getSlice = (state: RootState): DevicesStateSlice => state.devices;

export const getManagedDevicesList = (state: RootState): ManagedDevices["list"] => getSlice(state).managedDevices.list;

export const getUnmanagedDevicesList = (state: RootState): UnmanagedDevices["list"] =>
  getSlice(state).unmanagedDevices.list;

export const getWindowsUpdatesData = (state: RootState): WindowsUpdates => getSlice(state).windowsUpdates;
export const getWindowsUpdateDevicesData = (state: RootState): WindowsUpdates["selectedWindowsUpdateDevices"] =>
  getSlice(state).windowsUpdates.selectedWindowsUpdateDevices;
export const getMicrosoftDefenderData = (state: RootState): MicrosoftDefender => getSlice(state).microsoftDefender;

const managedDevicesListComposer = (devicesSlice: DevicesStateSlice) => {
  const {
    managedDevices: { list, filters },
    sortField,
    sortOrder,
    perPage,
    currentPage,
  } = devicesSlice;
  let _list = [...list];

  if (filters.deviceName) {
    _list = _list.filter((item) => item.deviceName.toLowerCase().includes(filters.deviceName.toLowerCase()));
  }

  if (filters.manufacturer.length || filters.complianceState.length) {
    const autocompleteFieldFilterNames = Object.keys(filters).filter((item) => item !== "deviceName");

    autocompleteFieldFilterNames.forEach((key) => {
      if ((filters as any)[key].length) {
        _list = _list.filter((item) => (filters as any)[key].includes((item as any)[key]));
      }
    });
  }

  if (sortField && managedDevicesSortHandler[sortField]) {
    _list.sort(managedDevicesSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getManagedDevicesTable = (state: RootState) => {
  const devicesSlice = getSlice(state);
  return managedDevicesListComposer(devicesSlice);
};

const unmanagedDevicesListComposer = (devicesSlice: DevicesStateSlice) => {
  const {
    unmanagedDevices: { list, filters },
    sortField,
    sortOrder,
    perPage,
    currentPage,
  } = devicesSlice;
  let _list = [...list];

  if (filters.deviceName) {
    _list = _list.filter((item) => item.deviceName.toLowerCase().includes(filters.deviceName.toLowerCase()));
  }

  if (filters.manufacturer.length) {
    const autocompleteFieldFilterNames = Object.keys(filters).filter((item) => item !== "deviceName");

    autocompleteFieldFilterNames.forEach((key) => {
      if ((filters as any)[key].length) {
        _list = _list.filter((item) => (filters as any)[key].includes((item as any)[key]));
      }
    });
  }

  if (filters.isCompliant && filters.isUncompliant)
    _list = _list.filter((item) => item.isCompliant === true || item.isCompliant === false);
  else if (filters.isCompliant) _list = _list.filter((item) => item.isCompliant === true);
  else if (filters.isUncompliant) _list = _list.filter((item) => item.isCompliant === false);

  if (sortField && unmanagedDevicesSortHandler[sortField]) {
    _list.sort(unmanagedDevicesSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getUnmanagedDevicesTable = (state: RootState) => {
  const devicesSlice = getSlice(state);
  return unmanagedDevicesListComposer(devicesSlice);
};

const windowsUpdateDevicesListComposer = (devicesSlice: DevicesStateSlice) => {
  const {
    windowsUpdates: {
      selectedWindowsUpdateDevices: { list, filters },
    },
    sortField,
    sortOrder,
    perPage,
    currentPage,
  } = devicesSlice;
  let _list = [...list];

  if (filters.deviceOrUserName) {
    _list = _list.filter(
      (item) =>
        item.deviceDisplayName.toLowerCase().includes(filters.deviceOrUserName.toLowerCase()) ||
        item.userPrincipalName?.toLowerCase().includes(filters.deviceOrUserName.toLowerCase())
    );
  }

  if (filters.status.length) {
    const autocompleteFieldFilterNames = Object.keys(filters).filter((item) => item !== "deviceOrUserName");

    autocompleteFieldFilterNames.forEach((key) => {
      if ((filters as any)[key].length) {
        _list = _list.filter((item) => (filters as any)[key].includes((item as any)[key]));
      }
    });
  }

  if (sortField && windowsUpdateDevicesSortHandler[sortField]) {
    _list.sort(windowsUpdateDevicesSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getWindowsUpdateDevicesTable = (state: RootState) => {
  const devicesSlice = getSlice(state);
  return windowsUpdateDevicesListComposer(devicesSlice);
};

const microsoftDefenderDevicesListComposer = (devicesSlice: DevicesStateSlice) => {
  const {
    microsoftDefender: { devices, filters },
    sortField,
    sortOrder,
    perPage,
    currentPage,
  } = devicesSlice;
  let _list = [...devices];

  if (filters.deviceOrUserName) {
    _list = _list.filter(
      (item) =>
        item.deviceName.toLowerCase().includes(filters.deviceOrUserName.toLowerCase()) ||
        item.userName?.toLowerCase().includes(filters.deviceOrUserName.toLowerCase())
    );
  }

  if (filters.deviceState.length) {
    const autocompleteFieldFilterNames = Object.keys(filters).filter((item) => item !== "deviceOrUserName");

    autocompleteFieldFilterNames.forEach((key) => {
      if ((filters as any)[key].length) {
        _list = _list.filter((item) => (filters as any)[key].includes((item as any)[key]));
      }
    });
  }

  if (sortField && microsoftDefenderDevicesSortHandler[sortField]) {
    _list.sort(microsoftDefenderDevicesSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getMicrosoftDefenderDevicesTable = (state: RootState) => {
  const devicesSlice = getSlice(state);
  return microsoftDefenderDevicesListComposer(devicesSlice);
};
