import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDateAndTime, laterThanYear2000 } from "utils/time";

const useMicrosoftDefenderDevicesColumnConfig = () => {
  const { t } = useTranslation("devicesPage");
  const { t: tCommon } = useTranslation("common");

  return useMemo(
    () =>
      [
        {
          key: "deviceName",
          dataIndex: "deviceName",
          title: t("microsoftDefenderDevicesTableColumns.deviceName"),
          width: 150,
        },
        {
          key: "userName",
          dataIndex: "userName",
          title: t("microsoftDefenderDevicesTableColumns.userName"),
        },
        {
          key: "antiMalwareVersion",
          dataIndex: "antiMalwareVersion",
          title: t("microsoftDefenderDevicesTableColumns.antiMalwareVersion"),
        },
        {
          key: "deviceState",
          dataIndex: "deviceState",
          title: t("microsoftDefenderDevicesTableColumns.deviceState"),
        },
        {
          key: "criticalFailure",
          dataIndex: "criticalFailure",
          title: t("microsoftDefenderDevicesTableColumns.criticalFailure"),
          render: (value: any) => <span>{value ? tCommon("Yes") : tCommon("No")}</span>,
        },
        {
          key: "encryption",
          dataIndex: "encryption",
          title: t("microsoftDefenderDevicesTableColumns.encryption"),
        },
        {
          key: "firewall",
          dataIndex: "firewall",
          title: t("microsoftDefenderDevicesTableColumns.firewall"),
        },
        {
          key: "lastQuickScanDateTime",
          dataIndex: "lastQuickScanDateTime",
          title: t("microsoftDefenderDevicesTableColumns.lastQuickScanDateTime"),
          render: (value: any) => <span>{laterThanYear2000(value) ? formatDateAndTime(value) : ""}</span>,
        },
        {
          key: "lastReportedDateTime",
          dataIndex: "lastReportedDateTime",
          title: t("microsoftDefenderDevicesTableColumns.lastReportedDateTime"),
          render: (value: any) => <span>{laterThanYear2000(value) ? formatDateAndTime(value) : ""}</span>,
        },
      ].filter(Boolean),
    [t, tCommon]
  );
};

export default useMicrosoftDefenderDevicesColumnConfig;
