import { RequestStatus } from "types/common.types";
import { UsersActions, UsersStateSlice } from "./users.types";

export const initState: UsersStateSlice = {
  allUsers: {
    list: [],
    filters: { displayNameOrMail: "", department: [], userType: [], isActive: true, isInactive: true },
    requestStatus: RequestStatus.UNCALLED,
  },
  authenticatedUsers: {
    list: [],
    filters: {
      displayNameOrMail: "",
      userType: [],
      isMfaCapable: true,
      isNotMfaCapable: true,
      isSsprCapable: true,
      isNotSsprCapable: true,
      methodsRegistered: [],
    },
    requestStatus: RequestStatus.UNCALLED,
  },
  sortField: "",
  sortOrder: "desc",
  perPage: 10,
  currentPage: 1,
};

export default function contractReducer(state: UsersStateSlice = initState, action: UsersActions): UsersStateSlice {
  switch (action.type) {
    case "ALL_USERS_REQUEST":
      return {
        ...state,
        allUsers: { ...state.allUsers, requestStatus: RequestStatus.PENDING },
      };
    case "ALL_USERS_REQUEST_SUCCESS":
      return {
        ...state,
        allUsers: { ...initState.allUsers, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    case "ALL_USERS_REQUEST_FAILURE":
      return {
        ...state,
        allUsers: { ...initState.allUsers, requestStatus: RequestStatus.FAILURE },
      };
    case "AUTHENTICATED_USERS_REQUEST":
      return {
        ...state,
        authenticatedUsers: { ...state.authenticatedUsers, requestStatus: RequestStatus.PENDING },
      };
    case "AUTHENTICATED_USERS_REQUEST_SUCCESS":
      return {
        ...state,
        authenticatedUsers: {
          ...initState.authenticatedUsers,
          list: action.payload,
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    case "AUTHENTICATED_USERS_REQUEST_FAILURE":
      return {
        ...state,
        authenticatedUsers: { ...initState.authenticatedUsers, requestStatus: RequestStatus.FAILURE },
      };
    case "SET_USERS_SORT_FIELD": {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case "SET_ALL_USERS_FILTERS": {
      return {
        ...state,
        allUsers: { ...state.allUsers, filters: action.payload },
      };
    }
    case "SET_AUTHENTICATED_USERS_FILTERS": {
      return {
        ...state,
        authenticatedUsers: { ...state.authenticatedUsers, filters: action.payload },
      };
    }
    case "SET_USERS_PER_PAGE": {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case "SET_USERS_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
}
