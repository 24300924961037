import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useDetectedAppsColumnConfig = () => {
  const { t } = useTranslation("applicationsPage");

  return useMemo(
    () =>
      [
        {
          key: "name",
          dataIndex: "name",
          title: t("detectedAppsTableColumns.name"),
          width: "50%",
        },
        {
          key: "version",
          dataIndex: "version",
          title: t("detectedAppsTableColumns.version"),
        },
        {
          key: "deviceCount",
          dataIndex: "deviceCount",
          title: t("detectedAppsTableColumns.deviceCount"),
        },
      ].filter(Boolean),
    [t]
  );
};

export default useDetectedAppsColumnConfig;
