import React, { useEffect, useState } from "react";
import { Card } from "@skyportal/ui-kit";
import useFetchUsers from "hooks/useFetchUsers";
import CardTabs from "../../containers/CardTabs";
import { setUsersCurrentPageAC, setUsersSortFieldAC } from "store/users/users.actions";
import AllUsersTable from "./tables/AllUsersTable";
import AuthenticatedUsersTable from "./tables/AuthenticatedUsersTable";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import styles from "./styles.module.css";

export interface TabsConfigModel {
  name: string;
  path: string;
}

const tabsConfig: TabsConfigModel[] = [
  { name: "allUsersTab", path: "/mw/users/all" },
  { name: "authenticatedUsersTab", path: "/mw/users/authentication" },
];

const UsersPageContent = () => {
  useFetchUsers();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name
  );

  useEffect(() => {
    setActiveTab(tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name);
  }, [pathname]);

  return (
    <Card className={styles.card} data-testid="usersPageContent">
      <CardTabs
        tabsConfig={tabsConfig}
        localeObjectName="usersPage"
        activeTab={activeTab}
        resetSortField={setUsersSortFieldAC}
        resetCurrentPage={setUsersCurrentPageAC}
      />
      <Switch>
        <Route path={`${path}/all`} component={AllUsersTable} />
        <Route path={`${path}/authentication`} component={AuthenticatedUsersTable} />
        <Route path="*">
          <Redirect to={`${path}/all`} />
        </Route>
      </Switch>
    </Card>
  );
};

export default UsersPageContent;
