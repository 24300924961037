import { RequestStatus } from "types/common.types";
import { AllUserModel, AuthenticatedUserModel } from "types/usersApi.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface AllUsersFiltersModel {
  filters: {
    displayNameOrMail: string;
    department: string[];
    userType: string[];
    isActive: boolean;
    isInactive: boolean;
  };
}

export interface AuthenticatedUsersFiltersModel {
  filters: {
    displayNameOrMail: string;
    userType: string[];
    isMfaCapable: boolean;
    isNotMfaCapable: boolean;
    isSsprCapable: boolean;
    isNotSsprCapable: boolean;
    methodsRegistered: string[];
  };
}

export interface AllUsers extends WithRequestStatus, AllUsersFiltersModel {
  list: AllUserModel[];
}

export interface AuthenticatedUsers extends WithRequestStatus, AuthenticatedUsersFiltersModel {
  list: AuthenticatedUserModel[];
}
export type SortFieldType = keyof AllUserModel | keyof AuthenticatedUserModel | "";
export type SortOrderType = "asc" | "desc";

export interface UsersStateSlice {
  readonly allUsers: AllUsers;
  readonly authenticatedUsers: AuthenticatedUsers;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly perPage: number;
  readonly currentPage: number;
}

export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export type AllUsersRequestAction = { type: typeof ALL_USERS_REQUEST };
export type AllUsersRequestAC = () => AllUsersRequestAction;

export const ALL_USERS_REQUEST_SUCCESS = "ALL_USERS_REQUEST_SUCCESS";
export type AllUsersRequestSuccessAction = {
  type: typeof ALL_USERS_REQUEST_SUCCESS;
  payload: AllUserModel[];
};
export type AllUsersRequestSuccessAC = (payload: AllUserModel[]) => AllUsersRequestSuccessAction;

export const ALL_USERS_REQUEST_FAILURE = "ALL_USERS_REQUEST_FAILURE";
export type AllUsersRequestFailureAction = { type: typeof ALL_USERS_REQUEST_FAILURE };
export type AllUsersRequestFailureAC = () => AllUsersRequestFailureAction;

export const AUTHENTICATED_USERS_REQUEST = "AUTHENTICATED_USERS_REQUEST";
export type AuthenticatedUsersRequestAction = {
  type: typeof AUTHENTICATED_USERS_REQUEST;
};
export type AuthenticatedUsersRequestAC = () => AuthenticatedUsersRequestAction;

export const AUTHENTICATED_USERS_REQUEST_SUCCESS = "AUTHENTICATED_USERS_REQUEST_SUCCESS";
export type AuthenticatedUsersRequestSuccessAction = {
  type: typeof AUTHENTICATED_USERS_REQUEST_SUCCESS;
  payload: AuthenticatedUserModel[];
};
export type AuthenticatedUsersRequestSuccessAC = (
  payload: AuthenticatedUserModel[]
) => AuthenticatedUsersRequestSuccessAction;

export const AUTHENTICATED_USERS_REQUEST_FAILURE = "AUTHENTICATED_USERS_REQUEST_FAILURE";
export type AuthenticatedUsersRequestFailureAction = {
  type: typeof AUTHENTICATED_USERS_REQUEST_FAILURE;
};
export type AuthenticatedUsersRequestFailureAC = () => AuthenticatedUsersRequestFailureAction;

export const SET_USERS_SORT_FIELD = "SET_USERS_SORT_FIELD";
export type SetUsersSortFieldAction = {
  type: typeof SET_USERS_SORT_FIELD;
  payload: SortFieldType;
};
export type SetUsersSortFieldAC = (payload: SortFieldType) => SetUsersSortFieldAction;

export const SET_ALL_USERS_FILTERS = "SET_ALL_USERS_FILTERS";
export type SetAllUsersFiltersAction = {
  type: typeof SET_ALL_USERS_FILTERS;
  payload: AllUsersFiltersModel["filters"];
};
export type SetAllUsersFiltersAC = (payload: AllUsersFiltersModel["filters"]) => SetAllUsersFiltersAction;

export const SET_AUTHENTICATED_USERS_FILTERS = "SET_AUTHENTICATED_USERS_FILTERS";
export type SetAuthenticatedUsersFiltersAction = {
  type: typeof SET_AUTHENTICATED_USERS_FILTERS;
  payload: AuthenticatedUsersFiltersModel["filters"];
};
export type SetAuthenticatedUsersFiltersAC = (
  payload: AuthenticatedUsersFiltersModel["filters"]
) => SetAuthenticatedUsersFiltersAction;

export const SET_USERS_PER_PAGE = "SET_USERS_PER_PAGE";
export type SetUsersPerPageAction = {
  type: typeof SET_USERS_PER_PAGE;
  payload: number;
};
export type SetUsersPerPageAC = (payload: number) => SetUsersPerPageAction;

export const SET_USERS_CURRENT_PAGE = "SET_USERS_CURRENT_PAGE";
export type SetUsersCurrentPageAction = {
  type: typeof SET_USERS_CURRENT_PAGE;
  payload: number;
};
export type SetUsersCurrentPageAC = (payload: number) => SetUsersCurrentPageAction;

export type UsersActions =
  | AllUsersRequestAction
  | AllUsersRequestSuccessAction
  | AllUsersRequestFailureAction
  | AuthenticatedUsersRequestAction
  | AuthenticatedUsersRequestSuccessAction
  | AuthenticatedUsersRequestFailureAction
  | SetUsersSortFieldAction
  | SetAllUsersFiltersAction
  | SetAuthenticatedUsersFiltersAction
  | SetUsersPerPageAction
  | SetUsersCurrentPageAction;
