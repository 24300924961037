import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { Card } from "@skyportal/ui-kit";
import useFetchApplications from "hooks/useFetchApplications";
import CardTabs from "containers/CardTabs";
import { setAppsCurrentPageAC, setAppsSortFieldAC } from "store/applications/applications.actions";
import ManagedAppsTable from "./tables/ManagedAppsTable";
import DetectedAppsTable from "./tables/DetectedAppsTable";

import styles from "./styles.module.css";

export interface TabsConfigModel {
  name: string;
  path: string;
}

const tabsConfig: TabsConfigModel[] = [
  { name: "managedAppsTab", path: "/mw/apps/managed" },
  { name: "detectedAppsTab", path: "/mw/apps/detected" },
];

const AppsPageContent = () => {
  useFetchApplications();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name
  );

  useEffect(() => {
    setActiveTab(tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name);
  }, [pathname]);

  return (
    <Card className={styles.card} data-testid="appsPageContent">
      <CardTabs
        tabsConfig={tabsConfig}
        localeObjectName="applicationsPage"
        activeTab={activeTab}
        resetSortField={setAppsSortFieldAC}
        resetCurrentPage={setAppsCurrentPageAC}
      />
      <Switch>
        <Route path={`${path}/managed`} component={ManagedAppsTable} />
        <Route path={`${path}/detected`} component={DetectedAppsTable} />
        <Route path="*">
          <Redirect to={`${path}/managed`} />
        </Route>
      </Switch>
    </Card>
  );
};

export default AppsPageContent;
