import { OperatingSystemModel } from "types/usageStatisticApi.types";

export function pieChartLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.2; font-family: Dustin Sans; margin-bottom: 4px; ">
      <div>${(this as any).name}</div>
      <div style="color: #8D8D8D; font-size:12px; line-height: 1.2">
        ${(this as any).percentage.toFixed(1)}%
      </div>
    </div>`;
}

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const addSpacesBeforeCapitalLetters = (string: string) => string.replace(/[A-Z]/g, " $&").trim();

export const composeChartOptions = (operatingSystems: OperatingSystemModel[]) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 350,
    },
    colors: ["#c48df7", "#44d9fc", "#b4b4b4", "#e59a7f", "#ff5a5a"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: operatingSystems.map((system) => {
          return {
            name: system.name,
            y: system.count,
          };
        }),
      },
    ],
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: pieChartLegendLabelFormatter,
    },
  };
};
