import { DetectedAppModel, ManagedAppModel } from "types/appsApi.types";
import { makeUniversalSort } from "./sort";

export const managedNameNormalizer = (item: ManagedAppModel) => item.name;
export const managedVersionNormalizer = (item: ManagedAppModel) => item.version;
export const publisherNormalizer = (item: ManagedAppModel) => item.publisher;
export const platformNormalizer = (item: ManagedAppModel) => item.platform;
export const failedDevicePercentageNormalizer = (item: ManagedAppModel) => item.failedDevicePercentage;
export const installedUserCountNormalizer = (item: ManagedAppModel) => item.installedUserCount;
export const installedDeviceCountNormalizer = (item: ManagedAppModel) => item.installedDeviceCount;

export const managedAppsSortHandler: any = {
  name: makeUniversalSort(managedNameNormalizer),
  version: makeUniversalSort(managedVersionNormalizer),
  publisher: makeUniversalSort(publisherNormalizer),
  platform: makeUniversalSort(platformNormalizer),
  failedDevicePercentage: makeUniversalSort(failedDevicePercentageNormalizer),
  installedUserCount: makeUniversalSort(installedUserCountNormalizer),
  installedDeviceCount: makeUniversalSort(installedDeviceCountNormalizer),
};

export type ManagedAppsFilterKeyListType = "publisher" | "platform";
export const MANAGED_APPS_FILTER_KEY_LIST: ManagedAppsFilterKeyListType[] = ["publisher", "platform"];

export const detectedNameNormalizer = (item: DetectedAppModel) => item.name;
export const detectedVersionNormalizer = (item: DetectedAppModel) => item.version;
export const deviceCountNormalizer = (item: DetectedAppModel) => item.deviceCount;

export const detectedAppsSortHandler: any = {
  name: makeUniversalSort(detectedNameNormalizer),
  version: makeUniversalSort(detectedVersionNormalizer),
  deviceCount: makeUniversalSort(deviceCountNormalizer),
};
