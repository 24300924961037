import ProgressBar from "containers/ProgressBar";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LicenseModel } from "types/licensesApi.types";

const ONE_MILLION = 1000000;

const useOtherLicensesColumnConfig = () => {
  const { t } = useTranslation("licensesPage");

  return useMemo(
    () =>
      [
        {
          key: "displayName",
          dataIndex: "displayName",
          title: t("otherLicensesTableColumns.productName"),
          width: "40%",
        },
        {
          key: "availableLicenses",
          dataIndex: "availableLicenses",
          title: t("otherLicensesTableColumns.availableLicenses"),
          width: "25%",
          render: (value: any, record: LicenseModel) => (
            <span>
              {record.prepaidUnits.enabled >= ONE_MILLION
                ? t("unlimited")
                : record.prepaidUnits.enabled - record.consumedUnits}
            </span>
          ),
        },
        {
          key: "assignedLicenses",
          dataIndex: "assignedLicenses",
          title: t("otherLicensesTableColumns.assignedLicenses"),
          width: "35%",
          sortDisabled: true,
          render: (value: any, record: LicenseModel) =>
            record.prepaidUnits.enabled >= ONE_MILLION ? (
              <span>{record.consumedUnits}</span>
            ) : (
              <ProgressBar
                completed={record.consumedUnits}
                total={record.prepaidUnits.enabled}
                fillColor="#e59a7f"
                bgColor="#4ef09d"
              />
            ),
        },
      ].filter(Boolean),
    [t]
  );
};

export default useOtherLicensesColumnConfig;
