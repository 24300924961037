import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { usageStatisticSelectors, usageStatisticThunks } from "store/usageStatistic";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { composeChartOptions } from "./utils";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const DevicesOperatingSystemChart = () => {
  const { t } = useTranslation("mwPage");
  const chartData = useSelector(usageStatisticSelectors.getDevicesOperationgSystemsSummary);

  const chartOptions = useMemo(() => composeChartOptions(chartData.operatingSystems), [chartData.operatingSystems]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(usageStatisticThunks.getDevicesOperatingSystemsInfo(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <Card
      className={styles.card}
      title={t("devicesOperatingSystemChartTitle")}
      data-testid="devicesOperatingSystemChartContent"
    >
      <div className={styles.cardContent}>
        {chartData.requestStatus === RequestStatus.PENDING && <Spinner show />}
        {chartData.requestStatus === RequestStatus.SUCCESS && (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        )}
        {chartData.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default DevicesOperatingSystemChart;
