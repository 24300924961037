import { AppThunk } from "store";
import * as mwApi from "api/licenses.api";
import * as licensesActions from "./licenses.actions";
import { notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";
import { parseApiErrorMessage } from "utils/helpers";

export const getLicensesList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(licensesActions.licensesListRequestAC());
      const { data } = await mwApi.getLicenses(selectedContractId);
      dispatch(licensesActions.licensesListRequestSuccessAC(data.licenses));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "LICENSES LIST REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:licensesListRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(licensesActions.licensesListRequestFailureAC());
    }
  };
