import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { usageStatisticSelectors, usageStatisticThunks } from "store/usageStatistic";
import { composeFirewallChartOptions, composeEncryptionChartOptions } from "./utils";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const FirewallEncryptionStatusChart = () => {
  const { t } = useTranslation("mwPage");
  const { requestStatus: firewallRequestStatus, ...firewallChartData } = useSelector(
    usageStatisticSelectors.getDevicesFirewallSummary
  );
  const { requestStatus: encryptionRequestStatus, ...encryptionChartData } = useSelector(
    usageStatisticSelectors.getDevicesEncryptionSummary
  );

  const firewallChartOptions = useMemo(() => composeFirewallChartOptions(firewallChartData), [firewallChartData]);
  const encryptionChartOptions = useMemo(
    () => composeEncryptionChartOptions(encryptionChartData),
    [encryptionChartData]
  );

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(usageStatisticThunks.getDevicesFirewallSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getDevicesEncryptionSummaryInfo(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <Card
      className={styles.card}
      title={t("firewallEncryptionStatusChartTitle")}
      data-testid="firewallEncryptionStatusChartContent"
    >
      <div className={styles.cardContent}>
        {(firewallRequestStatus === RequestStatus.PENDING || encryptionRequestStatus === RequestStatus.PENDING) && (
          <Spinner show />
        )}
        {firewallRequestStatus === RequestStatus.SUCCESS && encryptionRequestStatus === RequestStatus.SUCCESS && (
          <>
            <div className={styles.firewallStatusLink}>
              <Link to="/mw/devices/protection">{t("firewallStatusLink")}</Link>
            </div>
            <HighchartsReact highcharts={Highcharts} options={firewallChartOptions} />
            <div className={styles.firewallStatusLink}>
              <Link to="/mw/devices/protection">{t("encryptionStatusLink")}</Link>
            </div>
            <HighchartsReact highcharts={Highcharts} options={encryptionChartOptions} />
          </>
        )}
        {(firewallRequestStatus === RequestStatus.FAILURE || encryptionRequestStatus === RequestStatus.FAILURE) && (
          <CardFallback onReload={reloadData} />
        )}
      </div>
    </Card>
  );
};

export default FirewallEncryptionStatusChart;
