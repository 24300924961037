import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { devicesSelectors, devicesThunks } from "store/devices";
import { Button, Card, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import WindowsUpdateStatusChart from "./WindowsUpdateStatusChart";
import {
  setDevicesCurrentPageAC,
  setDevicesPerPageAC,
  setDevicesSortFieldAC,
  setWindowsUpdateDevicesFiltersAC,
} from "store/devices/devices.actions";
import useWindowsUpdateDevicesColumnConfig from "hooks/useWindowsUpdateDevicesColumnConfig";
import PaginationBox from "containers/PaginationBox";
import AutocompleteTableFilter from "containers/AutocompleteTableFilter";
import { initState } from "store/devices/devices.reducer";
import { WINDOWS_UPDATE_DEVICES_FILTER_KEY_LIST } from "utils/devicesTables";
import { RequestStatus } from "types/common.types";
import { generateCsvExportUrl } from "utils/helpers";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";
import { useThunkDispatch } from "store";

import styles from "../../styles.module.css";

const WindowsUpdateTabContent = () => {
  const { t } = useTranslation("devicesPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { sortField, sortOrder, currentPage, perPage, windowsUpdates } = useSelector(devicesSelectors.getSlice);
  const {
    list: windowsUpdatesList,
    requestStatus: windowsUpdatesRequestStatus,
    selectedWindowsUpdateDevices: { windowsUpdateId, filters, requestStatus },
  } = windowsUpdates;
  const { list, pageCount } = useSelector(devicesSelectors.getWindowsUpdateDevicesTable);
  const [filterDraft, setFilterDraft] = useState(initState.windowsUpdates.selectedWindowsUpdateDevices.filters);

  const columnsConfig = useWindowsUpdateDevicesColumnConfig();
  const selectedWindowsUpdate = useMemo(
    () => windowsUpdatesList.find((update) => update.id === windowsUpdateId),
    [windowsUpdateId, windowsUpdatesList]
  );

  useEffect(() => {
    dispatch(setWindowsUpdateDevicesFiltersAC(filterDraft));
  }, [dispatch, filterDraft]);

  const handleNameFilterChange = (value: string) => {
    setFilterDraft({ ...filterDraft, deviceOrUserName: value });
    dispatch(setDevicesCurrentPageAC(1));
  };

  const handleFilterChange = useCallback(
    (keyIndex, filterValues) => {
      setFilterDraft({
        ...filterDraft,
        [keyIndex]: filterValues.map((filterValue: { value: any }) => filterValue.value),
      });
      dispatch(setDevicesCurrentPageAC(1));
    },
    [dispatch, filterDraft]
  );

  const handleResetFiltersBtnClick = useCallback(
    () => setFilterDraft(initState.windowsUpdates.selectedWindowsUpdateDevices.filters),
    []
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setDevicesSortFieldAC(field)), [dispatch]);

  const handlePerPageChange = useCallback((value) => dispatch(setDevicesPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setDevicesCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadWindowsUpdateData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(devicesThunks.getWindowsUpdateConfigsList(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <div className={styles.cardContent}>
      {windowsUpdatesRequestStatus === RequestStatus.PENDING && <Spinner show />}
      {windowsUpdatesRequestStatus === RequestStatus.SUCCESS && (
        <div className={styles.chartsContainer}>
          {windowsUpdatesList.map((windowsUpdateItem) => (
            <div className={cn(styles.chartsItem, styles.smallCard)}>
              <WindowsUpdateStatusChart windowsUpdateItem={windowsUpdateItem} />
            </div>
          ))}
        </div>
      )}
      {windowsUpdatesRequestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadWindowsUpdateData} />}

      {selectedWindowsUpdate && requestStatus === RequestStatus.SUCCESS && (
        <div className={styles.windowsUpdateBlock}>
          <Card className={styles.windowsUpdateCard} title={`Devices (${selectedWindowsUpdate.name})`}>
            <div className={styles.tableBlock}>
              <div className={styles.filtersBlock}>
                <div className={styles.filterBar}>
                  <div className={styles.filterLabel}>{t("filterLabels.deviceOrUserName")}</div>
                  <TextInput
                    data-testId="devicesFilter-deviceOrUserName"
                    className={styles.filterInput}
                    placeholder={t("deviceOrUserNamePlaceholder")}
                    value={filters.deviceOrUserName}
                    onChange={(e) => handleNameFilterChange(e.target.value)}
                  />
                </div>
                {WINDOWS_UPDATE_DEVICES_FILTER_KEY_LIST.map((key) => (
                  <AutocompleteTableFilter
                    key={key}
                    list={list}
                    currentFilterValues={filters[key]}
                    keyIndex={key}
                    onChange={handleFilterChange}
                    testIdPrefix="devicesFilter"
                    localeObjectName="devicesPage"
                  />
                ))}
                <Button
                  type="secondary"
                  className={styles.resetBtn}
                  onClick={handleResetFiltersBtnClick}
                  data-testId="devicesResetFilterBtn"
                >
                  {t("common:resetFiltersBtn")}
                </Button>
                <a
                  className={styles.exportBtn}
                  href={generateCsvExportUrl(selectedContractId, `devices/windows-update/${selectedWindowsUpdate.id}`)}
                  download
                >
                  <Button data-testId="exportAsCsvBtn">{t("common:exportAsCsvBtn")}</Button>
                </a>
              </div>
              <div className={styles.devicesTable}>
                <Table
                  dataSource={list}
                  columns={columnsConfig}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortFieldChange={handleSortFieldChange}
                />
                <PaginationBox
                  perPage={perPage}
                  currentPage={currentPage}
                  pageCount={pageCount}
                  onPerPageChange={handlePerPageChange}
                  onChangePage={handlePageChange}
                />
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default WindowsUpdateTabContent;
