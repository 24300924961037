import { SortOrderType } from "store/devices/devices.types";
import { UserPermissionObjectInfo } from "@skyportal/auth-web-client/dist";

export const makeUniversalSort =
  (normalizer: (item: any) => string | number | boolean) => (sortWay: SortOrderType) => (a: any, b: any) => {
    const flag = sortWay === "asc" ? -1 : 1;
    try {
      return normalizer(a) >= normalizer(b) ? flag * -1 : flag;
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      return 0;
    }
  };

export const sortCustomersByOrganization = (list: UserPermissionObjectInfo[]): UserPermissionObjectInfo[] =>
  [...list].sort((a, b) =>
    (a.organization as string).toLowerCase() >= (b.organization as string).toLowerCase() ? 1 : -1
  );
