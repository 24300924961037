import { RootState } from "../index";

export const getSlice = (state: RootState) => state.usageStatistic;
export const getUsersSummary = (state: RootState) => getSlice(state).usersSummary;
export const getDevicesSummary = (state: RootState) => getSlice(state).devicesSummary;
export const getLicensesSummary = (state: RootState) => getSlice(state).licensesSummary;
export const getAppsSummary = (state: RootState) => getSlice(state).appsSummary;
export const getWindowsUpdateSummary = (state: RootState) => getSlice(state).windowsUpdateSummary;
export const getDevicesProtectionSummary = (state: RootState) => getSlice(state).devicesProtectionSummary;
export const getDevicesMalwareSummary = (state: RootState) => getSlice(state).devicesMalwareSummary;
export const getDevicesFirewallSummary = (state: RootState) => getSlice(state).devicesFirewallSummary;
export const getDevicesEncryptionSummary = (state: RootState) => getSlice(state).devicesEncryptionSummary;
export const getDevicesOperationgSystemsSummary = (state: RootState) => getSlice(state).devicesOperatingSystemsSummary;
export const getDevicesComplianceHistory = (state: RootState) => getSlice(state).devicesComplianceHistory;
export const getUsersTypesSummary = (state: RootState) => getSlice(state).usersTypesSummary;
export const getUsersServicesSummary = (state: RootState) => getSlice(state).usersServicesSummary;
export const getUsersAppsSummary = (state: RootState) => getSlice(state).usersAppsSummary;
