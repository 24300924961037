export const formatDate = (inputDate: string | number | Date): string => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
};

export const formatDateAndTime = (inputDate: string | number | Date): string => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")} ${String(hour).padStart(
    2,
    "0"
  )}:${String(minute).padStart(2, "0")}:${String(second).padStart(2, "0")}`;
};

export const formatAMPM = (inputDate: string | number | Date) => {
  const date = new Date(inputDate);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours !== 0 ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${String(minutes).padStart(2, "0")} ${ampm}`;
};

export const laterThanYear2000 = (inputDate: string | number | Date) => new Date(inputDate) > new Date("2000-01-01");
