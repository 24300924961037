import { RequestStatus } from "types/common.types";
import { LicensesActions, LicensesStateSlice } from "./licenses.types";

export const initState: LicensesStateSlice = {
  list: [],
  sortField: "",
  sortOrder: "desc",
  perPage: 10,
  currentPage: 1,
  requestStatus: RequestStatus.UNCALLED,
};

export default function contractReducer(
  state: LicensesStateSlice = initState,
  action: LicensesActions
): LicensesStateSlice {
  switch (action.type) {
    case "LICENSES_LIST_REQUEST":
      return {
        ...state,
        requestStatus: RequestStatus.PENDING,
      };
    case "LICENSES_LIST_REQUEST_SUCCESS":
      return {
        ...state,
        list: action.payload,
        requestStatus: RequestStatus.SUCCESS,
      };
    case "LICENSES_LIST_REQUEST_FAILURE":
      return {
        ...state,
        list: initState.list,
        requestStatus: RequestStatus.FAILURE,
      };
    case "SET_OTHER_LICENSES_SORT_FIELD": {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case "SET_LICENSES_PER_PAGE": {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case "SET_LICENSES_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
}
