export interface ManagedDeviceModel {
  id: string;
  deviceName: string;
  complianceState: string;
  manufacturer: string;
  model: string;
  operatingSystem: string;
  operatingSystemVersion: string;
  ownerType: string;
  serialNumber: string;
  emailAddress: string;
  userDisplayName: string;
  totalStorageSpaceInBytes: number;
  freeStorageSpaceInBytes: number;
}

export interface ManagedDevicesResponse {
  devices: ManagedDeviceModel[];
}

export interface UnmanagedDeviceModel {
  id: string;
  deviceName: string;
  isCompliant: boolean | null;
  manufacturer: string | null;
  model: string | null;
  operatingSystem: string | null;
  operatingSystemVersion: string | null;
  ownerType: string | null;
}

export interface UnmanagedDevicesResponse {
  devices: UnmanagedDeviceModel[];
}

export interface WindowsUpdateConfigModel {
  id: string;
  name: string;
  pending: number;
  success: number;
  error: number;
  failed: number;
  conflict: number;
  lastUpdate: string;
}

export interface WindowsUpdateSummaryResponse {
  windowsUpdateConfigurations: WindowsUpdateConfigModel[];
}

export interface WindowsUpdateDeviceModel {
  id: string;
  deviceId: string;
  userId: string;
  deviceDisplayName: string;
  userPrincipalName: string;
  status: string;
  qualityUpdateVersion: string;
  featureUpdateVersion: string;
  lastScanDateTime: string;
  lastSyncDateTime: string;
}

export interface WindowsUpdateDevicesResponse {
  devices: WindowsUpdateDeviceModel[];
}

export interface MicrosoftDefenderDeviceModel {
  antiMalwareVersion: string;
  criticalFailure: boolean;
  deviceId: string;
  deviceName: string;
  deviceState: string;
  encryption: string;
  engineVersion: string;
  firewall: string;
  fullScanOverdue: boolean;
  fullScanRequired: boolean;
  isVirtualMachine: boolean;
  isWDATPSenseRunning: boolean;
  lastFullScanDateTime: string;
  lastQuickScanDateTime: string;
  lastQuickScanSignatureVersion: string;
  lastReportedDateTime: string;
  malwareProtectionEnabled: boolean;
  networkInspectionSystemEnabled: boolean;
  pendingFullScan: boolean;
  pendingManualSteps: boolean;
  pendingOfflineScan: boolean;
  pendingReboot: boolean;
  quickScanOverdue: boolean;
  realTimeProtectionEnabled: boolean;
  rebootRequired: boolean;
  signatureUpdateOverdue: boolean;
  signatureVersion: string;
  tamperProtectionEnabled: boolean;
  userEmail: string;
  userName: string;
}

export interface MicrosoftDefenderReportModel {
  status: MicrosoftDefenderReportStatus | null;
}

export enum MicrosoftDefenderReportStatus {
  inProgress = "inProgress",
  completed = "completed",
}
export interface MicrosoftDefenderDevicesResponse {
  report: MicrosoftDefenderReportModel;
  devices: MicrosoftDefenderDeviceModel[] | [];
}
