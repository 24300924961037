import React, { useMemo } from "react";
import { Autocomplete } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { getFilterOptions } from "utils/devicesTables";

import styles from "./styles.module.css";

export interface AutocompleteTableFilterProps<ListItemModel> {
  list: ListItemModel[];
  keyIndex: string;
  currentFilterValues: string[];
  onChange: (keyIndex: any, value: any) => void;
  testIdPrefix: string;
  localeObjectName: string;
}

const AutocompleteTableFilter = ({
  list,
  keyIndex,
  currentFilterValues,
  onChange,
  testIdPrefix,
  localeObjectName,
}: AutocompleteTableFilterProps<any>) => {
  const { t } = useTranslation(localeObjectName);
  const options = useMemo(() => getFilterOptions(list, keyIndex), [keyIndex, list]);

  const handleFilterChange = (selectedOptionList: any) => onChange(keyIndex, selectedOptionList);
  const currentValue = useMemo(
    () =>
      currentFilterValues.map((filter) => ({
        label: filter,
        value: filter,
      })),
    [currentFilterValues]
  );

  return (
    <div className={styles.filterBar}>
      <div className={styles.filterLabel}>{t(`filterLabels.${keyIndex}`)}</div>
      <Autocomplete
        data-testId={`${testIdPrefix}-${keyIndex}`}
        className={styles.autocomplete}
        key={keyIndex}
        multiple
        disableClearable
        options={options}
        onChange={handleFilterChange}
        value={currentValue}
      />
    </div>
  );
};

export default AutocompleteTableFilter;
