import { AppThunk } from "store";
import { authClient } from "api/clients";
import config from "config";
import * as appActions from "./app.actions";
import * as contractActions from "../contracts/contracts.actions";
import { sortCustomersByOrganization } from "utils/sort";

export const getUserInfo = (): AppThunk => async (dispatch) => {
  try {
    const userProfile = await authClient.getUserProfile();
    const mwContracts = sortCustomersByOrganization(
      userProfile.permissions.entity.filter((contract) => contract.entityType === "MW")
    );

    if (!mwContracts.length) {
      window.location.href = config.baseUrl;
    }

    dispatch(appActions.setUserProfile(userProfile));
    dispatch(contractActions.setContractsList(mwContracts));
  } catch (error) {
    console.error(error, "USER INFO REQUEST");
  }
};
