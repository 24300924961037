import { RequestStatus } from "types/common.types";
import { AppsActions, AppsStateSlice } from "./applications.types";

export const initState: AppsStateSlice = {
  managedApps: {
    list: [],
    filters: { name: "", publisher: [], platform: [] },
    requestStatus: RequestStatus.UNCALLED,
  },
  detectedApps: {
    list: [],
    filters: { name: "" },
    requestStatus: RequestStatus.UNCALLED,
  },
  sortField: "",
  sortOrder: "desc",
  perPage: 10,
  currentPage: 1,
};

export default function contractReducer(state: AppsStateSlice = initState, action: AppsActions): AppsStateSlice {
  switch (action.type) {
    case "MANAGED_APPS_REQUEST":
      return {
        ...state,
        managedApps: { ...state.managedApps, requestStatus: RequestStatus.PENDING },
      };
    case "MANAGED_APPS_REQUEST_SUCCESS":
      return {
        ...state,
        managedApps: { ...initState.managedApps, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    case "MANAGED_APPS_REQUEST_FAILURE":
      return {
        ...state,
        managedApps: { ...initState.managedApps, requestStatus: RequestStatus.FAILURE },
      };
    case "DETECTED_APPS_REQUEST":
      return {
        ...state,
        detectedApps: { ...state.detectedApps, requestStatus: RequestStatus.PENDING },
      };
    case "DETECTED_APPS_REQUEST_SUCCESS":
      return {
        ...state,
        detectedApps: { ...initState.detectedApps, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    case "DETECTED_APPS_REQUEST_FAILURE":
      return {
        ...state,
        detectedApps: { ...initState.detectedApps, requestStatus: RequestStatus.FAILURE },
      };
    case "SET_APPS_SORT_FIELD": {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case "SET_MANAGED_APPS_FILTERS": {
      return {
        ...state,
        managedApps: { ...state.managedApps, filters: action.payload },
      };
    }
    case "SET_DETECTED_APPS_FILTERS": {
      return {
        ...state,
        detectedApps: { ...state.detectedApps, filters: action.payload },
      };
    }
    case "SET_APPS_PER_PAGE": {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case "SET_APPS_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
}
