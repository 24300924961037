import { RequestStatus } from "types/common.types";
import { MicrosoftDefenderReportStatus } from "types/devicesApi.types";
import * as types from "./devices.types";

export const initState: types.DevicesStateSlice = {
  managedDevices: {
    list: [],
    filters: { deviceName: "", manufacturer: [], complianceState: [] },
    requestStatus: RequestStatus.UNCALLED,
  },
  unmanagedDevices: {
    list: [],
    filters: { deviceName: "", manufacturer: [], isCompliant: false, isUncompliant: false },
    requestStatus: RequestStatus.UNCALLED,
  },
  windowsUpdates: {
    list: [],
    selectedWindowsUpdateDevices: {
      windowsUpdateId: "",
      list: [],
      requestStatus: RequestStatus.UNCALLED,
      filters: { deviceOrUserName: "", status: [] },
    },
    requestStatus: RequestStatus.UNCALLED,
  },
  microsoftDefender: {
    report: { status: null, callsCounter: 0 },
    devices: [],
    filters: { deviceOrUserName: "", deviceState: [] },
  },
  sortField: "",
  sortOrder: "desc",
  perPage: 10,
  currentPage: 1,
};

export default function contractReducer(
  state: types.DevicesStateSlice = initState,
  action: types.DevicesActions
): types.DevicesStateSlice {
  switch (action.type) {
    case types.MANAGED_DEVICES_REQUEST:
      return {
        ...state,
        managedDevices: { ...state.managedDevices, requestStatus: RequestStatus.PENDING },
      };
    case types.MANAGED_DEVICES_REQUEST_SUCCESS:
      return {
        ...state,
        managedDevices: { ...initState.managedDevices, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    case types.MANAGED_DEVICES_REQUEST_FAILURE:
      return {
        ...state,
        managedDevices: { ...initState.managedDevices, requestStatus: RequestStatus.FAILURE },
      };
    case types.UNMANAGED_DEVICES_REQUEST:
      return {
        ...state,
        unmanagedDevices: { ...state.unmanagedDevices, requestStatus: RequestStatus.PENDING },
      };
    case types.UNMANAGED_DEVICES_REQUEST_SUCCESS:
      return {
        ...state,
        unmanagedDevices: { ...initState.unmanagedDevices, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    case types.UNMANAGED_DEVICES_REQUEST_FAILURE:
      return {
        ...state,
        unmanagedDevices: { ...initState.unmanagedDevices, requestStatus: RequestStatus.FAILURE },
      };
    case types.WINDOWS_UPDATE_CONFIGS_REQUEST: {
      return {
        ...state,
        windowsUpdates: { ...initState.windowsUpdates, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.WINDOWS_UPDATE_CONFIGS_REQUEST_SUCCESS:
      return {
        ...state,
        windowsUpdates: { ...initState.windowsUpdates, list: action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    case types.WINDOWS_UPDATE_CONFIGS_REQUEST_FAILURE:
      return {
        ...state,
        windowsUpdates: { ...initState.windowsUpdates, requestStatus: RequestStatus.FAILURE },
      };
    case types.SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST:
      return {
        ...state,
        windowsUpdates: {
          ...state.windowsUpdates,
          selectedWindowsUpdateDevices: {
            ...initState.windowsUpdates.selectedWindowsUpdateDevices,
            list: state.windowsUpdates.selectedWindowsUpdateDevices.list,
            windowsUpdateId: action.payload,
            requestStatus: RequestStatus.PENDING,
          },
        },
      };
    case types.SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_SUCCESS:
      return {
        ...state,
        windowsUpdates: {
          ...state.windowsUpdates,
          selectedWindowsUpdateDevices: {
            ...state.windowsUpdates.selectedWindowsUpdateDevices,
            list: action.payload,
            requestStatus: RequestStatus.SUCCESS,
          },
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    case types.SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_FAILURE:
      return {
        ...state,
        windowsUpdates: {
          ...state.windowsUpdates,
          selectedWindowsUpdateDevices: {
            ...initState.windowsUpdates.selectedWindowsUpdateDevices,
            requestStatus: RequestStatus.FAILURE,
          },
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    case types.MICROSOFT_DEFENDER_DEVICES_REQUEST: {
      return {
        ...state,
        microsoftDefender: {
          ...initState.microsoftDefender,
          report: {
            ...state.microsoftDefender.report,
            status: MicrosoftDefenderReportStatus.inProgress,
          },
        },
      };
    }
    case types.MICROSOFT_DEFENDER_DEVICES_REQUEST_SUCCESS:
      return {
        ...state,
        microsoftDefender: {
          ...initState.microsoftDefender,
          devices: action.payload.devices,
          report: {
            callsCounter: state.microsoftDefender.report.callsCounter + 1,
            status: action.payload.report.status,
          },
        },
      };
    case types.MICROSOFT_DEFENDER_DEVICES_REQUEST_FAILURE:
      return {
        ...state,
        microsoftDefender: {
          ...initState.microsoftDefender,
          report: { callsCounter: state.microsoftDefender.report.callsCounter + 1, status: null },
        },
      };
    case types.SET_DEVICES_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case types.SET_MANAGED_DEVICES_FILTERS: {
      return {
        ...state,
        managedDevices: { ...state.managedDevices, filters: action.payload },
      };
    }
    case types.SET_UNMANAGED_DEVICES_FILTERS: {
      return {
        ...state,
        unmanagedDevices: { ...state.unmanagedDevices, filters: action.payload },
      };
    }
    case types.SET_WINDOWS_UPDATE_DEVICES_FILTERS: {
      return {
        ...state,
        windowsUpdates: {
          ...state.windowsUpdates,
          selectedWindowsUpdateDevices: {
            ...state.windowsUpdates.selectedWindowsUpdateDevices,
            filters: action.payload,
          },
        },
      };
    }
    case types.SET_MICROSOFT_DEFENDER_DEVICES_FILTERS: {
      return {
        ...state,
        microsoftDefender: { ...state.microsoftDefender, filters: action.payload },
      };
    }
    case types.SET_DEVICES_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case types.SET_DEVICES_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
}
