import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { usageStatisticSelectors, usageStatisticThunks } from "store/usageStatistic";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { composeChartData, composeChartOptions } from "./utils";
import { Card, Dropdown, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const DevicesComplianceHistoryChart = () => {
  const { t } = useTranslation("mwPage");
  const chartData = useSelector(usageStatisticSelectors.getDevicesComplianceHistory);
  const [currentDevice, setCurrentDevice] = useState("All");

  const composedData = useMemo(() => {
    const filteredDataByDevice =
      currentDevice === "All"
        ? chartData.history
        : chartData.history.filter((item) => item.deviceType === currentDevice);
    return composeChartData(filteredDataByDevice);
  }, [chartData.history, currentDevice]);
  const chartOptions = useMemo(() => composeChartOptions(composedData), [composedData]);

  const deviceDropdownOptions = useMemo(
    () =>
      chartData.history.reduce(
        (acc, item) => {
          if (item.deviceType && !acc.find((option) => option.label === item.deviceType)) {
            acc.push({ label: item.deviceType, value: item.deviceType });
          }
          return acc;
        },
        [{ label: "All", value: "All" }]
      ),
    [chartData.history]
  );
  const handleDeviceDropdownChange = (value: string) => setCurrentDevice(value);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(usageStatisticThunks.getDevicesComplianceHistoryInfo(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <Card
      className={styles.card}
      title={t("devicesComplianceHistoryChartTitle")}
      data-testid="devicesComplianceHistoryChartContent"
      controls={
        <div className={styles.devicesTypeDropdownBlock}>
          <Dropdown
            value={currentDevice}
            onChange={handleDeviceDropdownChange}
            options={deviceDropdownOptions}
            prefixIcon="phone"
            className={styles.devicesTypeDropdown}
          />
        </div>
      }
    >
      <div className={styles.cardContent}>
        {chartData.requestStatus === RequestStatus.PENDING && <Spinner show />}
        {chartData.requestStatus === RequestStatus.SUCCESS && (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        )}
        {chartData.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default DevicesComplianceHistoryChart;
