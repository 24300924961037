import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import { initState } from "store/applications/applications.reducer";
import { appsSelectors, appsThunks } from "store/applications";
import useManagedAppsColumnConfig from "hooks/useManagedAppsColumnConfig";
import {
  setAppsSortFieldAC,
  setManagedAppsFiltersAC,
  setAppsCurrentPageAC,
  setAppsPerPageAC,
} from "store/applications/applications.actions";
import { MANAGED_APPS_FILTER_KEY_LIST } from "utils/appsTables";
import AutocompleteTableFilter from "containers/AutocompleteTableFilter";
import PaginationBox from "containers/PaginationBox";
import { generateCsvExportUrl } from "utils/helpers";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";

import styles from "../styles.module.css";

const ManagedAppsTable = () => {
  const { t } = useTranslation("applicationsPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  const columnsConfig = useManagedAppsColumnConfig();
  const { managedApps, sortField, sortOrder, currentPage, perPage } = useSelector(appsSelectors.getSlice);
  const { list, pageCount } = useSelector(appsSelectors.getManagedAppsTable);
  const [filterDraft, setFilterDraft] = useState(initState.managedApps.filters);

  useEffect(() => {
    dispatch(setManagedAppsFiltersAC(filterDraft));
  }, [dispatch, filterDraft]);

  const handleNameFilterChange = (value: string) => {
    setFilterDraft({ ...filterDraft, name: value });
    dispatch(setAppsCurrentPageAC(1));
  };

  const handleFilterChange = useCallback(
    (keyIndex, filterValues) => {
      setFilterDraft({
        ...filterDraft,
        [keyIndex]: filterValues.map((filterValue: { value: any }) => filterValue.value),
      });
      dispatch(setAppsCurrentPageAC(1));
    },
    [dispatch, filterDraft]
  );
  const handleResetFiltersBtnClick = useCallback(() => setFilterDraft(initState.managedApps.filters), []);

  const handleSortFieldChange = useCallback((field) => dispatch(setAppsSortFieldAC(field)), [dispatch]);

  const handlePerPageChange = useCallback((value) => dispatch(setAppsPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setAppsCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(appsThunks.getManagedAppsList(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <div className={styles.cardContent}>
      {managedApps.requestStatus === RequestStatus.PENDING && <Spinner show />}
      {managedApps.requestStatus === RequestStatus.SUCCESS && (
        <div className={styles.tableBlock}>
          <div className={styles.filtersBlock}>
            <div className={styles.filterBar}>
              <div className={styles.filterLabel}>{t("filterLabels.name")}</div>
              <TextInput
                data-testId="appsFilter-name"
                className={styles.filterInput}
                placeholder={t("appsNamePlaceholder")}
                value={managedApps.filters.name}
                onChange={(e) => handleNameFilterChange(e.target.value)}
              />
            </div>
            {MANAGED_APPS_FILTER_KEY_LIST.map((key) => (
              <AutocompleteTableFilter
                key={key}
                list={managedApps.list}
                currentFilterValues={managedApps.filters[key]}
                keyIndex={key}
                onChange={handleFilterChange}
                testIdPrefix="appsFilter"
                localeObjectName="applicationsPage"
              />
            ))}
            <Button
              type="secondary"
              className={styles.resetBtn}
              onClick={handleResetFiltersBtnClick}
              data-testId="appsResetFilterBtn"
            >
              {t("common:resetFiltersBtn")}
            </Button>
            <a
              className={styles.exportBtn}
              href={generateCsvExportUrl(selectedContractId, "applications/managed")}
              download
            >
              <Button data-testId="exportAsCsvBtn">{t("common:exportAsCsvBtn")}</Button>
            </a>
          </div>
          <div className={styles.appsTable}>
            <Table
              dataSource={list}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
          />
        </div>
      )}
      {managedApps.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </div>
  );
};

export default ManagedAppsTable;
