import { pieChartLegendLabelFormatter } from "components/DevicesBlockCharts/charts/DevicesOperatingSystemChart/utils";
import { LicenseModel } from "types/licensesApi.types";

export const composeChartOptions = (license: LicenseModel) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 350,
    },
    colors: ["#4ef09d", "#e59a7f", "#44d9fc", "#c48df7", "#ff5a5a"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: [
          {
            name: "Available",
            y: license.prepaidUnits.enabled - license.consumedUnits,
          },
          {
            name: "Used",
            y: license.consumedUnits,
          },
        ],
      },
    ],
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: pieChartLegendLabelFormatter,
    },
  };
};
