// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mw_styles_root__F4Z2C {\n  margin-bottom: var(--padding-medium);\n}\n\n.mw_styles_titleBlock__ggDIW {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n@media (max-width: 767px) {\n  .mw_styles_subtitleBlock__rqyeg {\n    display: block;\n  }\n}\n\n.mw_styles_contractDropdown__NCrbG {\n  min-width: 240px !important;\n  padding-top: 7px !important;\n  padding-left: 32px !important;\n  margin: 8px 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/PageHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,6BAA6B;EAC7B,wBAAwB;AAC1B","sourcesContent":[".root {\n  margin-bottom: var(--padding-medium);\n}\n\n.titleBlock {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n@media (max-width: 767px) {\n  .subtitleBlock {\n    display: block;\n  }\n}\n\n.contractDropdown {\n  min-width: 240px !important;\n  padding-top: 7px !important;\n  padding-left: 32px !important;\n  margin: 8px 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "mw_styles_root__F4Z2C",
	"titleBlock": "mw_styles_titleBlock__ggDIW",
	"subtitleBlock": "mw_styles_subtitleBlock__rqyeg",
	"contractDropdown": "mw_styles_contractDropdown__NCrbG"
};
export default ___CSS_LOADER_EXPORT___;
