import { UsersSummaryModel } from "types/usageStatisticApi.types";
import { halfPieChartLegendLabelFormatter } from "../FirewallEncryptionStatusChart/utils";

export const composeMFAStatusChartOptions = (usersSummaryData: UsersSummaryModel) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "pie",
      height: 280,
    },
    colors: ["#4ef09d", "#ff5a5a"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    accessibility: {
      point: {
        valueSuffix: "users",
      },
    },
    tooltip: {
      pointFormat: "Users: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        innerSize: "60%",
        startAngle: -90,
        endAngle: 90,
        data: [
          ["On", usersSummaryData.mfaRegistered],
          ["Off", usersSummaryData.total - usersSummaryData.mfaRegistered],
        ],
        center: ["50%", "90%"],
        size: "120%",
      },
    ],
    legend: {
      enabled: true,
      alignColumns: true,
      useHTML: true,
      labelFormatter: halfPieChartLegendLabelFormatter,
    },
  };
};
