import { DevicesProtectionModel } from "types/usageStatisticApi.types";

export const categoriesConfig = {
  clean: { name: "Clean", color: "#4ef09d", order: 0 },
  criticalFailures: { name: "Critical failures", color: "#ff5a5a", order: 1 },
  pendingSignatureUpdate: { name: "Pending update", color: "#44d9fc", order: 2 },
  pendingQuickScan: { name: "Pending quick scan", color: "#c48df7", order: 3 },
  pendingOfflineScan: { name: "Pending offline scan", color: "#c48df7", order: 4 },
  pendingFullScan: { name: "Pending full scan", color: "#c48df7", order: 5 },
  pendingRestart: { name: "Pending restart", color: "#44d9fc", order: 6 },
  pendingManualSteps: { name: "Pending manual steps", color: "#44d9fc", order: 7 },
  inactiveThreatAgent: { name: "Inactive agent", color: "#e59a7f", order: 8 },
  unknownStateThreatAgent: { name: "Unknown ", color: "#e59a7f", order: 9 },
};

export const composeChartOptions = (devicesProtectionTypes: Omit<DevicesProtectionModel, "total">, total: number) => {
  const sortedTypesArr: [string, number][] = [];
  Object.entries(devicesProtectionTypes).map(
    (type) => (sortedTypesArr[categoriesConfig[type[0] as keyof Omit<DevicesProtectionModel, "total">].order] = type)
  );

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "bar",
      height: 350,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const chartData = this as any;
        return chartData.points.reverse().map((point: any, index: number) =>
          index === 0
            ? `<span style="font-family: Dustin Sans;">
                <span style="font-size: 10px;">${point.x}</span><br>
                <span style="color:${point.color}">${point.series.name}</span>
                <span>: <b>${point.y}</b> (${point.percentage.toFixed(1)}%)</span>
              </span><br>`
            : `<span style="font-family: Dustin Sans;">
                <span style="color:#333">${point.series.name}</span>
                <span>: <b>${point.y}</b> (${point.percentage.toFixed(1)}%)</span>
              </span><br>`
        );
      },
    },
    accessibility: {
      point: {
        valueSuffix: "devices",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        groupPadding: 0,
      },
    },
    xAxis: {
      categories: sortedTypesArr.map(
        (device) => categoriesConfig[device[0] as keyof Omit<DevicesProtectionModel, "total">].name
      ),
      labels: {
        align: "left",
        x: 4,
        y: 4,
        style: {
          fontSize: "12px",
          color: "#333",
          whiteSpace: "nowrap",
        },
      },
    },
    yAxis: {
      title: {
        text: "Devices",
      },
      max: total,
    },
    series: [
      {
        name: "Unrelated",
        data: sortedTypesArr.map((device) => ({ y: total - device[1], color: "#f0f0f0" })),
      },
      {
        name: "Related",
        data: sortedTypesArr.map((device) => ({
          y: device[1],
          color: categoriesConfig[device[0] as keyof Omit<DevicesProtectionModel, "total">].color,
        })),
      },
    ],
    legend: {
      enabled: false,
    },
  };
};
