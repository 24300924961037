import {
  UsersSummaryModel,
  DevicesSummaryModel,
  AppsSummaryModel,
  DevicesOperatingSystemsSummaryResponse,
  DevicesComplianceHistoryResponse,
  UserTypeModel,
  UsersServicesModel,
  UsersAppsModel,
  LicensesSummaryModel,
  DevicesProtectionModel,
  DevicesEncryptionModel,
  DevicesFirewallModel,
} from "types/usageStatisticApi.types";
import { RequestStatus } from "types/common.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface WindowsUpdateSummaryModel {
  pending: number;
  success: number;
  error: number;
  failed: number;
  conflict: number;
}

export interface DevicesMalwareSummaryModel {
  totalMalwareDevices: number;
  severe: number;
  high: number;
  moderate: number;
  low: number;
  unknown: number;
}

export interface UsageStatisticStateSlice {
  readonly usersSummary: UsersSummaryModel & WithRequestStatus;
  readonly devicesSummary: DevicesSummaryModel & WithRequestStatus;
  readonly licensesSummary: LicensesSummaryModel & WithRequestStatus;
  readonly appsSummary: AppsSummaryModel & WithRequestStatus;
  readonly windowsUpdateSummary: WindowsUpdateSummaryModel & WithRequestStatus;
  readonly devicesProtectionSummary: DevicesProtectionModel & WithRequestStatus;
  readonly devicesMalwareSummary: DevicesMalwareSummaryModel & WithRequestStatus;
  readonly devicesFirewallSummary: DevicesFirewallModel & WithRequestStatus;
  readonly devicesEncryptionSummary: DevicesEncryptionModel & WithRequestStatus;
  readonly devicesOperatingSystemsSummary: DevicesOperatingSystemsSummaryResponse & WithRequestStatus;
  readonly devicesComplianceHistory: DevicesComplianceHistoryResponse & WithRequestStatus;
  readonly usersTypesSummary: UserTypeModel & WithRequestStatus;
  readonly usersServicesSummary: UsersServicesModel & WithRequestStatus;
  readonly usersAppsSummary: UsersAppsModel & WithRequestStatus;
}

export const USERS_SUMMARY_REQUEST = "USERS_SUMMARY_REQUEST";
export type UserSummaryRequestAction = {
  type: typeof USERS_SUMMARY_REQUEST;
};
export type UsersSummaryRequestAC = () => UserSummaryRequestAction;

export const USERS_SUMMARY_REQUEST_SUCCESS = "USERS_SUMMARY_REQUEST_SUCCESS";
export type UserSummaryRequestSuccessAction = {
  type: typeof USERS_SUMMARY_REQUEST_SUCCESS;
  payload: UsersSummaryModel;
};
export type UsersSummaryRequestSuccessAC = (payload: UsersSummaryModel) => UserSummaryRequestSuccessAction;

export const USERS_SUMMARY_REQUEST_FAILURE = "USERS_SUMMARY_REQUEST_FAILURE";
export type UsersSummaryRequestFailureAction = {
  type: typeof USERS_SUMMARY_REQUEST_FAILURE;
};
export type UsersSummaryRequestFailureAC = () => UsersSummaryRequestFailureAction;

export const DEVICES_SUMMARY_REQUEST = "DEVICES_SUMMARY_REQUEST";
export type DevicesSummaryRequestAction = {
  type: typeof DEVICES_SUMMARY_REQUEST;
};
export type DevicesSummaryRequestAC = () => DevicesSummaryRequestAction;

export const DEVICES_SUMMARY_REQUEST_SUCCESS = "DEVICES_SUMMARY_REQUEST_SUCCESS";
export type DevicesSummaryRequestSuccessAction = {
  type: typeof DEVICES_SUMMARY_REQUEST_SUCCESS;
  payload: DevicesSummaryModel;
};
export type DevicesSummaryRequestSuccessAC = (payload: DevicesSummaryModel) => DevicesSummaryRequestSuccessAction;

export const DEVICES_SUMMARY_REQUEST_FAILURE = "DEVICES_SUMMARY_REQUEST_FAILURE";
export type DevicesSummaryRequestFailureAction = {
  type: typeof DEVICES_SUMMARY_REQUEST_FAILURE;
};
export type DevicesSummaryRequestFailureAC = () => DevicesSummaryRequestFailureAction;

export const LICENSES_SUMMARY_REQUEST = "LICENSES_SUMMARY_REQUEST";
export type LicensesSummaryRequestAction = {
  type: typeof LICENSES_SUMMARY_REQUEST;
};
export type LicensesSummaryRequestAC = () => LicensesSummaryRequestAction;

export const LICENSES_SUMMARY_REQUEST_SUCCESS = "LICENSES_SUMMARY_REQUEST_SUCCESS";
export type LicensesSummaryRequestSuccessAction = {
  type: typeof LICENSES_SUMMARY_REQUEST_SUCCESS;
  payload: LicensesSummaryModel;
};
export type LicensesSummaryRequestSuccessAC = (payload: LicensesSummaryModel) => LicensesSummaryRequestSuccessAction;

export const LICENSES_SUMMARY_REQUEST_FAILURE = "LICENSES_SUMMARY_REQUEST_FAILURE";
export type LicensesSummaryRequestFailureAction = {
  type: typeof LICENSES_SUMMARY_REQUEST_FAILURE;
};
export type LicensesSummaryRequestFailureAC = () => LicensesSummaryRequestFailureAction;

export const APPS_SUMMARY_REQUEST = "APPS_SUMMARY_REQUEST";
export type AppsSummaryRequestAction = {
  type: typeof APPS_SUMMARY_REQUEST;
};
export type AppsSummaryRequestAC = () => AppsSummaryRequestAction;

export const APPS_SUMMARY_REQUEST_SUCCESS = "APPS_SUMMARY_REQUEST_SUCCESS";
export type AppsSummaryRequestSuccessAction = {
  type: typeof APPS_SUMMARY_REQUEST_SUCCESS;
  payload: AppsSummaryModel;
};
export type AppsSummaryRequestSuccessAC = (payload: AppsSummaryModel) => AppsSummaryRequestSuccessAction;

export const APPS_SUMMARY_REQUEST_FAILURE = "APPS_SUMMARY_REQUEST_FAILURE";
export type AppsSummaryRequestFailureAction = {
  type: typeof APPS_SUMMARY_REQUEST_FAILURE;
};
export type AppsSummaryRequestFailureAC = () => AppsSummaryRequestFailureAction;

export const WINDOWS_UPDATE_SUMMARY_REQUEST = "WINDOWS_UPDATE_SUMMARY_REQUEST";
export type WindowsUpdateSummaryRequestAction = {
  type: typeof WINDOWS_UPDATE_SUMMARY_REQUEST;
};
export type WindowsUpdateSummaryRequestAC = () => WindowsUpdateSummaryRequestAction;

export const WINDOWS_UPDATE_SUMMARY_REQUEST_SUCCESS = "WINDOWS_UPDATE_SUMMARY_REQUEST_SUCCESS";
export type WindowsUpdateSummaryRequestSuccessAction = {
  type: typeof WINDOWS_UPDATE_SUMMARY_REQUEST_SUCCESS;
  payload: WindowsUpdateSummaryModel;
};
export type WindowsUpdateSummaryRequestSuccessAC = (
  payload: WindowsUpdateSummaryModel
) => WindowsUpdateSummaryRequestSuccessAction;

export const WINDOWS_UPDATE_SUMMARY_REQUEST_FAILURE = "WINDOWS_UPDATE_SUMMARY_REQUEST_FAILURE";
export type WindowsUpdateSummaryRequestFailureAction = {
  type: typeof WINDOWS_UPDATE_SUMMARY_REQUEST_FAILURE;
};
export type WindowsUpdateSummaryRequestFailureAC = () => WindowsUpdateSummaryRequestFailureAction;

export const DEVICES_PROTECTION_SUMMARY_REQUEST = "DEVICES_PROTECTION_SUMMARY_REQUEST";
export type DevicesProtectionSummaryRequestAction = {
  type: typeof DEVICES_PROTECTION_SUMMARY_REQUEST;
};
export type DevicesProtectionSummaryRequestAC = () => DevicesProtectionSummaryRequestAction;

export const DEVICES_PROTECTION_SUMMARY_REQUEST_SUCCESS = "DEVICES_PROTECTION_SUMMARY_REQUEST_SUCCESS";
export type DevicesProtectionSummaryRequestSuccessAction = {
  type: typeof DEVICES_PROTECTION_SUMMARY_REQUEST_SUCCESS;
  payload: DevicesProtectionModel;
};
export type DevicesProtectionSummaryRequestSuccessAC = (
  payload: DevicesProtectionModel
) => DevicesProtectionSummaryRequestSuccessAction;

export const DEVICES_PROTECTION_SUMMARY_REQUEST_FAILURE = "DEVICES_PROTECTION_SUMMARY_REQUEST_FAILURE";
export type DevicesProtectionSummaryRequestFailureAction = {
  type: typeof DEVICES_PROTECTION_SUMMARY_REQUEST_FAILURE;
};
export type DevicesProtectionSummaryRequestFailureAC = () => DevicesProtectionSummaryRequestFailureAction;

export const DEVICES_MALWARE_SUMMARY_REQUEST = "DEVICES_MALWARE_SUMMARY_REQUEST";
export type DevicesMalwareSummaryRequestAction = {
  type: typeof DEVICES_MALWARE_SUMMARY_REQUEST;
};
export type DevicesMalwareSummaryRequestAC = () => DevicesMalwareSummaryRequestAction;

export const DEVICES_MALWARE_SUMMARY_REQUEST_SUCCESS = "DEVICES_MALWARE_SUMMARY_REQUEST_SUCCESS";
export type DevicesMalwareSummaryRequestSuccessAction = {
  type: typeof DEVICES_MALWARE_SUMMARY_REQUEST_SUCCESS;
  payload: DevicesMalwareSummaryModel;
};
export type DevicesMalwareSummaryRequestSuccessAC = (
  payload: DevicesMalwareSummaryModel
) => DevicesMalwareSummaryRequestSuccessAction;

export const DEVICES_MALWARE_SUMMARY_REQUEST_FAILURE = "DEVICES_MALWARE_SUMMARY_REQUEST_FAILURE";
export type DevicesMalwareSummaryRequestFailureAction = {
  type: typeof DEVICES_MALWARE_SUMMARY_REQUEST_FAILURE;
};
export type DevicesMalwareSummaryRequestFailureAC = () => DevicesMalwareSummaryRequestFailureAction;

export const DEVICES_FIREWALL_SUMMARY_REQUEST = "DEVICES_FIREWALL_SUMMARY_REQUEST";
export type DevicesFirewallSummaryRequestAction = {
  type: typeof DEVICES_FIREWALL_SUMMARY_REQUEST;
};
export type DevicesFirewallSummaryRequestAC = () => DevicesFirewallSummaryRequestAction;

export const DEVICES_FIREWALL_SUMMARY_REQUEST_SUCCESS = "DEVICES_FIREWALL_SUMMARY_REQUEST_SUCCESS";
export type DevicesFirewallSummaryRequestSuccessAction = {
  type: typeof DEVICES_FIREWALL_SUMMARY_REQUEST_SUCCESS;
  payload: DevicesFirewallModel;
};
export type DevicesFirewallSummaryRequestSuccessAC = (
  payload: DevicesFirewallModel
) => DevicesFirewallSummaryRequestSuccessAction;

export const DEVICES_FIREWALL_SUMMARY_REQUEST_FAILURE = "DEVICES_FIREWALL_SUMMARY_REQUEST_FAILURE";
export type DevicesFirewallSummaryRequestFailureAction = {
  type: typeof DEVICES_FIREWALL_SUMMARY_REQUEST_FAILURE;
};
export type DevicesFirewallSummaryRequestFailureAC = () => DevicesFirewallSummaryRequestFailureAction;

export const DEVICES_ENCRYPTION_SUMMARY_REQUEST = "DEVICES_ENCRYPTION_SUMMARY_REQUEST";
export type DevicesEncryptionSummaryRequestAction = {
  type: typeof DEVICES_ENCRYPTION_SUMMARY_REQUEST;
};
export type DevicesEncryptionSummaryRequestAC = () => DevicesEncryptionSummaryRequestAction;

export const DEVICES_ENCRYPTION_SUMMARY_REQUEST_SUCCESS = "DEVICES_ENCRYPTION_SUMMARY_REQUEST_SUCCESS";
export type DevicesEncryptionSummaryRequestSuccessAction = {
  type: typeof DEVICES_ENCRYPTION_SUMMARY_REQUEST_SUCCESS;
  payload: DevicesEncryptionModel;
};
export type DevicesEncryptionSummaryRequestSuccessAC = (
  payload: DevicesEncryptionModel
) => DevicesEncryptionSummaryRequestSuccessAction;

export const DEVICES_ENCRYPTION_SUMMARY_REQUEST_FAILURE = "DEVICES_ENCRYPTION_SUMMARY_REQUEST_FAILURE";
export type DevicesEncryptionSummaryRequestFailureAction = {
  type: typeof DEVICES_ENCRYPTION_SUMMARY_REQUEST_FAILURE;
};
export type DevicesEncryptionSummaryRequestFailureAC = () => DevicesEncryptionSummaryRequestFailureAction;

export const DEVICES_OPERATING_SYSTEMS_REQUEST = "DEVICES_OPERATING_SYSTEMS_REQUEST";
export type DevicesOperatingSystemsRequestAction = {
  type: typeof DEVICES_OPERATING_SYSTEMS_REQUEST;
};
export type DevicesOperatingSystemsRequestAC = () => DevicesOperatingSystemsRequestAction;

export const DEVICES_OPERATING_SYSTEMS_REQUEST_SUCCESS = "DEVICES_OPERATING_SYSTEMS_REQUEST_SUCCESS";
export type DevicesOperatingSystemsRequestSuccessAction = {
  type: typeof DEVICES_OPERATING_SYSTEMS_REQUEST_SUCCESS;
  payload: DevicesOperatingSystemsSummaryResponse;
};
export type DevicesOperatingSystemsRequestSuccessAC = (
  payload: DevicesOperatingSystemsSummaryResponse
) => DevicesOperatingSystemsRequestSuccessAction;

export const DEVICES_OPERATING_SYSTEMS_REQUEST_FAILURE = "DEVICES_OPERATING_SYSTEMS_REQUEST_FAILURE";
export type DevicesOperatingSystemsRequestFailureAction = {
  type: typeof DEVICES_OPERATING_SYSTEMS_REQUEST_FAILURE;
};
export type DevicesOperatingSystemsRequestFailureAC = () => DevicesOperatingSystemsRequestFailureAction;

export const DEVICES_COMPLIANCE_HISTORY_REQUEST = "DEVICES_COMPLIANCE_HISTORY_REQUEST";
export type DevicesComplianceHistoryRequestAction = {
  type: typeof DEVICES_COMPLIANCE_HISTORY_REQUEST;
};
export type DevicesComplianceHistoryRequestAC = () => DevicesComplianceHistoryRequestAction;

export const DEVICES_COMPLIANCE_HISTORY_REQUEST_SUCCESS = "DEVICES_COMPLIANCE_HISTORY_REQUEST_SUCCESS";
export type DevicesComplianceHistoryRequestSuccessAction = {
  type: typeof DEVICES_COMPLIANCE_HISTORY_REQUEST_SUCCESS;
  payload: DevicesComplianceHistoryResponse;
};
export type DevicesComplianceHistoryRequestSuccessAC = (
  payload: DevicesComplianceHistoryResponse
) => DevicesComplianceHistoryRequestSuccessAction;

export const DEVICES_COMPLIANCE_HISTORY_REQUEST_FAILURE = "DEVICES_COMPLIANCE_HISTORY_REQUEST_FAILURE";
export type DevicesComplianceHistoryRequestFailureAction = {
  type: typeof DEVICES_COMPLIANCE_HISTORY_REQUEST_FAILURE;
};
export type DevicesComplianceHistoryRequestFailureAC = () => DevicesComplianceHistoryRequestFailureAction;

export const USERS_TYPES_SUMMARY_REQUEST = "USERS_TYPES_SUMMARY_REQUEST";
export type UsersTypesSummaryRequestAction = {
  type: typeof USERS_TYPES_SUMMARY_REQUEST;
};
export type UsersTypesSummaryRequestAC = () => UsersTypesSummaryRequestAction;

export const USERS_TYPES_SUMMARY_REQUEST_SUCCESS = "USERS_TYPES_SUMMARY_REQUEST_SUCCESS";
export type UsersTypesSummaryRequestSuccessAction = {
  type: typeof USERS_TYPES_SUMMARY_REQUEST_SUCCESS;
  payload: UserTypeModel;
};
export type UsersTypesSummaryRequestSuccessAC = (payload: UserTypeModel) => UsersTypesSummaryRequestSuccessAction;

export const USERS_TYPES_SUMMARY_REQUEST_FAILURE = "USERS_TYPES_SUMMARY_REQUEST_FAILURE";
export type UsersTypesSummaryRequestFailureAction = {
  type: typeof USERS_TYPES_SUMMARY_REQUEST_FAILURE;
};
export type UsersTypesSummaryRequestFailureAC = () => UsersTypesSummaryRequestFailureAction;

export const USERS_SERVICES_SUMMARY_REQUEST = "USERS_SERVICES_SUMMARY_REQUEST";
export type UsersServicesSummaryRequestAction = {
  type: typeof USERS_SERVICES_SUMMARY_REQUEST;
};
export type UsersServicesSummaryRequestAC = () => UsersServicesSummaryRequestAction;

export const USERS_SERVICES_SUMMARY_REQUEST_SUCCESS = "USERS_SERVICES_SUMMARY_REQUEST_SUCCESS";
export type UsersServicesSummaryRequestSuccessAction = {
  type: typeof USERS_SERVICES_SUMMARY_REQUEST_SUCCESS;
  payload: UsersServicesModel;
};
export type UsersServicesSummaryRequestSuccessAC = (
  payload: UsersServicesModel
) => UsersServicesSummaryRequestSuccessAction;

export const USERS_SERVICES_SUMMARY_REQUEST_FAILURE = "USERS_SERVICES_SUMMARY_REQUEST_FAILURE";
export type UsersServicesSummaryRequestFailureAction = {
  type: typeof USERS_SERVICES_SUMMARY_REQUEST_FAILURE;
};
export type UsersServicesSummaryRequestFailureAC = () => UsersServicesSummaryRequestFailureAction;

export const USERS_APPS_SUMMARY_REQUEST = "USERS_APPS_SUMMARY_REQUEST";
export type UsersAppsSummaryRequestAction = {
  type: typeof USERS_APPS_SUMMARY_REQUEST;
};
export type UsersAppsSummaryRequestAC = () => UsersAppsSummaryRequestAction;

export const USERS_APPS_SUMMARY_REQUEST_SUCCESS = "USERS_APPS_SUMMARY_REQUEST_SUCCESS";
export type UsersAppsSummaryRequestSuccessAction = {
  type: typeof USERS_APPS_SUMMARY_REQUEST_SUCCESS;
  payload: UsersAppsModel;
};
export type UsersAppsSummaryRequestSuccessAC = (payload: UsersAppsModel) => UsersAppsSummaryRequestSuccessAction;

export const USERS_APPS_SUMMARY_REQUEST_FAILURE = "USERS_APPS_SUMMARY_REQUEST_FAILURE";
export type UsersAppsSummaryRequestFailureAction = {
  type: typeof USERS_APPS_SUMMARY_REQUEST_FAILURE;
};
export type UsersAppsSummaryRequestFailureAC = () => UsersAppsSummaryRequestFailureAction;

export type UsageStatisticAction =
  | UserSummaryRequestAction
  | UserSummaryRequestSuccessAction
  | UsersSummaryRequestFailureAction
  | DevicesSummaryRequestAction
  | DevicesSummaryRequestSuccessAction
  | DevicesSummaryRequestFailureAction
  | LicensesSummaryRequestAction
  | LicensesSummaryRequestSuccessAction
  | LicensesSummaryRequestFailureAction
  | AppsSummaryRequestAction
  | AppsSummaryRequestSuccessAction
  | AppsSummaryRequestFailureAction
  | WindowsUpdateSummaryRequestAction
  | WindowsUpdateSummaryRequestSuccessAction
  | WindowsUpdateSummaryRequestFailureAction
  | DevicesProtectionSummaryRequestAction
  | DevicesProtectionSummaryRequestSuccessAction
  | DevicesProtectionSummaryRequestFailureAction
  | DevicesMalwareSummaryRequestAction
  | DevicesMalwareSummaryRequestSuccessAction
  | DevicesMalwareSummaryRequestFailureAction
  | DevicesFirewallSummaryRequestAction
  | DevicesFirewallSummaryRequestSuccessAction
  | DevicesFirewallSummaryRequestFailureAction
  | DevicesEncryptionSummaryRequestAction
  | DevicesEncryptionSummaryRequestSuccessAction
  | DevicesEncryptionSummaryRequestFailureAction
  | DevicesOperatingSystemsRequestAction
  | DevicesOperatingSystemsRequestSuccessAction
  | DevicesOperatingSystemsRequestFailureAction
  | DevicesComplianceHistoryRequestAction
  | DevicesComplianceHistoryRequestSuccessAction
  | DevicesComplianceHistoryRequestFailureAction
  | UsersTypesSummaryRequestAction
  | UsersTypesSummaryRequestSuccessAction
  | UsersTypesSummaryRequestFailureAction
  | UsersServicesSummaryRequestAction
  | UsersServicesSummaryRequestSuccessAction
  | UsersServicesSummaryRequestFailureAction
  | UsersAppsSummaryRequestAction
  | UsersAppsSummaryRequestSuccessAction
  | UsersAppsSummaryRequestFailureAction;
