import { AppSummary } from "types/usageStatisticApi.types";
import {
  areaSplineLegendLabelFormatter,
  ChartCoordinatesByTypeModel,
  ChartCoordinatesType,
} from "components/DevicesBlockCharts/charts/DevicesComplianceHistoryChart/utils";

export type ComposedDailyAppUsageChartDataType = {
  name: string;
  data: ChartCoordinatesType[];
}[];

export const groupChartDataByType = (dailyAppsUsageSummary: AppSummary[]): ChartCoordinatesByTypeModel => {
  const groupedDataByTypes = dailyAppsUsageSummary.reduce((acc, item) => {
    const timestamp = Number(new Date(item.date));
    if (!acc[item.name]) {
      acc[item.name] = [];
    }
    acc[item.name].push({ x: timestamp, y: item.active });
    return acc;
  }, {} as ChartCoordinatesByTypeModel);

  return groupedDataByTypes;
};

export const composeChartData = (dailyAppsUsageSummary: AppSummary[]) => {
  const groupedData = groupChartDataByType(dailyAppsUsageSummary);
  const composedData = Object.entries(groupedData).map((item, index) => {
    return {
      name: item[0],
      data: item[1],
    };
  });
  return composedData;
};

export const composeChartOptions = (composedData: ComposedDailyAppUsageChartDataType) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      height: 350,
    },
    colors: ["#c48df7", "#44d9fc", "#e59a7f", "#b4b4b4", "#2e2256", "#19495e", "#5c3e33", "#4f4f4f"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      split: true,
      pointFormat: "{series.name}: <b>{point.y}</b><br>",
    },
    accessibility: {
      point: {
        valueSuffix: "users",
      },
    },
    yAxis: {
      title: {
        text: "Users",
      },
    },
    xAxis: {
      title: null,
      type: "datetime",
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: composedData,
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: areaSplineLegendLabelFormatter,
    },
  };
};
