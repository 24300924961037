import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useAllUsersColumnConfig = () => {
  const { t } = useTranslation("usersPage");

  return useMemo(
    () =>
      [
        {
          key: "displayName",
          dataIndex: "displayName",
          title: t("allUsersTableColumns.displayName"),
          width: "20%",
        },
        {
          key: "mail",
          dataIndex: "mail",
          title: t("allUsersTableColumns.mail"),
          width: "30%",
        },
        {
          key: "department",
          dataIndex: "department",
          title: t("allUsersTableColumns.department"),
          width: "15%",
        },
        {
          key: "jobTitle",
          dataIndex: "jobTitle",
          title: t("allUsersTableColumns.jobTitle"),
          width: "15%",
        },
        {
          key: "userType",
          dataIndex: "userType",
          title: t("allUsersTableColumns.userType"),
          width: "10%",
        },
        {
          key: "accountEnabled",
          dataIndex: "accountEnabled",
          title: t("allUsersTableColumns.accountEnabled"),
          render: (value: any) => <span>{value ? t("active") : t("inactive")}</span>,
          width: "10%",
        },
      ].filter(Boolean),
    [t]
  );
};

export default useAllUsersColumnConfig;
