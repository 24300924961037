import * as types from "./usageStatistic.types";
import { RequestStatus } from "types/common.types";

export const initState: types.UsageStatisticStateSlice = {
  usersSummary: {
    total: 0,
    mfaRegistered: 0,
    guestUsers: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  devicesSummary: {
    total: 0,
    enrolled: 0,
    compliant: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  licensesSummary: {
    available: 0,
    assigned: 0,
    total: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  appsSummary: {
    managed: 0,
    detected: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  windowsUpdateSummary: {
    pending: 0,
    success: 0,
    error: 0,
    failed: 0,
    conflict: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  devicesProtectionSummary: {
    total: 0,
    inactiveThreatAgent: 0,
    unknownStateThreatAgent: 0,
    pendingSignatureUpdate: 0,
    clean: 0,
    pendingFullScan: 0,
    pendingRestart: 0,
    pendingManualSteps: 0,
    pendingOfflineScan: 0,
    criticalFailures: 0,
    pendingQuickScan: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  devicesMalwareSummary: {
    totalMalwareDevices: 0,
    severe: 0,
    high: 0,
    moderate: 0,
    low: 0,
    unknown: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  devicesFirewallSummary: {
    total: 0,
    firewallOff: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  devicesEncryptionSummary: {
    total: 0,
    notEncrypted: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  devicesOperatingSystemsSummary: {
    operatingSystems: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  devicesComplianceHistory: {
    history: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  usersTypesSummary: {
    member: 0,
    guest: 0,
    requestStatus: RequestStatus.UNCALLED,
  },
  usersServicesSummary: {
    services: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  usersAppsSummary: {
    services: [],
    requestStatus: RequestStatus.UNCALLED,
  },
};

export default function AppReducer(
  state: types.UsageStatisticStateSlice = initState,
  action: types.UsageStatisticAction
): types.UsageStatisticStateSlice {
  switch (action.type) {
    case types.USERS_SUMMARY_REQUEST: {
      return {
        ...state,
        usersSummary: { ...state.usersSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.USERS_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        usersSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.USERS_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        usersSummary: { ...initState.usersSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.DEVICES_SUMMARY_REQUEST: {
      return {
        ...state,
        devicesSummary: { ...state.devicesSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.DEVICES_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        devicesSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DEVICES_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        devicesSummary: { ...initState.devicesSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.LICENSES_SUMMARY_REQUEST: {
      return {
        ...state,
        licensesSummary: {
          ...state.licensesSummary,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.LICENSES_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        licensesSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.LICENSES_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        licensesSummary: {
          ...initState.licensesSummary,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    case types.APPS_SUMMARY_REQUEST: {
      return {
        ...state,
        appsSummary: { ...state.appsSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.APPS_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        appsSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.APPS_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        appsSummary: { ...initState.appsSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.WINDOWS_UPDATE_SUMMARY_REQUEST: {
      return {
        ...state,
        windowsUpdateSummary: { ...state.windowsUpdateSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.WINDOWS_UPDATE_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        windowsUpdateSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.WINDOWS_UPDATE_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        windowsUpdateSummary: { ...initState.windowsUpdateSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.DEVICES_PROTECTION_SUMMARY_REQUEST: {
      return {
        ...state,
        devicesProtectionSummary: { ...state.devicesProtectionSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.DEVICES_PROTECTION_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        devicesProtectionSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DEVICES_PROTECTION_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        devicesProtectionSummary: { ...initState.devicesProtectionSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.DEVICES_MALWARE_SUMMARY_REQUEST: {
      return {
        ...state,
        devicesMalwareSummary: { ...state.devicesMalwareSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.DEVICES_MALWARE_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        devicesMalwareSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DEVICES_MALWARE_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        devicesMalwareSummary: { ...initState.devicesMalwareSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.DEVICES_FIREWALL_SUMMARY_REQUEST: {
      return {
        ...state,
        devicesFirewallSummary: { ...state.devicesFirewallSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.DEVICES_FIREWALL_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        devicesFirewallSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DEVICES_FIREWALL_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        devicesFirewallSummary: { ...initState.devicesFirewallSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.DEVICES_ENCRYPTION_SUMMARY_REQUEST: {
      return {
        ...state,
        devicesEncryptionSummary: { ...state.devicesEncryptionSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.DEVICES_ENCRYPTION_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        devicesEncryptionSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DEVICES_ENCRYPTION_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        devicesEncryptionSummary: { ...initState.devicesEncryptionSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.DEVICES_OPERATING_SYSTEMS_REQUEST: {
      return {
        ...state,
        devicesOperatingSystemsSummary: {
          ...state.devicesOperatingSystemsSummary,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.DEVICES_OPERATING_SYSTEMS_REQUEST_SUCCESS: {
      return {
        ...state,
        devicesOperatingSystemsSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DEVICES_OPERATING_SYSTEMS_REQUEST_FAILURE: {
      return {
        ...state,
        devicesOperatingSystemsSummary: {
          ...initState.devicesOperatingSystemsSummary,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    case types.DEVICES_COMPLIANCE_HISTORY_REQUEST: {
      return {
        ...state,
        devicesComplianceHistory: {
          ...state.devicesComplianceHistory,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.DEVICES_COMPLIANCE_HISTORY_REQUEST_SUCCESS: {
      return {
        ...state,
        devicesComplianceHistory: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.DEVICES_COMPLIANCE_HISTORY_REQUEST_FAILURE: {
      return {
        ...state,
        devicesComplianceHistory: {
          ...initState.devicesComplianceHistory,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    case types.USERS_TYPES_SUMMARY_REQUEST: {
      return {
        ...state,
        usersTypesSummary: {
          ...state.usersTypesSummary,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.USERS_TYPES_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        usersTypesSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.USERS_TYPES_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        usersTypesSummary: {
          ...initState.usersTypesSummary,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    case types.USERS_SERVICES_SUMMARY_REQUEST: {
      return {
        ...state,
        usersServicesSummary: {
          ...state.usersServicesSummary,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.USERS_SERVICES_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        usersServicesSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.USERS_SERVICES_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        usersServicesSummary: {
          ...initState.usersServicesSummary,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    case types.USERS_APPS_SUMMARY_REQUEST: {
      return {
        ...state,
        usersAppsSummary: {
          ...state.usersAppsSummary,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.USERS_APPS_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        usersAppsSummary: { ...action.payload, requestStatus: RequestStatus.SUCCESS },
      };
    }
    case types.USERS_APPS_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        usersAppsSummary: {
          ...initState.usersAppsSummary,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    default:
      return state;
  }
}
