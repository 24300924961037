import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import UsageStatisticCards from "components/UsageStatisticCards";
import DevicesBlockCharts from "components/DevicesBlockCharts";
import SecurityBlockCharts from "components/SecurityBlockCharts";
import UsersBlockCharts from "components/UsersBlockCharts";

const HomePage: React.FC = () => {
  const { t } = useTranslation("mwPage");

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <UsageStatisticCards />
      <DevicesBlockCharts />
      <SecurityBlockCharts />
      <UsersBlockCharts />
    </PageLayout>
  );
};

export default HomePage;
