import { SelectedContractId } from "store/contracts/contracts.types";

export const generateCsvExportUrl = (selectedContractId: SelectedContractId, path: string) => {
  return [(window as any).MW_API_URL, `/v1/contracts/${selectedContractId}/${path}?accept=csv`].join("");
};

export const parseApiErrorMessage = (error: any): string | null => {
  if (!error || !error.isAxiosError) {
    return null;
  }

  try {
    const { msg: message } = error.response?.data;
    return message || null;
  } catch (e) {
    return null;
  }
};

export const sumObjectProperties = (obj: { [key: string]: number }, propertiesToSum: string[]): number => {
  let sum = 0;

  for (const propertyName of propertiesToSum) {
    if (obj.hasOwnProperty(propertyName)) {
      sum += obj[propertyName];
    }
  }

  return sum;
};
