import {
  ManagedDeviceModel,
  MicrosoftDefenderDeviceModel,
  UnmanagedDeviceModel,
  WindowsUpdateDeviceModel,
} from "types/devicesApi.types";
import { makeUniversalSort } from "./sort";

export const deviceNameNormalizer = (item: ManagedDeviceModel) => item.deviceName;
export const ownerTypeNormalizer = (item: ManagedDeviceModel) => item.ownerType;
export const complianceStateNormalizer = (item: ManagedDeviceModel) => item.complianceState;
export const manufacturerNormalizer = (item: ManagedDeviceModel) => item.manufacturer;
export const modelNormalizer = (item: ManagedDeviceModel) => item.model;
export const operatingSystemNormalizer = (item: ManagedDeviceModel) => item.operatingSystem;
export const operatingSystemVersionNormalizer = (item: ManagedDeviceModel) => item.operatingSystemVersion;
export const emailAddressNormalizer = (item: ManagedDeviceModel) => item.emailAddress;
export const serialNumberNormalizer = (item: ManagedDeviceModel) => item.serialNumber;
export const freeStorageSpaceInBytesNormalizer = (item: ManagedDeviceModel) => item.freeStorageSpaceInBytes;

export const managedDevicesSortHandler: any = {
  deviceName: makeUniversalSort(deviceNameNormalizer),
  ownerType: makeUniversalSort(ownerTypeNormalizer),
  complianceState: makeUniversalSort(complianceStateNormalizer),
  manufacturer: makeUniversalSort(manufacturerNormalizer),
  model: makeUniversalSort(modelNormalizer),
  operatingSystem: makeUniversalSort(operatingSystemNormalizer),
  operatingSystemVersion: makeUniversalSort(operatingSystemVersionNormalizer),
  emailAddress: makeUniversalSort(emailAddressNormalizer),
  serialNumber: makeUniversalSort(serialNumberNormalizer),
  freeStorageSpaceInBytes: makeUniversalSort(freeStorageSpaceInBytesNormalizer),
};

export const isCompliantNormalizer = (item: UnmanagedDeviceModel) => item.isCompliant || "";

export const unmanagedDevicesSortHandler: any = {
  deviceName: makeUniversalSort(deviceNameNormalizer),
  ownerType: makeUniversalSort(ownerTypeNormalizer),
  isCompliant: makeUniversalSort(isCompliantNormalizer),
  manufacturer: makeUniversalSort(manufacturerNormalizer),
  model: makeUniversalSort(modelNormalizer),
  operatingSystem: makeUniversalSort(operatingSystemNormalizer),
  operatingSystemVersion: makeUniversalSort(operatingSystemVersionNormalizer),
};

export const deviceDisplayNameNormalizer = (item: WindowsUpdateDeviceModel) => item.deviceDisplayName;
export const userDisplayNameNormalizer = (item: WindowsUpdateDeviceModel) => item.userPrincipalName;
export const statusNormalizer = (item: WindowsUpdateDeviceModel) => item.status;
export const qualityUpdateVersionNormalizer = (item: WindowsUpdateDeviceModel) => item.qualityUpdateVersion;
export const featureUpdateVersionNormalizer = (item: WindowsUpdateDeviceModel) => item.featureUpdateVersion;
export const lastScanDateTimeNormalizer = (item: WindowsUpdateDeviceModel) =>
  item.lastScanDateTime ? +new Date(item.lastScanDateTime) : "";
export const lastSyncDateTimeNormalizer = (item: WindowsUpdateDeviceModel) =>
  item.lastSyncDateTime ? +new Date(item.lastSyncDateTime) : "";

export const windowsUpdateDevicesSortHandler: any = {
  deviceDisplayName: makeUniversalSort(deviceDisplayNameNormalizer),
  userPrincipalName: makeUniversalSort(userDisplayNameNormalizer),
  status: makeUniversalSort(statusNormalizer),
  qualityUpdateVersion: makeUniversalSort(qualityUpdateVersionNormalizer),
  featureUpdateVersion: makeUniversalSort(featureUpdateVersionNormalizer),
  lastScanDateTime: makeUniversalSort(lastScanDateTimeNormalizer),
  lastSyncDateTime: makeUniversalSort(lastSyncDateTimeNormalizer),
};

export const userNameNormalizer = (item: MicrosoftDefenderDeviceModel) => item.userName;
export const antiMalwareVersionNormalizer = (item: MicrosoftDefenderDeviceModel) => item.antiMalwareVersion;
export const deviceStateNormalizer = (item: MicrosoftDefenderDeviceModel) => item.deviceState;
export const criticalFailureNormalizer = (item: MicrosoftDefenderDeviceModel) => item.criticalFailure;
export const encryptionNormalizer = (item: MicrosoftDefenderDeviceModel) => item.encryption;
export const firewallNormalizer = (item: MicrosoftDefenderDeviceModel) => item.firewall;
export const lastQuickScanDateTimeNormalizer = (item: MicrosoftDefenderDeviceModel) =>
  item.lastQuickScanDateTime ? +new Date(item.lastQuickScanDateTime) : "";
export const lastReportedDateTimeNormalizer = (item: MicrosoftDefenderDeviceModel) =>
  item.lastReportedDateTime ? +new Date(item.lastReportedDateTime) : "";

export const microsoftDefenderDevicesSortHandler: any = {
  deviceName: makeUniversalSort(deviceNameNormalizer),
  userName: makeUniversalSort(userNameNormalizer),
  antiMalwareVersion: makeUniversalSort(antiMalwareVersionNormalizer),
  deviceState: makeUniversalSort(deviceStateNormalizer),
  criticalFailure: makeUniversalSort(criticalFailureNormalizer),
  encryption: makeUniversalSort(encryptionNormalizer),
  firewall: makeUniversalSort(firewallNormalizer),
  lastQuickScanDateTime: makeUniversalSort(lastQuickScanDateTimeNormalizer),
  lastReportedDateTime: makeUniversalSort(lastReportedDateTimeNormalizer),
};

export type ManagedDevicesFilterKeyListType = "manufacturer" | "complianceState";
export const MANAGED_DEVICES_FILTER_KEY_LIST: ManagedDevicesFilterKeyListType[] = ["manufacturer", "complianceState"];
export type UnmanagedDevicesFilterKeyListType = "manufacturer";
export const UNMANAGED_DEVICES_FILTER_KEY_LIST: UnmanagedDevicesFilterKeyListType[] = ["manufacturer"];
export type WindowsUpdateDevicesFilterKeyListType = "status";
export const WINDOWS_UPDATE_DEVICES_FILTER_KEY_LIST: WindowsUpdateDevicesFilterKeyListType[] = ["status"];
export type MicrosoftDefenderDevicesFilterKeyListType = "deviceState";
export const MICROSOFT_DEFENDER_DEVICES_FILTER_KEY_LIST: MicrosoftDefenderDevicesFilterKeyListType[] = ["deviceState"];

export const getFilterOptions = <Type>(list: Type[], fieldName: keyof Type) => {
  const options = Array.from(new Set(list.flatMap((item) => item[fieldName]))).filter(Boolean);

  return options.map((option) => ({
    label: String(option),
    value: String(option),
  }));
};

export const PAGINATION_STEPS = [10, 20, 50, 100];

export const getPaginationSlice = (list: any[], page: number, perPage: number) =>
  list.slice((page - 1) * perPage, page * perPage);
