import { AppThunk } from "store";
import * as mwApi from "api/devices.api";
import * as devicesActions from "./devices.actions";
import { parseApiErrorMessage } from "utils/helpers";
import { notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";

export const getManagedDevicesList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(devicesActions.managedDevicesRequestAC());
      const { data } = await mwApi.getManagedDevices(selectedContractId);
      dispatch(devicesActions.managedDevicesRequestSuccessAC(data.devices));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "MANAGED DEVICES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:managedDevicesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(devicesActions.managedDevicesRequestFailureAC());
    }
  };

export const getUnmanagedDevicesList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(devicesActions.unmanagedDevicesRequestAC());
      const { data } = await mwApi.getUnmanagedDevices(selectedContractId);
      dispatch(devicesActions.unmanagedDevicesRequestSuccessAC(data.devices));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "UNMANAGED DEVICES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:unmanagedDevicesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(devicesActions.unmanagedDevicesRequestFailureAC());
    }
  };

export const getWindowsUpdateConfigsList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(devicesActions.windowsUpdateConfigsRequestAC());
      const { data } = await mwApi.getWindowsUpdateSummary(selectedContractId);
      dispatch(devicesActions.windowsUpdateConfigsRequestSuccessAC(data.windowsUpdateConfigurations));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "WINDOWS UPDATE CONFIGS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:windowsUpdateConfigsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(devicesActions.windowsUpdateConfigsRequestFailureAC());
    }
  };

export const getSelectedWindowsUpdateDevicesList =
  (selectedContractId: string, updateId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(devicesActions.selectedWindowsUpdateDevicesRequestAC(updateId));
      const { data } = await mwApi.getWindowsUpdateDevices(selectedContractId, updateId);
      dispatch(devicesActions.selectedWindowsUpdateDevicesRequestSuccessAC(data.devices));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "SELECTED WINDOWS UPDATE DEVICES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:windowsUpdateDevicesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(devicesActions.selectedWindowsUpdateDevicesRequestFailedAC());
    }
  };

export const getMicrosoftDefenderDevicesList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(devicesActions.microsoftDefenderDevicesRequestAC());
      const { data } = await mwApi.getMicrosoftDefenderDevices(selectedContractId);
      dispatch(devicesActions.microsoftDefenderDevicesRequestSuccessAC(data));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "MICROSOFT DEFENDER DEVICES REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:microsoftDefenderDevicesRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(devicesActions.microsoftDefenderDevicesRequestFailureAC());
    }
  };
