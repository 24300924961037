import { mwApiClient } from "./clients";
import { AllUsersResponse, AuthenticatedUsersResponse } from "types/usersApi.types";

export const getAllUsers = (contractId: string) =>
  mwApiClient.request<AllUsersResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/users/all`,
  });

export const getAuthenticatedUsers = (contractId: string) =>
  mwApiClient.request<AuthenticatedUsersResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/users/authentication`,
  });
