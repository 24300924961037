import { mwApiClient } from "./clients";
import { ManagedAppsResponse, DetectedAppsResponse } from "types/appsApi.types";

export const getManagedApps = (contractId: string) =>
  mwApiClient.request<ManagedAppsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/applications/managed`,
  });

export const getDetectedApps = (contractId: string) =>
  mwApiClient.request<DetectedAppsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/applications/detected`,
  });
