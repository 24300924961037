import { RequestStatus } from "types/common.types";
import { LicenseModel } from "types/licensesApi.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface LicensesStateSlice extends WithRequestStatus {
  readonly list: LicenseModel[];
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly perPage: number;
  readonly currentPage: number;
}

export type SortFieldType = keyof LicenseModel | "";
export type SortOrderType = "asc" | "desc";

export const LICENSES_LIST_REQUEST = "LICENSES_LIST_REQUEST";
export type LicensesListRequestAction = { type: typeof LICENSES_LIST_REQUEST };
export type LicensesListRequestAC = () => LicensesListRequestAction;

export const LICENSES_LIST_REQUEST_SUCCESS = "LICENSES_LIST_REQUEST_SUCCESS";
export type LicensesListRequestSuccessAction = {
  type: typeof LICENSES_LIST_REQUEST_SUCCESS;
  payload: LicenseModel[];
};
export type LicensesListRequestSuccessAC = (payload: LicenseModel[]) => LicensesListRequestSuccessAction;

export const LICENSES_LIST_REQUEST_FAILURE = "LICENSES_LIST_REQUEST_FAILURE";
export type LicensesListRequestFailureAction = { type: typeof LICENSES_LIST_REQUEST_FAILURE };
export type LicensesListRequestFailureAC = () => LicensesListRequestFailureAction;

export const SET_OTHER_LICENSES_SORT_FIELD = "SET_OTHER_LICENSES_SORT_FIELD";
export type SetOtherLicensesSortFieldAction = {
  type: typeof SET_OTHER_LICENSES_SORT_FIELD;
  payload: SortFieldType;
};
export type SetOtherLicensesSortFieldAC = (payload: SortFieldType) => SetOtherLicensesSortFieldAction;

export const SET_LICENSES_PER_PAGE = "SET_LICENSES_PER_PAGE";
export type SetLicensesPerPageAction = {
  type: typeof SET_LICENSES_PER_PAGE;
  payload: number;
};
export type SetLicensesPerPageAC = (payload: number) => SetLicensesPerPageAction;

export const SET_LICENSES_CURRENT_PAGE = "SET_LICENSES_CURRENT_PAGE";
export type SetLicensesCurrentPageAction = {
  type: typeof SET_LICENSES_CURRENT_PAGE;
  payload: number;
};
export type SetLicensesCurrentPageAC = (payload: number) => SetLicensesCurrentPageAction;

export type LicensesActions =
  | LicensesListRequestAction
  | LicensesListRequestSuccessAction
  | LicensesListRequestFailureAction
  | SetOtherLicensesSortFieldAction
  | SetLicensesPerPageAction
  | SetLicensesCurrentPageAction;
