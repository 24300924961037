import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { licensesSelectors, licensesThunks } from "store/licenses";

const useFetchLicenses = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  useEffect(() => {
    if (selectedContractId) {
      thunkDispatch(licensesThunks.getLicensesList(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId]);

  return useSelector(licensesSelectors.getSlice);
};

export default useFetchLicenses;
