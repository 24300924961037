import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import DevicesProtectionChart from "./charts/DevicesProtectionChart";
import DevicesMalwareSummaryChart from "./charts/DevicesMalwareSummaryChart";
import FirewallEncryptionStatusChart from "./charts/FirewallEncryptionStatusChart";

import styles from "./styles.module.css";
import MFAStatusChart from "./charts/MFAStatusChart/MFAStatusChart";

const SecurityBlockCharts = () => {
  const { t } = useTranslation("mwPage");

  return (
    <>
      <h2 className={styles.title}>{t("securitySubtitle")}</h2>
      <div className={styles.chartsContainer}>
        <div className={cn(styles.chartsItem, styles.smallCard)}>
          <MFAStatusChart />
        </div>
        <div className={cn(styles.chartsItem, styles.smallCard)}>
          <DevicesProtectionChart />
        </div>
        <div className={cn(styles.chartsItem, styles.smallCard)}>
          <DevicesMalwareSummaryChart />
        </div>
        <div className={cn(styles.chartsItem, styles.smallCard)}>
          <FirewallEncryptionStatusChart />
        </div>
      </div>
    </>
  );
};

export default SecurityBlockCharts;
