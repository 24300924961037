import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import DevicesComplianceHistoryChart from "./charts/DevicesComplianceHistoryChart";
import DevicesOperatingSystemChart from "./charts/DevicesOperatingSystemChart";
import WindowsUpdateStatusChart from "./charts/WindowsUpdateStatusChart";

import styles from "./styles.module.css";

const DevicesBlockCharts = () => {
  const { t } = useTranslation("mwPage");

  return (
    <>
      <h2 className={styles.title}>{t("devicesSubtitle")}</h2>
      <div className={styles.chartsContainer}>
        <div className={cn(styles.chartsItem, styles.smallCard)}>
          <DevicesOperatingSystemChart />
        </div>
        <div className={cn(styles.chartsItem, styles.mediumCard)}>
          <DevicesComplianceHistoryChart />
        </div>
        <div className={cn(styles.chartsItem, styles.smallCard)}>
          <WindowsUpdateStatusChart />
        </div>
      </div>
    </>
  );
};

export default DevicesBlockCharts;
