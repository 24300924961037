import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { usageStatisticSelectors, usageStatisticThunks } from "store/usageStatistic";

const useFetchUsageSummary = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  useEffect(() => {
    if (selectedContractId) {
      thunkDispatch(usageStatisticThunks.getDevicesSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getUsersSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getLicensesSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getAppsSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getWindowsUpdateSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getDevicesProtectionSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getDevicesMalwareSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getDevicesFirewallSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getDevicesEncryptionSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getDevicesOperatingSystemsInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getDevicesComplianceHistoryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getUsersTypesSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getUsersServicesSummaryInfo(selectedContractId));
      thunkDispatch(usageStatisticThunks.getUsersAppsSummaryInfo(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId]);

  return useSelector(usageStatisticSelectors.getSlice);
};

export default useFetchUsageSummary;
