import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as contracts } from "./contracts";
import { reducer as usageStatistic } from "./usageStatistic";
import { reducer as devices } from "./devices";
import { reducer as users } from "./users";
import { reducer as licenses } from "./licenses";
import { reducer as applications } from "./applications";
import { reducer as notification } from "./notification";

const rootReducer = combineReducers({
  app,
  contracts,
  usageStatistic,
  devices,
  users,
  licenses,
  applications,
  notification,
});

export default rootReducer;
