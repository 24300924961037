import { mwApiClient } from "./clients";
import {
  ManagedDevicesResponse,
  UnmanagedDevicesResponse,
  WindowsUpdateDevicesResponse,
  WindowsUpdateSummaryResponse,
  MicrosoftDefenderDevicesResponse,
} from "types/devicesApi.types";

export const getManagedDevices = (contractId: string) =>
  mwApiClient.request<ManagedDevicesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/managed`,
  });

export const getUnmanagedDevices = (contractId: string) =>
  mwApiClient.request<UnmanagedDevicesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/unmanaged`,
  });

export const getWindowsUpdateSummary = (contractId: string) =>
  mwApiClient.request<WindowsUpdateSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/windows-update/summary`,
  });

export const getWindowsUpdateDevices = (contractId: string, updateId: string) =>
  mwApiClient.request<WindowsUpdateDevicesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/windows-update/${updateId}`,
  });

export const getMicrosoftDefenderDevices = (contractId: string) =>
  mwApiClient.request<MicrosoftDefenderDevicesResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/protection`,
  });
