import Highcharts from "highcharts";
import { ComplianceHistoryType } from "types/usageStatisticApi.types";

export interface ChartCoordinatesType {
  x: number;
  y: number;
}

export type ChartCoordinatesByTypeModel = Record<string, ChartCoordinatesType[]>;
export type ComposedDevicesComplianceHistoryChartDataType = {
  name: string;
  data: ChartCoordinatesType[];
  color: string;
  fillColor: Object;
}[];

export function areaSplineLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.2; font-family: Dustin Sans; margin-bottom: 6px;">
      <div>${(this as any).name}</div>
    </div>`;
}

export const groupChartDataByType = (history: ComplianceHistoryType[]): ChartCoordinatesByTypeModel => {
  const groupedDataByTypes = history.reduce((acc, item) => {
    const timestamp = Number(new Date(item.date));
    if (!acc[item.complianceState]) {
      acc[item.complianceState] = [];
    }

    const existingStatePoint = acc[item.complianceState].find((point) => point.x === timestamp);
    existingStatePoint
      ? (existingStatePoint.y += item.count)
      : acc[item.complianceState].push({ x: timestamp, y: item.count });
    return acc;
  }, {} as ChartCoordinatesByTypeModel);

  return groupedDataByTypes;
};

const CHART_COLORS = ["#44d9fc", "#e59a7f", "#c48df7", "#b4b4b4", "#c5f5fa", "#faebe5", "#f3e8fd", "#f0f0f0"];
const popGreenColor = "#4ef09d";
const popRedColor = "#ff5a5a";

const getColor = (colors: string[], groupName: string) => {
  switch (groupName) {
    case "Compliant":
      return popGreenColor;
    case "Not compliant":
      return popRedColor;
    default:
      return colors.shift() as string;
  }
};

export const composeChartData = (history: ComplianceHistoryType[]) => {
  let colors = CHART_COLORS.map((color) => color);
  const groupedData = groupChartDataByType(history);
  const composedData = Object.entries(groupedData).map((item) => {
    if (!colors.length) colors = CHART_COLORS.map((color) => color);
    const groupColor = getColor(colors, item[0]);
    return {
      name: item[0],
      data: item[1],
      color: groupColor,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, new Highcharts.Color(groupColor).setOpacity(1).get("rgba")],
          [1, new Highcharts.Color(groupColor).setOpacity(0.2).get("rgba")],
        ],
      },
    };
  });
  return composedData;
};

export const composeChartOptions = (composedData: ComposedDevicesComplianceHistoryChartDataType) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "areaspline",
      height: 350,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      split: true,
      pointFormat: "{series.name}: <b>{point.y}</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "pcs",
      },
    },
    yAxis: {
      title: null,
      gridLineWidth: 0,
    },
    xAxis: {
      title: null,
      type: "datetime",
      gridLineWidth: 1,
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: false,
        },
        showLegend: false,
      },
    },
    series: composedData,
    legend: {
      itemDistance: 20,
      alignColumns: true,
      useHTML: true,
      labelFormatter: areaSplineLegendLabelFormatter,
    },
  };
};
