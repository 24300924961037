import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import PaginationBox from "containers/PaginationBox";
import { initState } from "store/devices/devices.reducer";
import { devicesSelectors } from "store/devices";
import useMicrosoftDefenderDevicesColumnConfig from "hooks/useMicrosoftDefenderDevicesColumnConfig";
import {
  setDevicesSortFieldAC,
  setMicrosoftDefenderDevicesFiltersAC,
  setDevicesCurrentPageAC,
  setDevicesPerPageAC,
} from "store/devices/devices.actions";
import { MICROSOFT_DEFENDER_DEVICES_FILTER_KEY_LIST } from "utils/devicesTables";
import AutocompleteTableFilter from "containers/AutocompleteTableFilter";
import { generateCsvExportUrl } from "utils/helpers";
import { contractSelectors } from "store/contracts";
import { MicrosoftDefenderReportStatus } from "types/devicesApi.types";

import styles from "../styles.module.css";

const MicrosoftDefenderDevicesTable = () => {
  const { t } = useTranslation("devicesPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  const columnsConfig = useMicrosoftDefenderDevicesColumnConfig();
  const { microsoftDefender, sortField, sortOrder, currentPage, perPage } = useSelector(devicesSelectors.getSlice);
  const { list, pageCount } = useSelector(devicesSelectors.getMicrosoftDefenderDevicesTable);
  const [filterDraft, setFilterDraft] = useState(initState.microsoftDefender.filters);

  useEffect(() => {
    dispatch(setMicrosoftDefenderDevicesFiltersAC(filterDraft));
  }, [dispatch, filterDraft]);

  const handleDeviceNameFilterChange = (value: string) => {
    setFilterDraft({ ...filterDraft, deviceOrUserName: value });
    dispatch(setDevicesCurrentPageAC(1));
  };

  const handleFilterChange = useCallback(
    (keyIndex, filterValues) => {
      setFilterDraft({
        ...filterDraft,
        [keyIndex]: filterValues.map((filterValue: { value: any }) => filterValue.value),
      });
      dispatch(setDevicesCurrentPageAC(1));
    },
    [dispatch, filterDraft]
  );
  const handleResetFiltersBtnClick = useCallback(() => setFilterDraft(initState.microsoftDefender.filters), []);

  const handleSortFieldChange = useCallback((field) => dispatch(setDevicesSortFieldAC(field)), [dispatch]);

  const handlePerPageChange = useCallback((value) => dispatch(setDevicesPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setDevicesCurrentPageAC(value)), [dispatch]);

  return (
    <div className={styles.cardContent}>
      {microsoftDefender.report.status !== MicrosoftDefenderReportStatus.completed && <Spinner show />}
      {microsoftDefender.report.status === MicrosoftDefenderReportStatus.completed && (
        <div className={styles.tableBlock}>
          <div className={styles.filtersBlock}>
            <div className={styles.filterBar}>
              <div className={styles.filterLabel}>{t("filterLabels.deviceOrUserName")}</div>
              <TextInput
                data-testId="devicesFilter-deviceOrUserName"
                className={styles.filterInput}
                placeholder={t("deviceOrUserNamePlaceholder")}
                value={microsoftDefender.filters.deviceOrUserName}
                onChange={(e) => handleDeviceNameFilterChange(e.target.value)}
              />
            </div>
            {MICROSOFT_DEFENDER_DEVICES_FILTER_KEY_LIST.map((key) => (
              <AutocompleteTableFilter
                key={key}
                list={microsoftDefender.devices}
                currentFilterValues={microsoftDefender.filters[key]}
                keyIndex={key}
                onChange={handleFilterChange}
                testIdPrefix="devicesFilter"
                localeObjectName="devicesPage"
              />
            ))}
            <Button
              type="secondary"
              className={styles.resetBtn}
              onClick={handleResetFiltersBtnClick}
              data-testId="devicesResetFilterBtn"
            >
              {t("common:resetFiltersBtn")}
            </Button>
            <a
              className={styles.exportBtn}
              href={generateCsvExportUrl(selectedContractId, "devices/protection")}
              download
            >
              <Button data-testId="exportAsCsvBtn">{t("common:exportAsCsvBtn")}</Button>
            </a>
          </div>
          <div className={styles.devicesTable}>
            <Table
              dataSource={list}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default MicrosoftDefenderDevicesTable;
