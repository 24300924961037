import { mwApiClient } from "./clients";
import {
  UsersSummaryResponse,
  DevicesSummaryResponse,
  LicensesSummaryResponse,
  AppsSummaryResponse,
  DevicesOperatingSystemsSummaryResponse,
  DevicesComplianceHistoryResponse,
  UsersTypesSummaryResponse,
  UsersAppsSummaryResponse,
  UsersServicesSummaryResponse,
  DevicesProtectionResponse,
  DevicesMalwareResponse,
  DevicesFirewallResponse,
  DevicesEncryptionlResponse,
} from "../types/usageStatisticApi.types";

export const getUsersSummary = (contractId: string) =>
  mwApiClient.request<UsersSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/users/summary`,
  });

export const getDevicesSummary = (contractId: string) =>
  mwApiClient.request<DevicesSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/summary`,
  });

export const getLicensesSummary = (contractId: string) =>
  mwApiClient.request<LicensesSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/licenses/summary`,
  });

export const getAppsSummary = (contractId: string) =>
  mwApiClient.request<AppsSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/applications/summary`,
  });

export const getDevicesOperatingSystemsSummary = (contractId: string) =>
  mwApiClient.request<DevicesOperatingSystemsSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/operating-systems/summary`,
  });

export const getDevicesComplianceHistory = (contractId: string) =>
  mwApiClient.request<DevicesComplianceHistoryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/compliance/history`,
  });

export const getDevicesProtectionSummary = (contractId: string) =>
  mwApiClient.request<DevicesProtectionResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/protection/summary`,
  });

export const getDevicesMalwareSummary = (contractId: string) =>
  mwApiClient.request<DevicesMalwareResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/malware/summary`,
  });

export const getDevicesFirewallSummary = (contractId: string) =>
  mwApiClient.request<DevicesFirewallResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/firewall/summary`,
  });

export const getDevicesEncryptionSummary = (contractId: string) =>
  mwApiClient.request<DevicesEncryptionlResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/devices/encryption/summary`,
  });

export const getUsersTypesSummary = (contractId: string) =>
  mwApiClient.request<UsersTypesSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/users/user-types/summary`,
  });

export const getUsersServicesSummary = (contractId: string) =>
  mwApiClient.request<UsersServicesSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/users/activity/services/summary`,
  });

export const getUsersAppsSummary = (contractId: string) =>
  mwApiClient.request<UsersAppsSummaryResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/users/activity/apps/daily`,
  });
