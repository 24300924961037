import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";
import { usageStatisticSelectors, usageStatisticThunks } from "store/usageStatistic";
import { Card, Icon, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { sumObjectProperties } from "utils/helpers";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const devicesProtectionRoute = "/mw/devices/protection";

const DevicesMalwareSummaryChart = () => {
  const { t } = useTranslation("mwPage");
  const { requestStatus, ...chartData } = useSelector(usageStatisticSelectors.getDevicesMalwareSummary);

  const totalMalwareDevicesCount = useMemo(
    () => sumObjectProperties(chartData, ["severe", "high", "moderate", "low", "unknown"]),
    [chartData]
  );

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(usageStatisticThunks.getDevicesMalwareSummaryInfo(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <Card
      className={styles.card}
      title={t("devicesMalwareSummaryChartTitle")}
      data-testid="devicesMalwareSummaryChartContent"
    >
      <div className={styles.cardContentBlock}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && totalMalwareDevicesCount !== 0 && (
          <>
            <div className={styles.malwareRow}>
              <div className={styles.malwareType}>
                <span className={styles.severeMalwareTitle}>{t("severe")}</span>
                <Link to={devicesProtectionRoute}>
                  <span className={cn(styles.malwareQuantity, styles.redVariant)}>{chartData.severe}</span>
                </Link>
              </div>
            </div>
            <div className={styles.malwareRow}>
              <div className={styles.malwareType}>
                <span>{t("high")}</span>
                <Link to={devicesProtectionRoute}>
                  <span className={cn(styles.malwareQuantity, styles.orangeVariant)}>{chartData.high}</span>{" "}
                </Link>
              </div>
              <div className={styles.malwareType}>
                <span>{t("moderate")}</span>
                <Link to={devicesProtectionRoute}>
                  <span className={cn(styles.malwareQuantity, styles.purpleVariant)}>{chartData.moderate}</span>{" "}
                </Link>
              </div>
            </div>
            <div className={styles.malwareRow}>
              <div className={styles.malwareType}>
                <span>{t("low")}</span>
                <Link to={devicesProtectionRoute}>
                  <span className={cn(styles.malwareQuantity, styles.greyVariant)}>{chartData.low}</span>{" "}
                </Link>
              </div>
              <div className={styles.malwareType}>
                <span>{t("unknown")}</span>
                <Link to={devicesProtectionRoute}>
                  <span className={cn(styles.malwareQuantity, styles.greyVariant)}>{chartData.unknown}</span>{" "}
                </Link>
              </div>
            </div>
          </>
        )}
        {requestStatus === RequestStatus.SUCCESS && totalMalwareDevicesCount === 0 && (
          <div className={styles.malwareType}>
            <div className={styles.checkIconBlock}>
              <Icon icon="check" color="white" height={42} />
            </div>
            <span className={styles.noMalwareDetectedMessage}>{t("noMalwareDetectedMessage")}</span>
          </div>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default DevicesMalwareSummaryChart;
