// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mw_styles_tabs__lMjD5 {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 8px 8px 0 8px;\n  border-bottom: 1px solid #d8d8d8;\n}\n\n.mw_styles_tab__crGsl {\n  padding: 8px 16px;\n  margin-right: 8px;\n  border-radius: 4px 4px 0 0;\n  color: black;\n  text-decoration: none;\n  transition: 0.2s;\n}\n\n.mw_styles_tab__crGsl:hover {\n  cursor: pointer;\n}\n\n.mw_styles_activeTab__DzPwt {\n  background-color: rgba(0, 0, 0, 0.1);\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/CardTabs/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,2BAA2B;EAC3B,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,0BAA0B;EAC1B,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,oBAAoB;AACtB","sourcesContent":[".tabs {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 8px 8px 0 8px;\n  border-bottom: 1px solid #d8d8d8;\n}\n\n.tab {\n  padding: 8px 16px;\n  margin-right: 8px;\n  border-radius: 4px 4px 0 0;\n  color: black;\n  text-decoration: none;\n  transition: 0.2s;\n}\n\n.tab:hover {\n  cursor: pointer;\n}\n\n.activeTab {\n  background-color: rgba(0, 0, 0, 0.1);\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "mw_styles_tabs__lMjD5",
	"tab": "mw_styles_tab__crGsl",
	"activeTab": "mw_styles_activeTab__DzPwt"
};
export default ___CSS_LOADER_EXPORT___;
