import React from "react";
import ContractDropdown from "containers/ContractDropdown";
import ModuleBreadcrumbs from "containers/ModuleBreadcrumbs";
import { Title } from "@skyportal/ui-kit";

import styles from "./styles.module.css";

export interface PageHeaderProps {
  title: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <header className={styles.root}>
      <div className={styles.titleBlock}>
        <ModuleBreadcrumbs />
        <Title level={2}>{title}</Title>
      </div>
      <ContractDropdown className={styles.contractDropdown} />
    </header>
  );
};

export default PageHeader;
