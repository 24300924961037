import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import UsersTypesChart from "./charts/UsersTypesChart";
import ServiceUsageChart from "./charts/ServiceUsageChart";
import DailyAppUsageChart from "./charts/DailyAppUsageChart";

import styles from "components/DevicesBlockCharts/styles.module.css";

const UsersBlockCharts = () => {
  const { t } = useTranslation("mwPage");

  return (
    <>
      <h2 className={styles.title}>{t("usersSubtitle")}</h2>
      <div className={styles.chartsContainer}>
        <div className={cn(styles.chartsItem, styles.smallCard)}>
          <UsersTypesChart />
        </div>
        <div className={cn(styles.chartsItem, styles.mediumCard)}>
          <DailyAppUsageChart />
        </div>
        <div className={cn(styles.chartsItem, styles.smallCard)}>
          <ServiceUsageChart />
        </div>
      </div>
    </>
  );
};

export default UsersBlockCharts;
