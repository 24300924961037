import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDateAndTime } from "utils/time";

const useWindowsUpdateDevicesColumnConfig = () => {
  const { t } = useTranslation("devicesPage");

  return useMemo(
    () =>
      [
        {
          key: "deviceDisplayName",
          dataIndex: "deviceDisplayName",
          title: t("windowsUpdateDevicesTableColumns.deviceDisplayName"),
        },
        {
          key: "userPrincipalName",
          dataIndex: "userPrincipalName",
          title: t("windowsUpdateDevicesTableColumns.userPrincipalName"),
        },
        {
          key: "status",
          dataIndex: "status",
          title: t("windowsUpdateDevicesTableColumns.status"),
        },
        {
          key: "qualityUpdateVersion",
          dataIndex: "qualityUpdateVersion",
          title: t("windowsUpdateDevicesTableColumns.qualityUpdateVersion"),
        },
        {
          key: "featureUpdateVersion",
          dataIndex: "featureUpdateVersion",
          title: t("windowsUpdateDevicesTableColumns.featureUpdateVersion"),
        },
        {
          key: "lastScanDateTime",
          dataIndex: "lastScanDateTime",
          title: t("windowsUpdateDevicesTableColumns.lastScanDateTime"),
          render: (value: any) => <span>{value ? formatDateAndTime(value) : ""}</span>,
        },
        {
          key: "lastSyncDateTime",
          dataIndex: "lastSyncDateTime",
          title: t("windowsUpdateDevicesTableColumns.lastSyncDateTime"),
          render: (value: any) => <span>{value ? formatDateAndTime(value) : ""}</span>,
        },
      ].filter(Boolean),
    [t]
  );
};

export default useWindowsUpdateDevicesColumnConfig;
