import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useAuthenticatedUsersColumnConfig = () => {
  const { t } = useTranslation("usersPage");
  const { t: tCommon } = useTranslation("common");

  return useMemo(
    () =>
      [
        {
          key: "userDisplayName",
          dataIndex: "userDisplayName",
          title: t("authenticatedUsersTableColumns.userDisplayName"),
          width: "20%",
        },
        {
          key: "userPrincipalName",
          dataIndex: "userPrincipalName",
          title: t("authenticatedUsersTableColumns.userPrincipalName"),
          width: "25%",
        },
        {
          key: "userType",
          dataIndex: "userType",
          title: t("authenticatedUsersTableColumns.userType"),
          width: "10%",
        },
        {
          key: "isAdmin",
          dataIndex: "isAdmin",
          title: t("authenticatedUsersTableColumns.isAdmin"),
          render: (value: any) => <span>{value ? tCommon("Yes") : tCommon("No")}</span>,
          width: "10%",
        },
        {
          key: "isMfaCapable",
          dataIndex: "isMfaCapable",
          title: t("authenticatedUsersTableColumns.isMfaCapable"),
          render: (value: any) => <span>{value ? tCommon("Yes") : tCommon("No")}</span>,
          width: "10%",
        },
        {
          key: "isSsprCapable",
          dataIndex: "isSsprCapable",
          title: t("authenticatedUsersTableColumns.isSsprCapable"),
          render: (value: any) => <span>{value ? tCommon("Yes") : tCommon("No")}</span>,
          width: "10%",
        },
        {
          key: "isPasswordlessCapable",
          dataIndex: "isPasswordlessCapable",
          title: t("authenticatedUsersTableColumns.isPasswordlessCapable"),
          render: (value: any) => <span>{value ? tCommon("Yes") : tCommon("No")}</span>,
          width: "10%",
        },
        {
          key: "methodsRegistered",
          dataIndex: "methodsRegistered",
          title: t("authenticatedUsersTableColumns.methodsRegistered"),
          render: (methods: any, record: any) =>
            methods.map((methodName: string, index: number) => (
              <span>
                <span style={{ textDecoration: record.defaultMfaMethod === methodName ? "underline" : "none" }}>
                  {methodName}
                </span>
                {index !== methods.length - 1 && ", "}
              </span>
            )),
          sortDisabled: true,
          width: "15%",
        },
      ].filter(Boolean),
    [t, tCommon]
  );
};

export default useAuthenticatedUsersColumnConfig;
