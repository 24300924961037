import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, Checkbox, Spinner, Table, TextInput } from "@skyportal/ui-kit";
import { initState } from "store/users/users.reducer";
import { usersSelectors, usersThunks } from "store/users";
import useAllUsersColumnConfig from "hooks/useAllUsersColumnConfig";
import {
  setUsersSortFieldAC,
  setAllUsersFiltersAC,
  setUsersCurrentPageAC,
  setUsersPerPageAC,
} from "store/users/users.actions";
import { ALL_USERS_FILTER_KEY_LIST } from "utils/usersTables";
import AutocompleteTableFilter from "containers/AutocompleteTableFilter";
import PaginationBox from "containers/PaginationBox";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../styles.module.css";

const AllUsersTable = () => {
  const { t } = useTranslation("usersPage");
  const dispatch = useDispatch();

  const columnsConfig = useAllUsersColumnConfig();
  const { allUsers, authenticatedUsers, sortField, sortOrder, currentPage, perPage } = useSelector(
    usersSelectors.getSlice
  );
  const { list, pageCount } = useSelector(usersSelectors.getAllUsersTable);
  const [filterDraft, setFilterDraft] = useState(initState.allUsers.filters);

  const isFetching = useMemo(
    () =>
      allUsers.requestStatus !== RequestStatus.SUCCESS || authenticatedUsers.requestStatus !== RequestStatus.SUCCESS,
    [allUsers.requestStatus, authenticatedUsers.requestStatus]
  );

  useEffect(() => {
    dispatch(setAllUsersFiltersAC(filterDraft));
  }, [dispatch, filterDraft]);

  const handleNameFilterChange = (value: string) => {
    setFilterDraft({ ...filterDraft, displayNameOrMail: value });
    dispatch(setUsersCurrentPageAC(1));
  };

  const handleFilterChange = useCallback(
    (keyIndex, filterValues) => {
      setFilterDraft({
        ...filterDraft,
        [keyIndex]: filterValues.map((filterValue: { value: any }) => filterValue.value),
      });
      dispatch(setUsersCurrentPageAC(1));
    },
    [dispatch, filterDraft]
  );

  const handleIsActiveCheckboxClick = useCallback(() => {
    setFilterDraft({ ...filterDraft, isActive: !filterDraft.isActive });
    dispatch(setUsersCurrentPageAC(1));
  }, [dispatch, filterDraft]);
  const handleIsInactiveCheckboxClick = useCallback(() => {
    setFilterDraft({ ...filterDraft, isInactive: !filterDraft.isInactive });
    dispatch(setUsersCurrentPageAC(1));
  }, [dispatch, filterDraft]);

  const handleResetFiltersBtnClick = useCallback(() => setFilterDraft(initState.allUsers.filters), []);

  const handleSortFieldChange = useCallback((field) => dispatch(setUsersSortFieldAC(field)), [dispatch]);

  const handlePerPageChange = useCallback((value) => dispatch(setUsersPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setUsersCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(usersThunks.getAllUsersList(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <div className={styles.cardContent}>
      {allUsers.requestStatus === RequestStatus.PENDING && <Spinner show />}
      {allUsers.requestStatus === RequestStatus.SUCCESS && (
        <div className={styles.tableBlock}>
          <div className={styles.filtersBlock}>
            <div className={styles.filterBar}>
              <div className={styles.filterLabel}>{t("filterLabels.displayNameOrMail")}</div>
              <TextInput
                data-testId="usersFilter-displayNameOrMail"
                className={styles.filterInput}
                placeholder={t("usersNameEmailPlaceholder")}
                value={allUsers.filters.displayNameOrMail}
                onChange={(e) => handleNameFilterChange(e.target.value)}
              />
            </div>
            {ALL_USERS_FILTER_KEY_LIST.map((key) => (
              <AutocompleteTableFilter
                key={key}
                list={allUsers.list}
                currentFilterValues={allUsers.filters[key]}
                keyIndex={key}
                onChange={handleFilterChange}
                testIdPrefix="usersFilter"
                localeObjectName="usersPage"
              />
            ))}
            <div className={styles.checkboxFilterBlock}>
              <div className={styles.filterLabel}>{t("filterLabels.accountEnabled")}</div>
              <div className={styles.checkboxesBlock}>
                <Checkbox
                  data-testId="usersFilter-isActive"
                  checked={allUsers.filters.isActive}
                  onClick={handleIsActiveCheckboxClick}
                  disabled={isFetching}
                  size="small"
                />
                <span className={styles.checkboxLabel}>{t("active")}</span>
                <Checkbox
                  data-testId="usersFilter-isInactive"
                  checked={allUsers.filters.isInactive}
                  onClick={handleIsInactiveCheckboxClick}
                  disabled={isFetching}
                  size="small"
                />
                <span className={styles.checkboxLabel}>{t("inactive")}</span>
              </div>
            </div>
            <Button
              type="secondary"
              className={styles.resetBtn}
              onClick={handleResetFiltersBtnClick}
              data-testId="usersResetFilterBtn"
            >
              {t("common:resetFiltersBtn")}
            </Button>
          </div>
          <div className={styles.usersTable}>
            <Table
              dataSource={list}
              columns={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortFieldChange={handleSortFieldChange}
            />
          </div>
          <PaginationBox
            perPage={perPage}
            currentPage={currentPage}
            pageCount={pageCount}
            onPerPageChange={handlePerPageChange}
            onChangePage={handlePageChange}
          />
        </div>
      )}
      {allUsers.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
    </div>
  );
};

export default AllUsersTable;
