import React from "react";
import UsageStatisticCard from "./UsageStaticticCard";
import { USAGE_STATISTICS_VARIANTS } from "./constants";
import useFetchUsageSummary from "hooks/useFetchUsageSummary";

import styles from "./styles.module.css";

const UsageStatisticCards = () => {
  useFetchUsageSummary();

  return (
    <div className={styles.usageBoxContainer}>
      {USAGE_STATISTICS_VARIANTS.map((variant) => (
        <div key={variant} className={styles.usageBoxItem}>
          <div data-testid={`usageStatisticCard-${variant}`}>
            <UsageStatisticCard variant={variant} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default UsageStatisticCards;
