import { ServiceSummary } from "types/usageStatisticApi.types";

export const composeChartOptions = (servicesUsageSummary: ServiceSummary[]) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      plotShadow: false,
      type: "bar",
      height: 350,
    },
    colors: ["#f0f0f0", "#c48df7"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const chartData = this as any;
        return chartData.points.reverse().map((point: any, index: number) =>
          index === 0
            ? `<span style="font-family: Dustin Sans;">
                <span style="font-size: 10px;">${point.x}</span><br>
                <span style="color:${point.color}">${point.series.name}</span>
                <span>: <b>${point.y}</b> (${point.percentage.toFixed(1)}%)</span>
              </span><br>`
            : `<span style="font-family: Dustin Sans;">
                <span style="color:#333">${point.series.name}</span>
                <span>: <b>${point.y}</b> (${point.percentage.toFixed(1)}%)</span>
              </span><br>`
        );
      },
    },
    accessibility: {
      point: {
        valueSuffix: "users",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        groupPadding: 0,
      },
    },
    xAxis: {
      categories: servicesUsageSummary.map((service) => service.name),
      labels: {
        align: "left",
        x: 4,
        y: 4,
        style: {
          fontSize: "12px",
          color: "#333333",
          whiteSpace: "nowrap",
        },
      },
    },
    yAxis: {
      title: {
        text: "Users",
      },
      max: Math.max(...servicesUsageSummary.map((service) => service.active + service.inactive)),
    },
    series: [
      {
        name: "Inactive",
        data: servicesUsageSummary.map((service) => service.inactive),
      },
      {
        name: "Active",
        data: servicesUsageSummary.map((service) => service.active),
      },
    ],
    legend: {
      enabled: false,
    },
  };
};
