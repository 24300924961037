import { RootState } from "../index";
import { ContractsStateSlice } from "./contracts.types";
import { createSelector } from "reselect";

export const getContractsSlice = (state: RootState): ContractsStateSlice => state.contracts;

export const getContractList = (state: RootState): ContractsStateSlice["list"] => getContractsSlice(state).list;

export const getSelectedContractId = (state: RootState): ContractsStateSlice["selectedContractId"] =>
  getContractsSlice(state).selectedContractId;

export const getContractDropdownOptions = createSelector(getContractList, (contracts) =>
  contracts.map((item) => ({ label: `${item.organization} (${item.name})`, value: item.name as string }))
);
