import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import DevicesPageContent from "components/DevicesPageContent";

const DevicesPage: React.FC = () => {
  const { t } = useTranslation("devicesPage");

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <DevicesPageContent />
    </PageLayout>
  );
};

export default DevicesPage;
