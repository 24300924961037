import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { usageStatisticSelectors, usageStatisticThunks } from "store/usageStatistic";
import { composeMFAStatusChartOptions } from "./utils";
import { Card, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import CardFallback from "containers/CardFallback";

import styles from "../../styles.module.css";

const MFAStatusChart = () => {
  const { t } = useTranslation("mwPage");
  const { requestStatus, ...usersSummaryData } = useSelector(usageStatisticSelectors.getUsersSummary);

  const chartOptions = useMemo(() => composeMFAStatusChartOptions(usersSummaryData), [usersSummaryData]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(usageStatisticThunks.getUsersSummaryInfo(selectedContractId));
    }
  }, [selectedContractId, thunkDispatch]);

  return (
    <Card className={styles.card} title={t("MFAStatusChartTitle")} data-testid="MFAStatusChartContent">
      <div className={styles.cardContent}>
        {requestStatus === RequestStatus.PENDING && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default MFAStatusChart;
