import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { composeChartOptions } from "./utils";
import { LicenseModel } from "types/licensesApi.types";

interface LicensesUsageChartProps {
  license: LicenseModel;
}

const LicensesUsageChart = ({ license }: LicensesUsageChartProps) => {
  const chartOptions = useMemo(() => composeChartOptions(license), [license]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default LicensesUsageChart;
