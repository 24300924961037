import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import AppsPageContent from "components/AppsPageContent";

export const AppsPage: React.FC = () => {
  const { t } = useTranslation("applicationsPage");

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <AppsPageContent />
    </PageLayout>
  );
};

export default AppsPage;
