import { RootState } from "../index";
import { UsersStateSlice, AllUsers, AuthenticatedUsers } from "./users.types";
import { getPaginationSlice } from "utils/devicesTables";
import { allUsersSortHandler, authenticatedUsersSortHandler } from "utils/usersTables";

export const getSlice = (state: RootState): UsersStateSlice => state.users;
export const getAllUsersList = (state: RootState): AllUsers["list"] => getSlice(state).allUsers.list;
export const getAuthenticatedUsersList = (state: RootState): AuthenticatedUsers["list"] =>
  getSlice(state).authenticatedUsers.list;

const allUsersListComposer = (usersSlice: UsersStateSlice) => {
  const {
    allUsers: { list, filters },
    sortField,
    sortOrder,
    perPage,
    currentPage,
  } = usersSlice;
  let _list = [...list];

  if (filters.displayNameOrMail) {
    _list = _list.filter(
      (item) =>
        item.displayName.toLowerCase().includes(filters.displayNameOrMail.toLowerCase()) ||
        item.mail?.toLowerCase().includes(filters.displayNameOrMail.toLowerCase())
    );
  }

  if (filters.department.length || filters.userType.length) {
    const autocompleteFieldFilterNames = Object.keys(filters).filter((item) => item !== "displayNameOrMail");

    autocompleteFieldFilterNames.forEach((key) => {
      if ((filters as any)[key].length) {
        _list = _list.filter((item) => (filters as any)[key].includes((item as any)[key]));
      }
    });
  }

  if (filters.isActive && filters.isInactive)
    _list = _list.filter((item) => item.accountEnabled === true || item.accountEnabled === false);
  else if (filters.isActive) _list = _list.filter((item) => item.accountEnabled === true);
  else if (filters.isInactive) _list = _list.filter((item) => item.accountEnabled === false);
  else _list = [];

  if (sortField && allUsersSortHandler[sortField]) {
    _list.sort(allUsersSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getAllUsersTable = (state: RootState) => {
  const usersSlice = getSlice(state);
  return allUsersListComposer(usersSlice);
};

const authenticatedUsersListComposer = (usersSlice: UsersStateSlice) => {
  const {
    authenticatedUsers: { list, filters },
    sortField,
    sortOrder,
    perPage,
    currentPage,
  } = usersSlice;
  let _list = [...list];

  if (filters.displayNameOrMail) {
    _list = _list.filter(
      (item) =>
        item.userDisplayName.toLowerCase().includes(filters.displayNameOrMail.toLowerCase()) ||
        item.userPrincipalName.toLowerCase().includes(filters.displayNameOrMail.toLowerCase())
    );
  }

  if (filters.userType.length || filters.methodsRegistered.length) {
    const autocompleteFieldFilterNames = Object.keys(filters).filter((item) => item !== "displayNameOrMail");

    autocompleteFieldFilterNames.forEach((key) => {
      if ((filters as any)[key].length) {
        _list = _list.filter((item: any) => {
          if (Array.isArray(item[key])) {
            return (filters as any)[key].every((filter: any) => item[key].includes(filter));
          } else {
            return (filters as any)[key].includes(item[key]);
          }
        });
      }
    });
  }

  if (filters.isMfaCapable && filters.isNotMfaCapable)
    _list = _list.filter((item) => item.isMfaCapable === true || item.isMfaCapable === false);
  else if (filters.isMfaCapable) _list = _list.filter((item) => item.isMfaCapable === true);
  else if (filters.isNotMfaCapable) _list = _list.filter((item) => item.isMfaCapable === false);
  else _list = [];

  if (filters.isSsprCapable && filters.isNotSsprCapable)
    _list = _list.filter((item) => item.isSsprCapable === true || item.isSsprCapable === false);
  else if (filters.isSsprCapable) _list = _list.filter((item) => item.isSsprCapable === true);
  else if (filters.isNotSsprCapable) _list = _list.filter((item) => item.isSsprCapable === false);
  else _list = [];

  if (sortField && authenticatedUsersSortHandler[sortField]) {
    _list.sort(authenticatedUsersSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getAuthenticatedUsersTable = (state: RootState) => {
  const usersSlice = getSlice(state);
  return authenticatedUsersListComposer(usersSlice);
};
