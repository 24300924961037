import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "pages/HomePage";
import DevicesPage from "pages/DevicesPage";
import UsersPage from "pages/UsersPage";
import LicensesPage from "pages/LicensesPage";
import AppsPage from "pages/AppsPage";

export const AppRouter: React.FC = () => (
  <Switch>
    <Route path="/mw/overview" exact component={HomePage} />
    <Route path="/mw/devices" component={DevicesPage} />
    <Route path="/mw/users" component={UsersPage} />
    <Route path="/mw/licenses" exact component={LicensesPage} />
    <Route path="/mw/apps" component={AppsPage} />
    <Route path="*">
      <Redirect to="/mw/overview" />
    </Route>
  </Switch>
);

export default AppRouter;
