import * as types from "./devices.types";

export const managedDevicesRequestAC: types.ManagedDevicesRequestAC = () => ({
  type: types.MANAGED_DEVICES_REQUEST,
});

export const managedDevicesRequestSuccessAC: types.ManagedDevicesRequestSuccessAC = (payload) => ({
  type: types.MANAGED_DEVICES_REQUEST_SUCCESS,
  payload,
});

export const managedDevicesRequestFailureAC: types.ManagedDevicesRequestFailureAC = () => ({
  type: types.MANAGED_DEVICES_REQUEST_FAILURE,
});

export const unmanagedDevicesRequestAC: types.UnmanagedDevicesRequestAC = () => ({
  type: types.UNMANAGED_DEVICES_REQUEST,
});

export const unmanagedDevicesRequestSuccessAC: types.UnmanagedDevicesRequestSuccessAC = (payload) => ({
  type: types.UNMANAGED_DEVICES_REQUEST_SUCCESS,
  payload,
});

export const unmanagedDevicesRequestFailureAC: types.UnmanagedDevicesRequestFailureAC = () => ({
  type: types.UNMANAGED_DEVICES_REQUEST_FAILURE,
});

export const windowsUpdateConfigsRequestAC: types.WindowsUpdateConfigsRequestAC = () => ({
  type: types.WINDOWS_UPDATE_CONFIGS_REQUEST,
});

export const windowsUpdateConfigsRequestSuccessAC: types.WindowsUpdateConfigsRequestSuccessAC = (payload) => ({
  type: types.WINDOWS_UPDATE_CONFIGS_REQUEST_SUCCESS,
  payload,
});

export const windowsUpdateConfigsRequestFailureAC: types.WindowsUpdateConfigsRequestFailureAC = () => ({
  type: types.WINDOWS_UPDATE_CONFIGS_REQUEST_FAILURE,
});

export const selectedWindowsUpdateDevicesRequestAC: types.SelectedWindowsUpdateDevicesRequestAC = (payload) => ({
  type: types.SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST,
  payload,
});

export const selectedWindowsUpdateDevicesRequestSuccessAC: types.SelectedWindowsUpdateDevicesRequestSuccessAC = (
  payload
) => ({
  type: types.SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_SUCCESS,
  payload,
});

export const selectedWindowsUpdateDevicesRequestFailedAC: types.SelectedWindowsUpdateDevicesRequestFailureAC = () => ({
  type: types.SELECTED_WINDOWS_UPDATE_DEVICES_REQUEST_FAILURE,
});

export const microsoftDefenderDevicesRequestAC: types.MicrosoftDefenderDevicesRequestAC = () => ({
  type: types.MICROSOFT_DEFENDER_DEVICES_REQUEST,
});

export const microsoftDefenderDevicesRequestSuccessAC: types.MicrosoftDefenderDevicesRequestSuccessAC = (payload) => ({
  type: types.MICROSOFT_DEFENDER_DEVICES_REQUEST_SUCCESS,
  payload,
});

export const microsoftDefenderDevicesRequestFailureAC: types.MicrosoftDefenderDevicesRequestFailureAC = () => ({
  type: types.MICROSOFT_DEFENDER_DEVICES_REQUEST_FAILURE,
});

export const setDevicesSortFieldAC: types.SetDevicesSortFieldAC = (payload) => ({
  type: types.SET_DEVICES_SORT_FIELD,
  payload,
});

export const setManagedDevicesFiltersAC: types.SetManagedDevicesFiltersAC = (payload) => ({
  type: types.SET_MANAGED_DEVICES_FILTERS,
  payload,
});

export const setUnmanagedDevicesFiltersAC: types.SetUnmanagedDevicesFiltersAC = (payload) => ({
  type: types.SET_UNMANAGED_DEVICES_FILTERS,
  payload,
});

export const setWindowsUpdateDevicesFiltersAC: types.SetWindowsUpdateDevicesFiltersAC = (payload) => ({
  type: types.SET_WINDOWS_UPDATE_DEVICES_FILTERS,
  payload,
});

export const setMicrosoftDefenderDevicesFiltersAC: types.SetMicrosoftDefenderDevicesFiltersAC = (payload) => ({
  type: types.SET_MICROSOFT_DEFENDER_DEVICES_FILTERS,
  payload,
});

export const setDevicesPerPageAC: types.SetDevicesPerPageAC = (payload) => ({
  type: types.SET_DEVICES_PER_PAGE,
  payload,
});

export const setDevicesCurrentPageAC: types.SetDevicesCurrentPageAC = (payload) => ({
  type: types.SET_DEVICES_CURRENT_PAGE,
  payload,
});
