import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Icon, Spinner } from "@skyportal/ui-kit";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { UsageStatisticCardData, UsageStatisticCardsConfig } from "./constants";
import { UsageStatisticCardConfigType, UsageStatisticCardType, UsageStatisticCardVariantDataType } from "./types";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";

import styles from "./styles.module.css";

interface UsageStatisticCardProps {
  variant: UsageStatisticCardType;
}

const UsageStatisticCard: React.FC<UsageStatisticCardProps> = ({ variant }) => {
  const cardData = useSelector(UsageStatisticCardsConfig[variant].selector);
  const cardDataNames: UsageStatisticCardVariantDataType = UsageStatisticCardData[variant];
  const cardConfig: UsageStatisticCardConfigType = UsageStatisticCardsConfig[variant];
  const { t } = useTranslation("mwPage");
  const wrapperClasses = cn(styles.root, styles[cardConfig.style]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const reloadData = useCallback(() => {
    if (selectedContractId) {
      thunkDispatch(cardConfig.thunk(selectedContractId));
    }
  }, [cardConfig, selectedContractId, thunkDispatch]);

  return (
    <div className={wrapperClasses}>
      {cardData.requestStatus === RequestStatus.PENDING && <Spinner data-testid="infoBoxSpinner" show />}
      {cardData.requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      <div className={styles.infoBox}>
        <Icon type="fontawesome" className={styles.mwBgIcon} icon={cardConfig.icon} />
        <div className={styles.label}>{t(cardConfig.labelPhrase, { number: cardData[cardDataNames[0]] })}</div>
        <div className={styles.mwSubLabel}>
          {t(cardConfig.subLabelPhrase, {
            firstNum: cardData[cardDataNames[1]] || 0,
            secondNum: cardData[cardDataNames[2]] || 0,
          })}
        </div>
        <Link data-testid="moreInfoLink" to={cardConfig.linkTo}>
          <Button primaryTypeColor={cardConfig.buttonColor} rightIcon="arrow-right" className={styles.moreInfoBtn}>
            {t("usageStatisticBlock.moreInfoLink")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default UsageStatisticCard;
