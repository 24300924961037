import { RootState } from "../index";
import { getPaginationSlice } from "utils/devicesTables";
import { otherLicensesSortHandler } from "utils/licensesTable";
import { LicensesStateSlice } from "./licenses.types";

export const getSlice = (state: RootState) => state.licenses;
export const getLicensesList = (state: RootState) => getSlice(state).list;

const otherLicensesListComposer = (devicesSlice: LicensesStateSlice) => {
  const { list, sortField, sortOrder, perPage, currentPage } = devicesSlice;
  let _list = [...list.filter((license) => !license.isMicrosoft365)];

  if (sortField && otherLicensesSortHandler[sortField]) {
    _list.sort(otherLicensesSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getOtherLicensesTable = (state: RootState) => {
  const licensesSlice = getSlice(state);
  return otherLicensesListComposer(licensesSlice);
};
