import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { bytesToGb } from "utils/size";

const useManagedDevicesColumnConfig = () => {
  const { t } = useTranslation("devicesPage");

  return useMemo(
    () =>
      [
        {
          key: "deviceName",
          dataIndex: "deviceName",
          title: t("managedDevicesTableColumns.deviceName"),
          width: 150,
        },
        {
          key: "ownerType",
          dataIndex: "ownerType",
          title: t("managedDevicesTableColumns.ownerType"),
        },
        {
          key: "complianceState",
          dataIndex: "complianceState",
          title: t("managedDevicesTableColumns.complianceState"),
        },
        {
          key: "manufacturer",
          dataIndex: "manufacturer",
          title: t("managedDevicesTableColumns.manufacturer"),
        },
        {
          key: "model",
          dataIndex: "model",
          title: t("managedDevicesTableColumns.model"),
          width: 150,
        },
        {
          key: "operatingSystem",
          dataIndex: "operatingSystem",
          title: t("managedDevicesTableColumns.operatingSystem"),
        },
        {
          key: "operatingSystemVersion",
          dataIndex: "operatingSystemVersion",
          title: t("managedDevicesTableColumns.operatingSystemVersion"),
        },
        {
          key: "emailAddress",
          dataIndex: "emailAddress",
          title: t("managedDevicesTableColumns.emailAddress"),
        },
        {
          key: "serialNumber",
          dataIndex: "serialNumber",
          title: t("managedDevicesTableColumns.serialNumber"),
          width: 150,
        },
        {
          key: "freeStorageSpaceInBytes",
          dataIndex: "freeStorageSpaceInBytes",
          title: t("managedDevicesTableColumns.freeTotalSpace"),
          render: (value: any, record: any) => (
            <span>{`${bytesToGb(record.freeStorageSpaceInBytes).toFixed(1)} GB / ${bytesToGb(
              record.totalStorageSpaceInBytes
            ).toFixed(1)} GB`}</span>
          ),
        },
      ].filter(Boolean),
    [t]
  );
};

export default useManagedDevicesColumnConfig;
