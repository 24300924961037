import { AppThunk } from "store";
import * as mwApi from "api/apps.api";
import * as appsActions from "./applications.actions";
import { notificationErrorAC } from "store/notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";
import i18n from "locales";

export const getManagedAppsList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(appsActions.managedAppsRequestAC());
      const { data } = await mwApi.getManagedApps(selectedContractId);
      dispatch(appsActions.managedAppsRequestSuccessAC(data.applications));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "MANAGED APPS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:managedAppsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(appsActions.managedAppsRequestFailureAC());
    }
  };

export const getDetectedAppsList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(appsActions.detectedAppsRequestAC());
      const { data } = await mwApi.getDetectedApps(selectedContractId);
      dispatch(appsActions.detectedAppsRequestSuccessAC(data.applications));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "DETECTED APPS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:detectedAppsRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(appsActions.detectedAppsRequestFailureAC());
    }
  };
