import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useUnmanagedDevicesColumnConfig = () => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("devicesPage");

  const complianceColumnValuesMap = new Map();
  complianceColumnValuesMap.set(true, tCommon("Yes"));
  complianceColumnValuesMap.set(false, tCommon("No"));
  complianceColumnValuesMap.set(null, "");

  return useMemo(
    () =>
      [
        {
          key: "deviceName",
          dataIndex: "deviceName",
          title: t("unmanagedDevicesTableColumns.deviceName"),
          width: 150,
        },
        {
          key: "ownerType",
          dataIndex: "ownerType",
          title: t("unmanagedDevicesTableColumns.ownerType"),
        },
        {
          key: "isCompliant",
          dataIndex: "isCompliant",
          title: t("unmanagedDevicesTableColumns.isCompliant"),
          render: (value: any) => <span>{complianceColumnValuesMap.get(value)}</span>,
        },
        {
          key: "manufacturer",
          dataIndex: "manufacturer",
          title: t("unmanagedDevicesTableColumns.manufacturer"),
        },
        {
          key: "model",
          dataIndex: "model",
          title: t("unmanagedDevicesTableColumns.model"),
        },
        {
          key: "operatingSystem",
          dataIndex: "operatingSystem",
          title: t("unmanagedDevicesTableColumns.operatingSystem"),
        },
        {
          key: "operatingSystemVersion",
          dataIndex: "operatingSystemVersion",
          title: t("unmanagedDevicesTableColumns.operatingSystemVersion"),
        },
      ].filter(Boolean),
    [t]
  );
};

export default useUnmanagedDevicesColumnConfig;
