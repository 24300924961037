import { detectedAppsSortHandler, managedAppsSortHandler } from "utils/appsTables";
import { getPaginationSlice } from "utils/devicesTables";
import { RootState } from "../index";
import { AppsStateSlice, ManagedApps, DetectedApps } from "./applications.types";

export const getSlice = (state: RootState): AppsStateSlice => state.applications;
export const getManagedAppsList = (state: RootState): ManagedApps["list"] => getSlice(state).managedApps.list;
export const getDetectedAppsList = (state: RootState): DetectedApps["list"] => getSlice(state).detectedApps.list;

const managedAppsListComposer = (appsSlice: AppsStateSlice) => {
  const {
    managedApps: { list, filters },
    sortField,
    sortOrder,
    perPage,
    currentPage,
  } = appsSlice;
  let _list = [...list];

  if (filters.name) {
    _list = _list.filter((item) => item.name.toLowerCase().includes(filters.name.toLowerCase()));
  }

  if (filters.publisher.length || filters.platform.length) {
    const autocompleteFieldFilterNames = Object.keys(filters).filter((item) => item !== "name");

    autocompleteFieldFilterNames.forEach((key) => {
      if ((filters as any)[key].length) {
        _list = _list.filter((item) => (filters as any)[key].includes((item as any)[key]));
      }
    });
  }

  if (sortField && managedAppsSortHandler[sortField]) {
    _list.sort(managedAppsSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getManagedAppsTable = (state: RootState) => {
  const appsSlice = getSlice(state);
  return managedAppsListComposer(appsSlice);
};

const detectedAppsListComposer = (appsSlice: AppsStateSlice) => {
  const {
    detectedApps: { list, filters },
    sortField,
    sortOrder,
    perPage,
    currentPage,
  } = appsSlice;
  let _list = [...list];

  if (filters.name) {
    _list = _list.filter((item) => item.name.toLowerCase().includes(filters.name.toLowerCase()));
  }

  if (sortField && detectedAppsSortHandler[sortField]) {
    _list.sort(detectedAppsSortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getDetectedAppsTable = (state: RootState) => {
  const appsSlice = getSlice(state);
  return detectedAppsListComposer(appsSlice);
};
