import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useManagedAppsColumnConfig = () => {
  const { t } = useTranslation("applicationsPage");

  return useMemo(
    () =>
      [
        {
          key: "name",
          dataIndex: "name",
          title: t("managedAppsTableColumns.name"),
          width: "30%",
        },
        {
          key: "version",
          dataIndex: "version",
          title: t("managedAppsTableColumns.version"),
        },
        {
          key: "publisher",
          dataIndex: "publisher",
          title: t("managedAppsTableColumns.publisher"),
        },
        {
          key: "platform",
          dataIndex: "platform",
          title: t("managedAppsTableColumns.platform"),
        },
        {
          key: "failedDevicePercentage",
          dataIndex: "failedDevicePercentage",
          title: t("managedAppsTableColumns.failedDevicePercentage"),
          render: (value: any) => <span>{`${value.toFixed(0)}%`}</span>,
        },
        {
          key: "installedUserCount",
          dataIndex: "installedUserCount",
          title: t("managedAppsTableColumns.installedUserCount"),
          render: (value: any, record: any) => (
            <>
              <span>{`${record.installedUserCount} Successful`}</span>
              <br />
              <span>{`${record.failedUserCount} Failed`}</span>
            </>
          ),
        },
        {
          key: "installedDeviceCount",
          dataIndex: "installedDeviceCount",
          title: t("managedAppsTableColumns.installedDeviceCount"),
          render: (value: any, record: any) => (
            <>
              <span>{`${record.installedDeviceCount} Successful`}</span>
              <br />
              <span>{`${record.failedDeviceCount} Failed`}</span>
            </>
          ),
        },
      ].filter(Boolean),
    [t]
  );
};

export default useManagedAppsColumnConfig;
