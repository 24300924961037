import { AppThunk } from "store";
import * as mwApi from "api/users.api";
import * as usersActions from "./users.actions";
import { notificationErrorAC } from "store/notification/notification.actions";
import i18n from "locales";
import { parseApiErrorMessage } from "utils/helpers";

export const getAllUsersList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersActions.allUsersRequestAC());
      const { data } = await mwApi.getAllUsers(selectedContractId);
      dispatch(usersActions.allUsersRequestSuccessAC(data.users));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "ALL USERS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:allUsersRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usersActions.allUsersRequestFailureAC());
    }
  };

export const getAuthenticatedUsersList =
  (selectedContractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(usersActions.authenticatedUsersRequestAC());
      const { data } = await mwApi.getAuthenticatedUsers(selectedContractId);
      dispatch(usersActions.authenticatedUsersRequestSuccessAC(data.users));
    } catch (apiErrorMessage) {
      console.error(apiErrorMessage, "AUTHENTICATED USERS REQUEST");
      dispatch(
        notificationErrorAC({
          message: i18n.t("notification:authenticatedUsersRequestFailure"),
          description: parseApiErrorMessage(apiErrorMessage),
        })
      );
      dispatch(usersActions.authenticatedUsersRequestFailureAC());
    }
  };
