import * as types from "./usageStatistic.types";

export const usersSummaryRequestAC: types.UsersSummaryRequestAC = () => ({
  type: types.USERS_SUMMARY_REQUEST,
});

export const usersSummaryRequestSuccessAC: types.UsersSummaryRequestSuccessAC = (payload) => ({
  type: types.USERS_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const usersSummaryRequestFailureAC: types.UsersSummaryRequestFailureAC = () => ({
  type: types.USERS_SUMMARY_REQUEST_FAILURE,
});

export const devicesSummaryRequestAC: types.DevicesSummaryRequestAC = () => ({
  type: types.DEVICES_SUMMARY_REQUEST,
});

export const devicesSummaryRequestSuccessAC: types.DevicesSummaryRequestSuccessAC = (payload) => ({
  type: types.DEVICES_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const devicesSummaryRequestFailureAC: types.DevicesSummaryRequestFailureAC = () => ({
  type: types.DEVICES_SUMMARY_REQUEST_FAILURE,
});

export const licensesSummaryRequestAC: types.LicensesSummaryRequestAC = () => ({
  type: types.LICENSES_SUMMARY_REQUEST,
});

export const licensesSummaryRequestSuccessAC: types.LicensesSummaryRequestSuccessAC = (payload) => ({
  type: types.LICENSES_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const licensesSummaryRequestFailureAC: types.LicensesSummaryRequestFailureAC = () => ({
  type: types.LICENSES_SUMMARY_REQUEST_FAILURE,
});

export const appsSummaryRequestAC: types.AppsSummaryRequestAC = () => ({
  type: types.APPS_SUMMARY_REQUEST,
});

export const appsSummaryRequestSuccessAC: types.AppsSummaryRequestSuccessAC = (payload) => ({
  type: types.APPS_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const appsSummaryRequestFailureAC: types.AppsSummaryRequestFailureAC = () => ({
  type: types.APPS_SUMMARY_REQUEST_FAILURE,
});

export const windowsUpdateSummaryRequestAC: types.WindowsUpdateSummaryRequestAC = () => ({
  type: types.WINDOWS_UPDATE_SUMMARY_REQUEST,
});

export const windowsUpdateSummaryRequestSuccessAC: types.WindowsUpdateSummaryRequestSuccessAC = (payload) => ({
  type: types.WINDOWS_UPDATE_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const windowsUpdateSummaryRequestFailureAC: types.WindowsUpdateSummaryRequestFailureAC = () => ({
  type: types.WINDOWS_UPDATE_SUMMARY_REQUEST_FAILURE,
});

export const devicesProtectionSummaryRequestAC: types.DevicesProtectionSummaryRequestAC = () => ({
  type: types.DEVICES_PROTECTION_SUMMARY_REQUEST,
});

export const devicesProtectionSummaryRequestSuccessAC: types.DevicesProtectionSummaryRequestSuccessAC = (payload) => ({
  type: types.DEVICES_PROTECTION_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const devicesProtectionSummaryRequestFailureAC: types.DevicesProtectionSummaryRequestFailureAC = () => ({
  type: types.DEVICES_PROTECTION_SUMMARY_REQUEST_FAILURE,
});

export const devicesMalwareSummaryRequestAC: types.DevicesMalwareSummaryRequestAC = () => ({
  type: types.DEVICES_MALWARE_SUMMARY_REQUEST,
});

export const devicesMalwareSummaryRequestSuccessAC: types.DevicesMalwareSummaryRequestSuccessAC = (payload) => ({
  type: types.DEVICES_MALWARE_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const devicesMalwareSummaryRequestFailureAC: types.DevicesMalwareSummaryRequestFailureAC = () => ({
  type: types.DEVICES_MALWARE_SUMMARY_REQUEST_FAILURE,
});

export const devicesFirewallSummaryRequestAC: types.DevicesFirewallSummaryRequestAC = () => ({
  type: types.DEVICES_FIREWALL_SUMMARY_REQUEST,
});

export const devicesFirewallSummaryRequestSuccessAC: types.DevicesFirewallSummaryRequestSuccessAC = (payload) => ({
  type: types.DEVICES_FIREWALL_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const devicesFirewallSummaryRequestFailureAC: types.DevicesFirewallSummaryRequestFailureAC = () => ({
  type: types.DEVICES_FIREWALL_SUMMARY_REQUEST_FAILURE,
});

export const devicesEncryptionSummaryRequestAC: types.DevicesEncryptionSummaryRequestAC = () => ({
  type: types.DEVICES_ENCRYPTION_SUMMARY_REQUEST,
});

export const devicesEncryptionSummaryRequestSuccessAC: types.DevicesEncryptionSummaryRequestSuccessAC = (payload) => ({
  type: types.DEVICES_ENCRYPTION_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const devicesEncryptionSummaryRequestFailureAC: types.DevicesEncryptionSummaryRequestFailureAC = () => ({
  type: types.DEVICES_ENCRYPTION_SUMMARY_REQUEST_FAILURE,
});

export const devicesOperatingSystemsRequestAC: types.DevicesOperatingSystemsRequestAC = () => ({
  type: types.DEVICES_OPERATING_SYSTEMS_REQUEST,
});

export const devicesOperatingSystemsRequestSuccessAC: types.DevicesOperatingSystemsRequestSuccessAC = (payload) => ({
  type: types.DEVICES_OPERATING_SYSTEMS_REQUEST_SUCCESS,
  payload,
});

export const devicesOperatingSystemsRequestFailureAC: types.DevicesOperatingSystemsRequestFailureAC = () => ({
  type: types.DEVICES_OPERATING_SYSTEMS_REQUEST_FAILURE,
});

export const devicesCompianceHistoryRequestAC: types.DevicesComplianceHistoryRequestAC = () => ({
  type: types.DEVICES_COMPLIANCE_HISTORY_REQUEST,
});

export const devicesCompianceHistoryRequestSuccessAC: types.DevicesComplianceHistoryRequestSuccessAC = (payload) => ({
  type: types.DEVICES_COMPLIANCE_HISTORY_REQUEST_SUCCESS,
  payload,
});

export const devicesCompianceHistoryRequestFailureAC: types.DevicesComplianceHistoryRequestFailureAC = () => ({
  type: types.DEVICES_COMPLIANCE_HISTORY_REQUEST_FAILURE,
});

export const usersTypesSummaryRequestAC: types.UsersTypesSummaryRequestAC = () => ({
  type: types.USERS_TYPES_SUMMARY_REQUEST,
});

export const usersTypesSummaryRequestSuccessAC: types.UsersTypesSummaryRequestSuccessAC = (payload) => ({
  type: types.USERS_TYPES_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const usersTypesSummaryRequestFailureAC: types.UsersTypesSummaryRequestFailureAC = () => ({
  type: types.USERS_TYPES_SUMMARY_REQUEST_FAILURE,
});

export const usersServicesSummaryRequestAC: types.UsersServicesSummaryRequestAC = () => ({
  type: types.USERS_SERVICES_SUMMARY_REQUEST,
});

export const usersServicesSummaryRequestSuccessAC: types.UsersServicesSummaryRequestSuccessAC = (payload) => ({
  type: types.USERS_SERVICES_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const usersServicesSummaryRequestFailureAC: types.UsersServicesSummaryRequestFailureAC = () => ({
  type: types.USERS_SERVICES_SUMMARY_REQUEST_FAILURE,
});

export const usersAppsSummaryRequestAC: types.UsersAppsSummaryRequestAC = () => ({
  type: types.USERS_APPS_SUMMARY_REQUEST,
});

export const usersAppsSummaryRequestSuccessAC: types.UsersAppsSummaryRequestSuccessAC = (payload) => ({
  type: types.USERS_APPS_SUMMARY_REQUEST_SUCCESS,
  payload,
});

export const usersAppsSummaryRequestFailureAC: types.UsersAppsSummaryRequestFailureAC = () => ({
  type: types.USERS_APPS_SUMMARY_REQUEST_FAILURE,
});
