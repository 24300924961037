import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { usersSelectors, usersThunks } from "store/users";

const useFetchUsers = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  useEffect(() => {
    if (selectedContractId) {
      thunkDispatch(usersThunks.getAllUsersList(selectedContractId));
      thunkDispatch(usersThunks.getAuthenticatedUsersList(selectedContractId));
    }
  }, [thunkDispatch, selectedContractId]);

  return useSelector(usersSelectors.getSlice);
};

export default useFetchUsers;
