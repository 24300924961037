import { RequestStatus } from "types/common.types";
import { ManagedAppModel, DetectedAppModel } from "types/appsApi.types";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface ManagedAppsFiltersModel {
  filters: {
    name: string;
    publisher: string[];
    platform: string[];
  };
}

export interface DetectedAppsFiltersModel {
  filters: {
    name: string;
  };
}

export interface ManagedApps extends WithRequestStatus, ManagedAppsFiltersModel {
  list: ManagedAppModel[];
}

export interface DetectedApps extends WithRequestStatus, DetectedAppsFiltersModel {
  list: DetectedAppModel[];
}
export type SortFieldType = keyof ManagedAppModel | keyof DetectedAppModel | "";
export type SortOrderType = "asc" | "desc";

export interface AppsStateSlice {
  readonly managedApps: ManagedApps;
  readonly detectedApps: DetectedApps;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly perPage: number;
  readonly currentPage: number;
}

export const MANAGED_APPS_REQUEST = "MANAGED_APPS_REQUEST";
export type ManagedAppsRequestAction = { type: typeof MANAGED_APPS_REQUEST };
export type ManagedAppsRequestAC = () => ManagedAppsRequestAction;

export const MANAGED_APPS_REQUEST_SUCCESS = "MANAGED_APPS_REQUEST_SUCCESS";
export type ManagedAppsRequestSuccessAction = {
  type: typeof MANAGED_APPS_REQUEST_SUCCESS;
  payload: ManagedAppModel[];
};
export type ManagedAppsRequestSuccessAC = (payload: ManagedAppModel[]) => ManagedAppsRequestSuccessAction;

export const MANAGED_APPS_REQUEST_FAILURE = "MANAGED_APPS_REQUEST_FAILURE";
export type ManagedAppsRequestFailureAction = { type: typeof MANAGED_APPS_REQUEST_FAILURE };
export type ManagedAppsRequestFailureAC = () => ManagedAppsRequestFailureAction;

export const DETECTED_APPS_REQUEST = "DETECTED_APPS_REQUEST";
export type DetectedAppsRequestAction = {
  type: typeof DETECTED_APPS_REQUEST;
};
export type DetectedAppsRequestAC = () => DetectedAppsRequestAction;

export const DETECTED_APPS_REQUEST_SUCCESS = "DETECTED_APPS_REQUEST_SUCCESS";
export type DetectedAppsRequestSuccessAction = {
  type: typeof DETECTED_APPS_REQUEST_SUCCESS;
  payload: DetectedAppModel[];
};
export type DetectedAppsRequestSuccessAC = (payload: DetectedAppModel[]) => DetectedAppsRequestSuccessAction;

export const DETECTED_APPS_REQUEST_FAILURE = "DETECTED_APPS_REQUEST_FAILURE";
export type DetectedAppsRequestFailureAction = {
  type: typeof DETECTED_APPS_REQUEST_FAILURE;
};
export type DetectedAppsRequestFailureAC = () => DetectedAppsRequestFailureAction;

export const SET_APPS_SORT_FIELD = "SET_APPS_SORT_FIELD";
export type SetAppsSortFieldAction = {
  type: typeof SET_APPS_SORT_FIELD;
  payload: SortFieldType;
};
export type SetAppsSortFieldAC = (payload: SortFieldType) => SetAppsSortFieldAction;

export const SET_MANAGED_APPS_FILTERS = "SET_MANAGED_APPS_FILTERS";
export type SetManagedAppsFiltersAction = {
  type: typeof SET_MANAGED_APPS_FILTERS;
  payload: ManagedAppsFiltersModel["filters"];
};
export type SetManagedAppsFiltersAC = (payload: ManagedAppsFiltersModel["filters"]) => SetManagedAppsFiltersAction;

export const SET_DETECTED_APPS_FILTERS = "SET_DETECTED_APPS_FILTERS";
export type SetDetectedAppsFiltersAction = {
  type: typeof SET_DETECTED_APPS_FILTERS;
  payload: DetectedAppsFiltersModel["filters"];
};
export type SetDetectedAppsFiltersAC = (payload: DetectedAppsFiltersModel["filters"]) => SetDetectedAppsFiltersAction;

export const SET_APPS_PER_PAGE = "SET_APPS_PER_PAGE";
export type SetAppsPerPageAction = {
  type: typeof SET_APPS_PER_PAGE;
  payload: number;
};
export type SetAppsPerPageAC = (payload: number) => SetAppsPerPageAction;

export const SET_APPS_CURRENT_PAGE = "SET_APPS_CURRENT_PAGE";
export type SetAppsCurrentPageAction = {
  type: typeof SET_APPS_CURRENT_PAGE;
  payload: number;
};
export type SetAppsCurrentPageAC = (payload: number) => SetAppsCurrentPageAction;

export type AppsActions =
  | ManagedAppsRequestAction
  | ManagedAppsRequestSuccessAction
  | ManagedAppsRequestFailureAction
  | DetectedAppsRequestAction
  | DetectedAppsRequestSuccessAction
  | DetectedAppsRequestFailureAction
  | SetAppsSortFieldAction
  | SetManagedAppsFiltersAction
  | SetDetectedAppsFiltersAction
  | SetAppsPerPageAction
  | SetAppsCurrentPageAction;
