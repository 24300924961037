import React, { useEffect, useState } from "react";
import { Card } from "@skyportal/ui-kit";
import useFetchDevices from "hooks/useFetchDevices";
import CardTabs from "containers/CardTabs";
import { setDevicesCurrentPageAC, setDevicesSortFieldAC } from "store/devices/devices.actions";
import ManagedDevicesTable from "./tabsContent/ManagedDevicesTable";
import UnmanagedDevicesTable from "./tabsContent/UnmanagedDevicesTable";
import WindowsUpdateTabContent from "./tabsContent/WindowsUpdateTabContent";
import MicrosoftDefenderDevicesTable from "./tabsContent/MicrosoftDefenderDevicesTable";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import styles from "./styles.module.css";

export interface TabsConfigModel {
  name: string;
  path: string;
}

const tabsConfig: TabsConfigModel[] = [
  { name: "managedDevicesTab", path: "/mw/devices/managed" },
  { name: "unmanagedDevicesTab", path: "/mw/devices/unmanaged" },
  { name: "windowsUpdateTab", path: "/mw/devices/windows-update" },
  { name: "microsoftDefenderTab", path: "/mw/devices/protection" },
];

const DevicesPageContent = () => {
  useFetchDevices();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name
  );

  useEffect(() => {
    setActiveTab(tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name);
  }, [pathname]);

  return (
    <Card className={styles.card} data-testid="devicesPageContent">
      <CardTabs
        tabsConfig={tabsConfig}
        localeObjectName="devicesPage"
        activeTab={activeTab}
        resetSortField={setDevicesSortFieldAC}
        resetCurrentPage={setDevicesCurrentPageAC}
      />
      <Switch>
        <Route path={`${path}/managed`} component={ManagedDevicesTable} />
        <Route path={`${path}/unmanaged`} component={UnmanagedDevicesTable} />
        <Route path={`${path}/windows-update`} component={WindowsUpdateTabContent} />
        <Route path={`${path}/protection`} component={MicrosoftDefenderDevicesTable} />
        <Route path="*">
          <Redirect to={`${path}/managed`} />
        </Route>
      </Switch>
    </Card>
  );
};

export default DevicesPageContent;
