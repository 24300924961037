import * as types from "./licenses.types";

export const licensesListRequestAC: types.LicensesListRequestAC = () => ({
  type: types.LICENSES_LIST_REQUEST,
});

export const licensesListRequestSuccessAC: types.LicensesListRequestSuccessAC = (payload) => ({
  type: types.LICENSES_LIST_REQUEST_SUCCESS,
  payload,
});

export const licensesListRequestFailureAC: types.LicensesListRequestFailureAC = () => ({
  type: types.LICENSES_LIST_REQUEST_FAILURE,
});

export const setOtherLicensesSortFieldAC: types.SetOtherLicensesSortFieldAC = (payload) => ({
  type: types.SET_OTHER_LICENSES_SORT_FIELD,
  payload,
});

export const setOtherLicensesPerPageAC: types.SetLicensesPerPageAC = (payload) => ({
  type: types.SET_LICENSES_PER_PAGE,
  payload,
});

export const setOtherLicensesCurrentPageAC: types.SetLicensesCurrentPageAC = (payload) => ({
  type: types.SET_LICENSES_CURRENT_PAGE,
  payload,
});
