import { LicenseModel } from "types/licensesApi.types";
import { makeUniversalSort } from "./sort";

export const licenseNameNormalizer = (item: LicenseModel) => item.displayName;
export const availableLicensesNormalizer = (item: LicenseModel) => item.prepaidUnits.enabled - item.consumedUnits;

export const otherLicensesSortHandler: any = {
  displayName: makeUniversalSort(licenseNameNormalizer),
  availableLicenses: makeUniversalSort(availableLicensesNormalizer),
};
